import { allRole, whereRole, WhereNotRole } from '@/plugins/RolePermision/role.js'

// PROFILE ACCOUNT
const ProfileLayout = () => import(/* webpackChunkName: "ProfileLayout" */ '../views/Apps/ProfileV2/Layout/index.vue')
const ProfilePramuka = () => import(/* webpackChunkName: "ProfilePramuka" */ '../views/Apps/ProfileV2/Layout/ProfilePramuka.vue')

const TimeLine = () => import(/* webpackChunkName: "TimeLine" */ '../views/Apps/ProfileV2/NewPage/TimeLine.vue')
const Biodata = () => import(/* webpackChunkName: "Biodata" */ '../views/Apps/ProfileV2/NewPage/Biodata.vue')
const RiwayatTugas = () => import(/* webpackChunkName: "RiwayatTugas" */ '../views/Apps/ProfileV2/NewPage/RiwayatTugas.vue')
const RiwayatMutasi = () => import(/* webpackChunkName: "RiwayatMutasi" */ '../views/Apps/ProfileV2/NewPage/RiwayatMutasi.vue')
const Kegiatan = () => import(/* webpackChunkName: "Kegiatan" */ '../views/Apps/ProfileV2/NewPage/Kegiatan.vue')
const SkkSku = () => import(/* webpackChunkName: "SkkSku" */ '../views/Apps/ProfileV2/NewPage/SkkSku.vue')
const PendidikanKepramukaan = () =>
	import(/* webpackChunkName: "PendidikanKepramukaan" */ '../views/Apps/ProfileV2/NewPage/PendidikanKepramukaan.vue')

const ProfileId = () => import(/* webpackChunkName: "ProfileId" */ '../views/Apps/ProfileV2/Page/PageIndex.vue')
const ProfileEditId = () => import(/* webpackChunkName: "ProfileEditId" */ '../views/Apps/ProfileV2/Page/PageEditProfil.vue')
const SettingProfile = () => import(/* webpackChunkName: "SettingProfile" */ '../views/Apps/ProfileV2/Page/PageSetting.vue')
const PrivacySetting = () => import(/* webpackChunkName: "PrivacySetting" */ '../views/User/PrivacySetting')
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/Pages/PrivacyPolicy')
const TermOfService = () => import(/* webpackChunkName: "TermOfService" */ '../views/Pages/TermsOfService')
const AccountSetting = () => import(/* webpackChunkName: "AccountSetting" */ '../views/User/AccountSetting')
const Privacy = () => import(/* webpackChunkName: "Privacy" */ '../views/User/PrivacySetting')

const TataCara = () => import(/* webpackChunkName: "priv-setting" */ '../views/Apps/ProfileV2/Component/Setting/Tata.vue')
const PageTataCaraPengajuan = () => import(/* webpackChunkName: "TataCara" */ '../views/Apps/Penghargaan/Page/PageTataCaraPengajuan.vue')
const PageFormPengajuanAnggota = () =>
	import(/* webpackChunkName: "PageFormPengajuanAnggota" */ '../views/Apps/Penghargaan/Page/PageFormPengajuanAnggota.vue')
const PagePengajuanGerakanPramuka = () =>
	import(/* webpackChunkName: "PagePengajuanGerakanPramuka" */ '../views/Apps/ProfileV2/Page/PagePengajuanGerakanPramuka.vue')
const PageDashboardActivity = () => import(/* webpackChunkName: "PageDashboardActivity" */ '../views/Apps/ProfileV2/Page/PageDashboardActivity.vue')
const PagePencapaianActivity = () =>
	import(/* webpackChunkName: "PagePencapaianActivity" */ '../views/Apps/ProfileV2/Page/PagePencapaianActivity.vue')
const PagePapanPeringkat = () => import(/* webpackChunkName: "PagePapanPeringkat" */ '../views/Apps/ProfileV2/Page/PagePapanPeringkat.vue')
const PageRefLevel = () => import(/* webpackChunkName: "PageRefLevel" */ '../views/Apps/ProfileV2/Page/PageRefLevel.vue')

var prop = 'social'

const ProfileRouter = [
	{
		path: 'accountsetting',
		name: prop + '.accountsetting',
		meta: { auth: true, name: 'Account Setting', roles: allRole },
		component: () => AccountSetting
	},
	{
		path: 'privacysetting',
		name: prop + '.privacy-setting',
		meta: { auth: true, name: 'Privacy Setting', roles: allRole },
		component: PrivacySetting
	},
	{
		path: 'privacypolicy',
		name: prop + '.privacy',
		meta: { auth: true, name: 'Privacy Policy', roles: allRole },
		component: PrivacyPolicy
	},
	{
		path: 'termofservice',
		name: prop + '.termofservice',
		meta: { auth: true, name: 'Terms Of Use', roles: allRole },
		component: TermOfService
	},
	{
		path: 'profile/:id',
		component: ProfileLayout,
		name: 'Profile Layout',
		children: [
			{
				path: '',
				redirect: (to) => {
					// Example: Redirect to a profile page
					return `/profile/${to.params.id}/profil-pramuka`
				}
			},

			{
				path: '/profile/:id/postingan',
				name: 'Postingan',
				component: TimeLine,
				meta: { auth: true, name: 'Profil', roles: WhereNotRole(['Guest Mode']) }
			},
			{
				path: '/profile/:id/balasan',
				name: 'Balasan',
				component: TimeLine,
				meta: { auth: true, name: 'Profil', roles: WhereNotRole(['Guest Mode']) }
			},
			{
				path: '/profile/:id/media',
				name: 'Media',
				component: TimeLine,
				meta: { auth: true, name: 'Profil', roles: WhereNotRole(['Guest Mode']) }
			},
			{
				path: '/profile/:id/profil-pramuka',
				name: 'Profil Pramuka',
				component: ProfilePramuka,
				children: [
					{
						path: '',
						name: 'Biodata',
						component: Biodata,
						meta: { auth: true, name: 'Biodata', roles: WhereNotRole(['Guest Mode']) }
					},
					{
						path: '/profile/:id/profil-pramuka/pendidikan-kepramukaan',
						name: 'Pendidikan Kepramukaan',
						component: PendidikanKepramukaan,
						meta: { auth: true, name: 'Pendidikan Kepramukaan', roles: WhereNotRole(['Guest Mode']) }
					},
					{
						path: '/profile/:id/profil-pramuka/riwayat-tugas',
						name: 'Riwayat Tugas',
						component: RiwayatTugas,
						meta: { auth: true, name: 'Riwayat Tugas', roles: WhereNotRole(['Guest Mode']) }
					},
					{
						path: '/profile/:id/profil-pramuka/riwayat-mutasi',
						name: 'Riwayat Mutasi',
						component: RiwayatMutasi,
						meta: { auth: true, name: 'Riwayat Mutasi', roles: WhereNotRole(['Guest Mode']) }
					},
					{
						path: '/profile/:id/profil-pramuka/kegiatan',
						name: 'Kegiatan',
						component: Kegiatan,
						meta: { auth: true, name: 'Kegiatan', roles: WhereNotRole(['Guest Mode']) }
					}
				]
			}
		]
	},
	{
		path: '/profile/:id/pencapaian',
		name: 'Pencapaian',
		component: SkkSku,
		meta: { auth: true, name: 'Pencapaian', roles: WhereNotRole(['Guest Mode']) }
	},
	{
		path: 'profile/edit/:id',
		name: prop + '.profile.edit',
		meta: { auth: true, name: 'Profil Edit', roles: WhereNotRole(['Guest Mode']) },
		component: ProfileEditId
	},
	{
		path: 'setting/profile',
		name: prop + '.seting.profile',
		meta: { auth: true, name: 'Setting Profil', roles: WhereNotRole(['Guest Mode']) },
		component: SettingProfile
	},
	{
		path: 'tata-cara',
		name: prop + '.tatacara',
		meta: { auth: true, name: 'Tata Cara', roles: WhereNotRole(['Guest Mode']) },
		component: TataCara
	},
	{
		path: 'profile/pengajuan/tata-cara',
		name: prop + '.tata-cara-pengajuan',
		meta: { auth: true, name: 'Tata Cara Pengajuan Penghargaan', permission: 'Dewasa', roles: allRole },
		component: PageTataCaraPengajuan
	},
	{
		path: 'pengajuan/gerakan-pramuka',
		name: prop + '.pengajuan-gerakan-pramuka',
		meta: { auth: true, name: 'Pengajuan Gerakan Pramuka', permission: 'Muda', roles: allRole },
		component: PagePengajuanGerakanPramuka
	},
	{
		path: 'profile/pengajuan-penghargaan',
		name: prop + '.form-pengajuan',
		meta: { auth: true, name: 'Form Pengajuan Penghargaan', permission: 'Dewasa', roles: allRole },
		component: PageFormPengajuanAnggota
	},
	{
		path: '/profile/:id/dasbor-aktivitas',
		name: prop + '.dasbor-aktivitas',
		component: PageDashboardActivity,
		meta: { auth: true, name: 'Dashboard Activity', roles: WhereNotRole(['Guest Mode']) }
	},
	{
		path: '/profile/:id/pencapaian-aktivitas',
		name: prop + '.pencapaian-aktivitas',
		component: PagePencapaianActivity,
		meta: { auth: true, name: 'Pencapaian Activity', roles: WhereNotRole(['Guest Mode']) }
	},
	{
		path: '/profile/:id/papan-peringkat',
		name: prop + '.papan-peringkat',
		component: PagePapanPeringkat,
		meta: { auth: true, name: 'Papan Peringkat Pencapaian Activity', roles: WhereNotRole(['Guest Mode']) }
	},
	{
		path: '/profile/:id/ref-level',
		name: prop + '.ref-level',
		component: PageRefLevel,
		meta: { auth: true, name: 'Referensi Level', roles: WhereNotRole(['Guest Mode']) }
	}
]

export default ProfileRouter
