import axios from "axios"

export default {
  async TweetAction (context, payload) {
    try {
      let response = await axios.get('api/feed/get-feed', {params : payload})
      
      if (response.status === 200) {
        const data = response.data.data
        if (data && data.length === 0) context.state.stop = true
        let check = context.state.tweet.includes(data[0])
        if (!check) {
          context.commit('tweetCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async KegiatanAction (context, payload) {
    try {
      let response = await axios.get('api/kegiatan', {params : payload})
      
      if (response.status === 200) {
        const data = response.data.data
        let check = context.state.tweet.includes(data[0])
        if (!check) {
          context.commit('tweetCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async reloadAction(context, payload){
    try {
        let response = await axios.get("api/feed?key="+payload.key)
        if (response.status == 200) {
        context.commit("reloadTweetCommit",response.data.data[0])
        context.commit("detailTweetCommit",null)
        context.commit("commentCommit",null)
        this.upload = false
        }
     } catch (error) {
        console.log(error)
     }
 },
}
