import { createApp } from 'vue'
import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Home from './Home/index'
import Auth from './Auth/index'
import Tweet from './Tweet/index'
import TweetBalasan from './TweetBalasan/index'
import TweetGalery from './TweetGalery/index'
import TweetPoling from './TweetPoling/index'
import Header from './Header/index'
import Berita from './Berita/index'
import Rekap from './Rekap/index'
import NewFeed from './NewFeed/index'
import NewComment from './NewComment/index'
import RolePermission from './RolePermission/index'
import RegisterMandiri from './RegisterMandiri/index'
import Musda from './Musda/index'
import Muscab from './Muscab/index'
import DataPotensi from './DataPotensi/index'
import GerakanPramuka from './GerakanPramuka/index'
import Kegiatan from './Kegiatan/index'
import Modal from './Modal/index'
import Ediklat from './Ediklat/index'
import DashboardWilayah from './DashboardWilayah/index'

createApp(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: ['RegisterMandiri', 'RolePermission', 'Auth.userProfile']
		})
	],
	modules: {
		Setting,
		Home,
		Auth,
		Tweet,
		Berita,
		Rekap,
		Header,
		TweetBalasan,
		TweetGalery,
		TweetPoling,
		NewFeed,
		NewComment,
		RolePermission,
		RegisterMandiri,
		Musda,
		Muscab,
		DataPotensi,
		GerakanPramuka,
		Kegiatan,
		Modal,
		Ediklat,
		DashboardWilayah
	},
	state: {},
	mutations: {},
	actions: {},
	getters: {},
	strict: debug
})
