<template>
	<li :class="liClass">
		<div v-if="title == 'REKOMENDASI' && title == 'LIST GAME'">
			<a
				style="padding: 0.25rem 0.625rem"
				:class="'nav-link ' + className + ' ' + widthclass"
				:id="id"
				:data-toggle="dataToggle"
				:href="url"
				:role="role"
				:aria-controls="ariaControls"
				:aria-selected="ariaSelected"
			>
				<span class="text-large">{{ title }}</span>
			</a>
		</div>
		<a
			:class="'nav-link ' + className + ' ' + widthclass"
			:id="id"
			:data-toggle="dataToggle"
			:href="url"
			:role="role"
			:aria-controls="ariaControls"
			:aria-selected="ariaSelected"
		>
			<!-- <img src="@/assets/images/icon/Timeline.png" class="img-fluid" alt="" v-if="{{title}}=='Timeline'"> -->

			<slot name="title" v-if="hasTitleSlot" />
			<div v-if="title == 'TIMELINE'" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<i class="las la-align-center" style="font-size: 22px; margin-right: 5px; margin-bottom: 5px"></i>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Balasan'" style="font-size: 18px; font-weight: 400; font-family: 'Poppins'">
				<i class="las la-retweet" style="font-size: 22px; margin-right: 5px; margin-bottom: 5px"></i>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'FOTO'" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<i class="las la-image" style="font-size: 22px; margin-right: 5px; margin-bottom: 5px"></i>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Keanggotaan'" style="font-size: 18px; font-weight: 400; font-family: 'Poppins'">
				<i class="las la-certificate" style="font-size: 22px; margin-right: 5px; margin-bottom: 5px"></i>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Profile'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'TKK'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'TKU'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'TKK/TKU'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Kegiatan/Kursus'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Kegiatan'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Riwayat Tugas'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Kursus'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Penghargaan'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Riwayat Mutasi'">
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Profil'">
				<img src="@/assets/images/icon/SettingProfil.png" class="img-fluid" :alt="title" style="margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Akun'">
				<img src="@/assets/images/icon/SettingAkun.png" class="img-fluid" :alt="title" style="margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Pengguna'">
				<img
					src="@/assets/images/icon/SettingPengguna.png"
					class="img-fluid"
					:alt="title"
					style="margin-right: 10px; width: 20px; font-weight: 600"
				/>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Kebijakan'">
				<img
					src="@/assets/images/icon/SettingKebijakan.png"
					class="img-fluid"
					:alt="title"
					style="margin-right: 10px; width: 20px; font-weight: 600"
				/>
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Info'">
				<img src="@/assets/images/icon/SettingInfo.png" class="img-fluid" :alt="title" style="margin-right: 10px; width: 20px; font-weight: 600" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title == 'Keluar'">
				<img src="@/assets/images/icon/SettingKeluar.png" class="img-fluid" :alt="title" style="margin-right: 10px; width: 20px; font-weight: 600" />
				<span class="text-large">{{ title }}</span>
			</div>
			<!-- <div v-else-if="title == 'Keluar'" >
        <img src="@/assets/images/icon/SettingKeluar.png" class="img-fluid" :alt="title" style="margin-right: 10px;width:20px">
        <span class="text-large">{{ title }}</span>
      </div> -->
			<div v-else-if="title === 'KWARNAS '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/TabKwarnas.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 16px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'ORGANISASI '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/TabKwarnas.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 16px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'BERITA'" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/NewsPaper.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'KWARDA '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/Kwarda.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'KWARCAB '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/Kwarcab.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'KWARRAN '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/Kwarran.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'GUGUS DEPAN '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/Gudep.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else-if="title === 'SAKA ' || title === 'SAKO '" style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" class="text-center">
				<img src="@/assets/images/icon/Gudep.png" class="img-fluid" :alt="title" style="margin-top: -4px; margin-right: 10px; width: 20px" />
				<span class="text-large">{{ title }}</span>
			</div>
			<div v-else style="font-size: 18px; font-weight: 500; font-family: 'Poppins'" :class="divClass">
				<i v-if="icon" :class="icon" style="font-size: 22px; margin-right: 5px; margin-bottom: 5px"></i>
				<span class="text-large">{{ title }}</span>
			</div>
		</a>
	</li>
</template>

<script>
export default {
	name: 'tab-nav-items',
	props: {
		id: { type: String, default: '' },
		active: { type: Boolean, default: false },
		role: { type: String, default: 'tab' },
		ariaControls: { type: String, default: 'tabpanel' },
		ariaSelected: { type: Boolean, default: false },
		dataToggle: { type: String, default: 'tab' },
		href: { type: String, default: '' },
		title: { type: String, default: '' },
		liClass: { type: String, default: 'nav-item' },
		widthclass: { type: String, default: '' },
		icon: { type: String, default: null },
		divClass: { type: String, default: '' }
	},
	mounted() {
		if (this.active) {
			this.className = 'active'
		}
	},
	computed: {
		hasTitleSlot() {
			return !!this.$slots.title
		},
		url() {
			if (this.href === '') {
				return '#' + this.ariaControls
			}
			return this.href
		}
	},
	data() {
		return {
			className: ''
		}
	}
}
</script>
