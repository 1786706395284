export default {
  setModalData (state, payload) {
    state.modalData = payload
  },
  setModalInfo (state, payload) {
    state.modalInfo = payload
  },
  setLoading(state, payload) {
    state.modalData.loading = payload
  },
  setFunction(state, func) {
    state.myFunction = func;
  },
  setDeniedFunction(state, func) {
    state.deniedFunction = func;
  }
}
