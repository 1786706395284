const state = {
	selectedFoto: null,
	showModal: false
}

const mutations = {
	setSelectedFoto(state, payload) {
		state.selectedFoto = payload
	}
}

const actions = {
	selectFoto({ commit }, payload) {
		commit('setSelectedFoto', payload)
		$('#modal-preview-foto-general').modal('show')
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
