import { allRole, whereRole } from '@/plugins/RolePermision/role.js'

// GERAKAN PRAMUKA
const PageDetailKwarnas = () => import(/* webpackChunkName: "gugus-depan" */ '../views/Apps/GerakanPramuka/Page/PageDetailKwarnas.vue')
const OrganisasiId = () => import(/* webpackChunkName: "organisasi-id" */ '../views/Apps/Organisasi/Organisasi.vue')
const PageListGudep = () => import(/* webpackChunkName: "gugus-depan-list" */ '../views/Apps/GerakanPramuka/Page/PageListGudep.vue')
const PageDetailGudep = () => import(/* webpackChunkName: "gugus-depan" */ '../views/Apps/GerakanPramuka/Page/PageDetailGudep.vue')
const PageListKwarran = () => import(/* webpackChunkName: "kwarran-list" */ '../views/Apps/GerakanPramuka/Page/PageListKwarran.vue')
const PageDetailKwarran = () => import(/* webpackChunkName: "kwarran-detail" */ '../views/Apps/GerakanPramuka/Page/PageDetailKwarran.vue')
const PageListKwarcab = () => import(/* webpackChunkName: "kwarcab" */ '../views/Apps/GerakanPramuka/Page/PageListKwarcab.vue')
const PageDetailKwarcab = () => import(/* webpackChunkName: "kwarcab-detail" */ '../views/Apps/GerakanPramuka/Page/PageDetailKwarcab.vue')
const PageListKwarda = () => import(/* webpackChunkName: "kwarda" */ '../views/Apps/GerakanPramuka/Page/PageListKwarda.vue')
const PageDetailKwarda = () => import(/* webpackChunkName: "kwarda-detail" */ '../views/Apps/GerakanPramuka/Page/PageDetailKwarda.vue')
const PageDetailOrganisasi = () => import(/* webpackChunkName: "organisasi" */ '../views/Apps/GerakanPramuka/Page/PageDetailOrganisasi.vue')
const PageListSakaSako = () => import(/* webpackChunkName: "saka-sako" */ '../views/Apps/GerakanPramuka/Page/PageListSakaSako.vue')
const PageKelolaSakaSako = () => import(/* webpackChunkName: "kelola-saka-sako" */ '../views/Apps/GerakanPramuka/Page/PageKelolaSakaSako.vue')
const PageBuatSakaSako = () => import(/* webpackChunkName: "tambah-saka-sako" */ '../views/Apps/GerakanPramuka/Page/PageBuatSakaSako.vue')
const PageUbahSakaSako = () => import(/* webpackChunkName: "ubah-saka-sako" */ '../views/Apps/GerakanPramuka/Page/PageUbahSakaSako.vue')
const PageDetailSakaSako = () => import(/* webpackChunkName: "saka-sako-detail" */ '../views/Apps/GerakanPramuka/Page/PageDetailSakaSako.vue')

var prop = 'social'

const GerakanPramukaRouter = [
	{
		path: '/gugus-depan/:id',
		name: prop + '.pangkalan',
		meta: { auth: true, name: 'Gugus Depan App', roles: allRole },
		component: PageDetailGudep
	},
	{
		path: '/gugus-depan',
		name: prop + '.pangkalan.list',
		meta: { auth: true, name: 'Explore Gugus Depan', roles: allRole },
		component: PageListGudep
	},
	{
		path: '/kwarran/:id',
		name: prop + '.kwarran',
		meta: { auth: true, name: 'Kwarran App', roles: allRole },
		component: PageDetailKwarran
	},
	{
		path: '/kwarran',
		name: prop + '.kwarran.list',
		meta: { auth: true, name: 'Explore Kwarran', roles: allRole },
		component: PageListKwarran
	},
	{
		path: '/kwarcab/:id',
		name: prop + '.kwarcab',
		meta: { auth: true, name: 'Kwarcab App', roles: allRole },
		component: PageDetailKwarcab
	},
	{
		path: '/kwarcab',
		name: prop + '.kwarcab.list',
		meta: { auth: true, name: 'Explore Kwarcab', roles: allRole },
		component: PageListKwarcab
	},
	{
		path: '/kwarda/:id',
		name: prop + '.kwarda',
		meta: { auth: true, name: 'Kwarda App', roles: allRole },
		component: PageDetailKwarda
	},
	{
		path: '/kwarda',
		name: prop + '.kwarda.list',
		meta: { auth: true, name: 'Explore Kwarda', roles: allRole },
		component: PageListKwarda
	},
	{
		path: '/kwarnas',
		name: prop + '.kwarnas',
		meta: { auth: true, name: 'Kwarnas App', roles: allRole },
		component: PageDetailKwarnas
	},
	{
		path: '/organisasi/:id',
		name: prop + '.organisasi',
		meta: { auth: true, name: 'Organisasi App', roles: allRole },
		component: PageDetailOrganisasi
	},
	{
		path: '/saka-sako',
		name: prop + '.saka-sako.list',
		meta: { auth: true, name: 'Explore Saka Sako', roles: allRole },
		component: PageListSakaSako
	},
	{
		path: '/saka-sako/kelola',
		name: prop + '.saka-sako.kelola',
		meta: {
			auth: true,
			name: 'Kelola Saka Sako',
			roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Admin Kwartir Daerah', 'Admin Kwartir Cabang'])
		},
		component: PageKelolaSakaSako
	},
	{
		path: '/saka-sako/kelola/tambah',
		name: prop + '.saka-sako.tambah',
		meta: {
			auth: true,
			name: 'Tambah Saka Sako',
			roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Admin Kwartir Daerah', 'Admin Kwartir Cabang'])
		},
		component: PageBuatSakaSako
	},
	{
		path: '/saka-sako/kelola/ubah/:id',
		name: prop + '.saka-sako.ubah',
		meta: {
			auth: true,
			name: 'Ubah Saka Sako',
			roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Admin Kwartir Daerah', 'Admin Kwartir Cabang'])
		},
		component: PageUbahSakaSako
	},
	{
		path: '/saka-sako/:id',
		name: prop + '.saka-sako.detail',
		meta: { auth: true, name: 'Detail Saka Sako', roles: allRole },
		component: PageDetailSakaSako
	}
]

export default GerakanPramukaRouter
