const state = {
  comments: []
}

const mutations = {
  setClearComments (state) {
    state.comments = []
  },
  setComments (state, value) {
    if (value === null) {
      state.comments = []
    } else {
      state.comments.push(...value)
    }
  },
  setAddComment (state, data) {
    state.comments.splice(0, 0, data)
  },
  setCommentInComment (state, data) {
    state.comments.forEach((item) => {
      if (item.key === data.key) {
        item.comments.splice(0, 0, data.newComment)
      }
    })
  },
  deleteAndInterestComment (state, key) {
    state.comments = state.comments.filter(item => {
      return item.key !== key
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
