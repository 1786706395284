import { allRole, whereRole, WhereNotRole, kwartirRole, garudaRole, EdiklatRole } from '@/plugins/RolePermision/role.js'

// LAPORAN
const Index = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Page/Beranda.vue') // Beranda
const Pelatihan = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Page/Pelatihan.vue')
const DetailPelatihan = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Page/PageDetailPelatihan.vue')
const AddPelatihan = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Create/PageCreatePelatihan.vue')
const UpdatePelatihan = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Update/PageUpdatePelatihan.vue')
const UjianPeserta = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Component/ujianPeserta.vue')

var prop = 'diklat'

const EdiklatRouter = [
	{
		path: '/e-diklat',
		name: prop + '.beranda',
		meta: { auth: true, name: 'E-DIKLAT', permission: 'Dewasa', roles: allRole },
		component: Index
	},
	{
		path: '/e-diklat/pelatihan',
		name: prop + '.pelatihan',
		meta: { auth: true, name: 'E-DIKLAT Pelatihan', permission: 'Dewasa', roles: allRole },
		component: Pelatihan
	},
	{
		path: '/e-diklat/pelatihan/:id',
		name: prop + 'detail.pelatihan',
		meta: { auth: true, name: 'E-DIKLAT Detail Pelatihan', permission: 'Dewasa', roles: allRole },
		component: DetailPelatihan
	},
	{
		path: '/e-diklat/pelatihan/buat',
		name: prop + '.buat-pelatihan',
		meta: { auth: true, name: 'E-DIKLAT Buat Pelatihan', permission: 'Dewasa', roles: EdiklatRole },
		component: AddPelatihan
	},
	{
		path: '/e-diklat/pelatihan/edit/:id',
		name: prop + '.edit-pelatihan',
		meta: { auth: true, name: 'E-DIKLAT Edit Pelatihan', permission: 'Dewasa', roles: EdiklatRole },
		component: UpdatePelatihan
	},
	{
		path: '/ujian',
		name: prop + 'ujian',
		meta: { auth: true, name: 'E-DIKLAT Ujian' },
		component: UjianPeserta
	}
]

export default EdiklatRouter