import axios from 'axios'
import store from '../store'
import router from '@/router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export const differenceOfTwoDate = (date1, date2, type = 'hour') => {
	const msMinutes = 60 * 1000
	const msHours = 60 * 60 * 1000
	const msDays = 60 * 60 * 24 * 1000

	const newDate1 = new Date(date1)
	const newDate2 = new Date(date2)
	const diff = newDate2.getTime() - newDate1.getTime()

	let result = 0
	switch (type) {
		case 'minute':
			result = diff / msMinutes
			break
		case 'hour':
			result = diff / msHours
			break
		case 'day':
			result = diff / msDays
			break
	}

	return Math.floor(result)
}

export const logout = async () => {
	localStorage.removeItem('token')
	// localStorage.removeItem('reToken')
	await store.commit('Auth/userCommit', {})
	await store.commit('Auth/userProfileCommit', {})
	await store.commit('Auth/panggilanCommit', null)
	await store.commit('RolePermission/setRoles', [])
}

var refreshToken = false
// Function refresh_token
export const doRefresh = async () => {
	if (refreshToken == true) {
		return await doRefresh()
	}
	refreshToken = true
	try {
		const refresh_token = localStorage.getItem('reToken') || 'retoken_xxxx'
		const resultRequest = await axios.post('api/refresh-token', { refresh_token: refresh_token })
		if (resultRequest.status === 200 && !resultRequest.data.data?.error) {
			const result = resultRequest?.data?.data
			localStorage.setItem('token', result.access_token)
			localStorage.setItem('reToken', result.refresh_token)
			refreshToken = false
			store.commit('Auth/loadingCommit', false)
			return { token: result.access_token }
		} else {
			window.location.href = window.location.origin + '/auth/signin'
			router.replace('/auth/signin')
			logout()
			refreshToken = false
			store.commit('Auth/loadingCommit', false)
			console.log(error)
		}
	} catch (error) {
		window.location.href = window.location.origin + '/auth/signin'
		router.replace('/auth/signin')
		logout()
		refreshToken = false
		store.commit('Auth/loadingCommit', false)
		console.log(error)
	}
}

const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const removeListener = onAuthStateChanged(
			getAuth(),
			(user) => {
				removeListener()
				resolve(user)
			},
			reject
		)
	})
}

export const getMe = async () => {
	const currentUser = await getCurrentUser()
	// console.log(currentUser.accessToken)
	// document.querySelector('body').style.visibility = 'hidden'
	// document.querySelector('.dotLoader').style.visibility = 'visible'
	// document.querySelector('#loader').style.visibility = 'visible'
	// if(currentUser) {
	const response = await axios.get('/api/auth/me')
	if (response.status == 200) {
		localStorage.setItem(
			'emailPhone',
			JSON.stringify({
				email: response.data.data.email,
				noHp: response.data.data.noHp
			})
		)
		await store.commit('Auth/userProfileCommit', response.data.data)
		await store.commit('Auth/userCommit', response.data.data)
		await store.commit('Auth/panggilanCommit', response.data?.data?.jenisAnggota === 'Muda' ? 'Adik' : 'Kakak')
		await axios.post('/api/firebase-auth', { firebase_token: currentUser.accessToken }).then(async (res) => {
			if (res.status === 200) {
				const roles = res.data.data?.user?.roles
				localStorage.setItem('token', res.data.data?.token?.access_token)
				localStorage.setItem('reToken', res.data.data?.token?.refresh_token)
				await store.commit('RolePermission/setRoles', roles)
				// document.querySelector('#loader').style.display = 'none'
				// document.querySelector('.dotLoader').style.display = 'none'
				// document.querySelector('body').style.visibility = 'visible'
				return true
			}
		})
		return true
	}
	// }
}

export const isAdminAppsRole = (userRoles, checkRoles) => {
	const buffer = userRoles.map((res) => {
		return res.name
	})

	const userSet = new Set(buffer)
	const isAllowed = checkRoles.filter((item) => userSet.has(item))

	return isAllowed.length > 0
}

export const getDecodeToken = (jwtToken) => {
	if (!jwtToken || (typeof jwtToken === 'string' && jwtToken?.trim()?.length === 0) || typeof window === 'undefined') return null
	const jwtArrays = jwtToken.split('.')
	const jwtPayloads = jwtArrays.length > 1 ? jwtArrays[1] : null
	try {
		const parsedToken = JSON.parse(window.atob(jwtPayloads))
		return parsedToken
	} catch (_error) {
		return null
	}
}

export const getExpiredToken = (parsedToken) => {
	if (!parsedToken) return null
	const iatTime = (parsedToken?.iat || 0) * 1000
	const expTime = (parsedToken?.exp || 0) * 1000
	const resultDuration = differenceOfTwoDate(iatTime, expTime, 'minute')
	const resultCurrent = differenceOfTwoDate(new Date().getTime(), expTime, 'minute')

	if (resultCurrent >= 0 && resultCurrent <= resultDuration) {
		return { expired: false, ...parsedToken }
	} else {
		return { expired: true }
	}
}
