export default {
	setTitleAction({commit, state}, payload) {
		commit('setTitle', payload)
	},
	setIsAdminAction({commit, state}, payload) {
		commit('setIsAdmin', payload)
	},
	setPelatihanDetailAction({commit, state}, payload) {
		commit('setPelatihanDetail', payload)
	},
	setSesiDetailAction({commit, state}, payload) {
		commit('setSesiDetail', payload)
	},
	setSesiFormAction({commit, state}, payload) {
		commit('setSesiForm', payload)
	},
	setEvaluasiFormAction({commit, state}, payload) {
		commit('setEvaluasiForm', payload)
	},
	setTugasFormAction({commit, state}, payload) {
		commit('setTugasForm', payload)
	},
	setUjianFormAction({commit, state}, payload) {
		commit('setUjianForm', payload)
	},
	setSoalAction({commit, state}, payload) {
		commit('setSoal', payload)
	},
	deleteSoalAction({commit, state}, payload) {
		commit('deleteSoal', payload)
	},
	clearSoalAction({commit, state}, payload) {
		commit('clearSoal')
	},
	clearAllFormAction({commit, state}, payload) {
		commit('clearAllForm')
	},
	setLoadingDetailSesiAction({commit, state}, payload) {
		commit('setLoadingDetailSesi', payload)
	},
	setLampiranAction({commit, state}, payload) {
		commit('setLampiran', payload)
	},
	pushLampiranAction({commit, state}, payload) {
		commit('pushLampiran', payload)
	},
	removeLampiranAction({commit, state}, payload) {
		commit('removeLampiran', payload)
	},
	editSoalAction({commit, state}, payload) {
		commit('editSoal', payload)
	},
	setAsesmenFormAction({commit, state}, payload) {
		commit('setAsesmenForm', payload)
	},
}
