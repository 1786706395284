import { whereRole, kwartirRole, allRole } from '@/plugins/RolePermision/role.js'

const IndexKlasifikasi = () => import(/* webpackChunkName: "IndexKlasifikasi" */ '../views/Apps/Referensi/KlasifikasiSurat/IndexKlasifikasi.vue')
const TambahKlasifikasi = () => import(/* webpackChunkName: "TambahKlasifikasi" */ '../views/Apps/Referensi/KlasifikasiSurat/TambahKlasifikasi.vue')
const IndexSifat = () => import(/* webpackChunkName: "IndexSifat" */ '../views/Apps/Referensi/SifatSurat/IndexSifat.vue')
const TambahSifat = () => import(/* webpackChunkName: "TambahSifat" */ '../views/Apps/Referensi/SifatSurat/TambahSifat.vue')
const IndexDisposisi = () => import(/* webpackChunkName: "IndexDisposisi" */ '../views/Apps/Referensi/SuratDisposisi/IndexDisposisi.vue')
const TambahDisposisi = () => import(/* webpackChunkName: "TambahDisposisi" */ '../views/Apps/Referensi/SuratDisposisi/TambahDisposisi.vue')
const IndexJenis = () => import(/* webpackChunkName: "IndexJenis" */ '../views/Apps/Referensi/JenisSurat/IndexJenis.vue')
const TambahJenis = () => import(/* webpackChunkName: "TambahJenis" */ '../views/Apps/Referensi/JenisSurat/TambahJenis.vue')
const IndexTujuan = () => import(/* webpackChunkName: "IndexTujuan" */ '../views/Apps/Referensi/SuratTujuan/IndexTujuan.vue')
const TambahTujuan = () => import(/* webpackChunkName: "TambahTujuan" */ '../views/Apps/Referensi/SuratTujuan/TambahTujuan.vue')
const IndexGrup = () => import(/* webpackChunkName: "IndexGrup" */ '../views/Apps/Referensi/GrupSurat/IndexGrup.vue')
const TambahGrup = () => import(/* webpackChunkName: "TambahGrup" */ '../views/Apps/Referensi/GrupSurat/TambahGrup.vue')
const IndexCatatan = () => import(/* webpackChunkName: "IndexCatatan" */ '../views/Apps/Referensi/SuratCatatan/IndexCatatan.vue')
const TambahCatatan = () => import(/* webpackChunkName: "TambahCatatan" */ '../views/Apps/Referensi/SuratCatatan/TambahCatatan.vue')
const IndexStatus = () => import(/* webpackChunkName: "IndexStatus" */ '../views/Apps/Referensi/StatusSurat/IndexStatus.vue')
const TambahStatus = () => import(/* webpackChunkName: "TambahStatus" */ '../views/Apps/Referensi/StatusSurat/TambahStatus.vue')
const IndexBidang = () => import(/* webpackChunkName: "IndexBidang" */ '../views/Apps/Referensi/Bidang/IndexBidang.vue')
const TambahBidang = () => import(/* webpackChunkName: "TambahBidang" */ '../views/Apps/Referensi/Bidang/TambahBidang.vue')
const IndexSuratBidang = () => import(/* webpackChunkName: "IndexSuratBidang" */ '../views/Apps/Referensi/SuratBidang/IndexSuratBidang.vue')
const TambahSuratBidang = () => import(/* webpackChunkName: "TambahSuratBidang" */ '../views/Apps/Referensi/SuratBidang/TambahSuratBidang.vue')
const IndexNota = () => import(/* webpackChunkName: "IndexNota" */ '../views/Apps/Referensi/TujuanNota/IndexNota.vue')
const TambahNota = () => import(/* webpackChunkName: "TambahNota" */ '../views/Apps/Referensi/TujuanNota/TambahNota.vue')
const IndexTingkatanJabatan = () => import(/* webpackChunkName: "IndexTingkatanJabatan" */ '../views/Apps/Referensi/TingkatanJabatan/IndexTingkatanJabatan.vue')
const TambahTingkatanJabatan = () => import(/* webpackChunkName: "TambahTingkatanJabatan" */ '../views/Apps/Referensi/TingkatanJabatan/TambahTingkatanJabatan.vue')
const IndexTTD = () => import(/* webpackChunkName: "IndexTTD" */ '../views/Apps/Referensi/TTDStemple/IndexTTD.vue')
// const IndexListRole = () => import(/* webpackChunkName: "IndexListRole" */ '../views/Apps/Referensi/ListRole/IndexListRole.vue')
// const TambahListRole = () => import(/* webpackChunkName: "TambahListRole" */ '../views/Apps/Referensi/ListRole/TambahListRole.vue')
const Home = () => import(/* webpackChunkName: "Index" */ '../views/Apps/Referensi/index.vue')


var prop = 'referensi'

const ReferensiRouter = [
	{
		path: prop + '/e-surat/',
		name: prop + '.e-surat',
		meta: { auth: true, name: 'Klasifikasi Surat', permission: 'Dewasa', roles: allRole },
		component: Home,
		children: [
      {
				path: 'klasifikasi-surat',
				name: prop + '.klasifikasi-surat',
				meta: { auth: true, name: 'Klasifikasi Surat', permission: 'Dewasa', roles: allRole },
				component: IndexKlasifikasi
			},
			{
				path: 'tambah-klasifikasi-surat',
				name: prop + '.tambah-klasifikasi-surat',
				meta: { auth: true, name: 'Tambah Klasifikasi Surat', permission: 'Dewasa', roles: allRole },
				component: TambahKlasifikasi
			},
			{
				path: 'sifat-surat',
				name: prop + '.sifat-surat',
				meta: { auth: true, name: 'Sifat Surat', permission: 'Dewasa', roles: allRole },
				component: IndexSifat
			},
			{
				path: 'tambah-sifat-surat',
				name: prop + '.tambah-sifat-surat',
				meta: { auth: true, name: 'Tambah Sifat Surat', permission: 'Dewasa', roles: allRole },
				component: TambahSifat
			},
			{
				path: 'surat-disposisi',
				name: prop + '.surat-disposisi',
				meta: { auth: true, name: 'Surat Disposisi', permission: 'Dewasa', roles: allRole },
				component: IndexDisposisi
			},
			{
				path: 'tambah-surat-disposisi',
				name: prop + '.tambah-surat-disposisi',
				meta: { auth: true, name: 'Tambah Surat Disposisi', permission: 'Dewasa', roles: allRole },
				component: TambahDisposisi
			},
			{
				path: 'jenis-surat',
				name: prop + '.jenis-surat',
				meta: { auth: true, name: 'Jenis Surat', permission: 'Dewasa', roles: allRole },
				component: IndexJenis
			},
			{
				path: 'tambah-jenis-surat',
				name: prop + '.tambah-jenis-surat',
				meta: { auth: true, name: 'Tambah Jenis Surat', permission: 'Dewasa', roles: allRole },
				component: TambahJenis
			},
			{
				path: 'surat-tujuan',
				name: prop + '.surat-tujuan',
				meta: { auth: true, name: 'Surat Tujuan', permission: 'Dewasa', roles: allRole },
				component: IndexTujuan
			},
		
			{
				path: 'tambah-surat-tujuan',
				name: prop + '.tambah-surat-tujuan',
				meta: { auth: true, name: 'Tambah Surat Tujuan', permission: 'Dewasa', roles: allRole },
				component: TambahTujuan
			},
			{
				path: 'tujuan-nota',
				name: prop + '.tujuan-nota',
				meta: { auth: true, name: 'Tujuan Nota', permission: 'Dewasa', roles: allRole },
				component: IndexNota
			},
			{
				path: 'tambah-tujuan-nota',
				name: prop + '.tambah-tujuan-nota',
				meta: { auth: true, name: 'Tambah Nota', permission: 'Dewasa', roles: allRole },
				component: TambahNota
			},
			{
				path: 'edit-tujuan-nota/:id',
				name: prop + '.edit-tujuan-nota',
				meta: { auth: true, name: 'Edit Nota', permission: 'Dewasa', roles: allRole },
				component: TambahNota
			},
			{
				path: 'grup-surat',
				name: prop + '.grup-surat',
				meta: { auth: true, name: 'Grup Surat', permission: 'Dewasa', roles: allRole },
				component: IndexGrup
			},
			{
				path: 'tambah-grup-surat',
				name: prop + '.tambah-grup-surat',
				meta: { auth: true, name: 'Tambah Grup Surat', permission: 'Dewasa', roles: allRole },
				component: TambahGrup
			},
			{
				path: 'surat-catatan',
				name: prop + '.surat-catatan',
				meta: { auth: true, name: 'Surat Catatan', permission: 'Dewasa', roles: allRole },
				component: IndexCatatan
			},
			{
				path: 'tambah-surat-catatan',
				name: prop + '.tambah-surat-catatan',
				meta: { auth: true, name: 'Tambah Surat Catatan', permission: 'Dewasa', roles: allRole },
				component: TambahCatatan
			},
			{
				path: 'status-surat',
				name: prop + '.status-surat',
				meta: { auth: true, name: 'Status Surat', permission: 'Dewasa', roles: allRole },
				component: IndexStatus
			},
			{
				path: 'tambah-status-surat',
				name: prop + '.tambah-status-surat',
				meta: { auth: true, name: 'Tambah Status Surat', permission: 'Dewasa', roles: allRole },
				component: TambahStatus
			},
			{
				path: 'bidang',
				name: prop + '.bidang-index',
				meta: { auth: true, name: 'Bidang', permission: 'Dewasa', roles: allRole },
				component: IndexBidang
			},
			{
				path: 'tambah-bidang',
				name: prop + '.tambah-bidang',
				meta: { auth: true, name: 'Tambah Bidang', permission: 'Dewasa', roles: allRole },
				component: TambahBidang
			},
			{
				path: 'surat-bidang',
				name: prop + '.surat-bidang',
				meta: { auth: true, name: 'Surat Bidang', permission: 'Dewasa', roles: allRole },
				component: IndexSuratBidang
			},
			{
				path: 'tambah-surat-bidang',
				name: prop + '.tambah-surat-bidang',
				meta: { auth: true, name: 'Tambah Surat Bidang', permission: 'Dewasa', roles: allRole },
				component: TambahSuratBidang
			},
			{
				path: 'tingkatan-jabatan',
				name: prop + '.tingkatan-jabatan',
				meta: { auth: true, name: 'Tingkatan Jabatan', permission: 'Dewasa', roles: allRole },
				component: IndexTingkatanJabatan
			},
			{
				path: 'tambah-tingkatan-jabatan',
				name: prop + '.tambah-tingkatan-jabatan',
				meta: { auth: true, name: 'Tambah Tingkatan Jabatan', permission: 'Dewasa', roles: allRole },
				component: TambahTingkatanJabatan
			},
			{
				path: 'stempel',
				name: prop + '.stempel',
				meta: { auth: true, name: 'Tanda Tangan dan Stempel', permission: 'Dewasa', roles: allRole },
				component: IndexTTD
			},
			// {
			// 	path: 'list-role',
			// 	name: prop + '.list-role',
			// 	meta: { auth: true, name: 'Tingkatan Jabatan', permission: 'Dewasa', roles: allRole },
			// 	component: IndexListRole
			// },
			// {
			// 	path: 'tambah-list-role',
			// 	name: prop + '.tambah-list-role',
			// 	meta: { auth: true, name: 'Tambah Tingkatan Jabatan', permission: 'Dewasa', roles: allRole },
			// 	component: TambahListRole
			// }
    ],
	},
	
]

export default ReferensiRouter
