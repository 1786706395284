import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  tweet: [],
  detailTweets:{},
  berita: [],
  detailBerita:{},
  panduan: [],
  detailPanduan:{},
  buku: [],
  detailBuku:{},
  learning: [],
  detailLearning:{},
  comment:[],
  detailComment:{},
  stop: false

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
