const UserManagement = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/UserManagement.vue')
const RoleManagement = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/RoleManagement.vue')
const AccessManagement = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/AccessManagement.vue')
const MenuManagement = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/MenuManagement.vue')
const KelolaPM = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/KelolaPM.vue')
const LogEsurat = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/RoleManagement/LogEsurat.vue')

var prop = 'usersocmed'

const RoleManagementRouter = [
	{
		path: '/sosmed/user',
		name: prop + '.user',
		meta: { auth: true, name: 'User', roles: ['Admin Aplikasi'] },
		component: UserManagement
	},
	{
		path: '/sosmed/role',
		name: prop + '.role',
		meta: { auth: true, name: 'Role', roles: ['Admin Aplikasi'] },
		component: RoleManagement
	},
	{
		path: '/sosmed/access',
		name: prop + '.access',
		meta: { auth: true, name: 'Access', roles: ['Admin Aplikasi'] },
		component: AccessManagement
	},
	{
		path: '/sosmed/menus',
		name: prop + '.menus',
		meta: { auth: true, name: 'Menus', roles: ['Admin Aplikasi'] },
		component: MenuManagement
	},
	{
		path: '/sosmed/pendataan-mandiri',
		name: prop + '.pendataan-mandiri',
		meta: { auth: true, name: 'Pendataan Mandiri', roles: ['Admin Aplikasi'] },
		component: KelolaPM
	},
	{
		path: '/sosmed/log-esurat',
		name: prop + '.log-esurat',
		meta: { auth: true, name: 'Log E-Surat', roles: ['Admin Aplikasi'] },
		component: LogEsurat
	}
]

export default RoleManagementRouter
