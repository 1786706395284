export default {
  getTitle: (state) => state.title,
  getIsAdmin: (state) => state.isAdmin,
  getPelatihanDetail: (state) => state.pelatihanDetail,
  getSesiDetail: (state) => state.sesiDetail,
  getSesiForm: (state) => state.sesiForm,
  getEvaluasiForm: (state) => state.evaluasiForm,
  getTugasForm: (state) => state.tugasForm,
  getUjianForm: (state) => state.ujianForm,
  getSoal: (state) => state.soals,
  getAsesmenForm: (state) => state.asesmenForm,
  getLoadingDetailSesi: (state) => state.loadingDetailSesi,
  getLampiran: (state) => state.lampiran,
}
