import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  Notification : [],
  notifCount : 0,
  error : null,
  login : false,
  filter:null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
