import { whereRole, kwartirRole, allRole } from '@/plugins/RolePermision/role.js'

const SafeFromHarm = () => import(/* webpackChunkName: "SafeFromHarm" */ '../views/Apps/SafeFromHarm/Page/PageIndex.vue')
const PageSertifikatList = () => import(/* webpackChunkName: "SafeFromHarm" */ '../views/Apps/SafeFromHarm/Page/PageSertifikatList.vue')

var prop = 'safe-from-harm'

const SafeFromHarmRouter = [
	{
		path: '/' + prop,
		name: prop + '.list',
		meta: {
			auth: true,
			name: 'Safe From Harm',
			permission: 'Dewasa',
			roles: allRole
		},
		component: SafeFromHarm
	},
	{
		path: '/' + prop + '/sertifikat-saya',
		name: prop + '.sertifikat-saya',
		meta: {
			auth: true,
			name: 'Sertifikat Saya',
			permission: 'Dewasa',
			roles: allRole
		},
		component: PageSertifikatList
	}
]

export default SafeFromHarmRouter
