import { whereRole, kwartirRole, allRole } from '@/plugins/RolePermision/role.js'

const TambahAnggota = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/RegisterMandiri/PageTambahAnggota.vue')
const DaftarTransaksi = () => import(/* webpackChunkName: "daftar-pesanan" */ '../views/Apps/RegisterMandiri/PageDaftarTransaksi.vue')
const DaftarPesanan = () => import(/* webpackChunkName: "DaftarPesanan" */ '../views/Apps/RegisterMandiri/PageDaftarPesanan.vue')
const CetakMandiri = () => import(/* webpackChunkName: "CetakMandiri" */ '../views/Apps/RegisterMandiri/PageCetakMandiri.vue')
const TarikSaldo = () => import(/* webpackChunkName: "TarikSaldo" */ '../views/Apps/RegisterMandiri/PageTarikSaldo.vue')
const PageIzinAkses = () => import(/* webpackChunkName: "PageIzinAkses" */ '../views/Apps/RegisterMandiri/PageIzinAkses.vue')
const PageRekapPendataan = () => import(/* webpackChunkName: "PageRekapPendataan" */ '../views/Apps/RekapData/Page/PageRekapPendataan.vue')
const PageDetailPendataan = () => import(/* webpackChunkName: "PageDetailPendataan" */ '../views/Apps/RekapData/Page/PageRekapDetailData.vue')
const PageEditPendataan = () => import(/* webpackChunkName: "PageEditPendataan" */ '../views/Apps/RekapData/Page/PageEditRekapData.vue')

var prop = 'social'

const SelfRegisterRouter = [
	{
		path: '/tambah-anggota',
		name: prop + '.tambah-anggota',
		meta: {
			auth: true,
			name: 'Tambah Anggota',
			permission: 'Dewasa',
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: TambahAnggota
	},
	{
		path: '/daftar-pesanan',
		name: prop + '.daftar-pesanan',
		meta: {
			auth: true,
			name: 'Daftar Pesanan',
			permission: 'Dewasa',
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: DaftarPesanan
	},
	{
		path: '/daftar-transaksi',
		name: prop + '.daftar-transaksi',
		meta: {
			auth: true,
			name: 'Daftar transaksi',
			permission: 'Dewasa',
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: DaftarTransaksi
	},
	{
		path: '/cetak-mandiri',
		name: prop + '.cetak-mandiri',
		meta: {
			auth: true,
			name: 'Cetak Mandiri',
			permission: 'Dewasa',
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: CetakMandiri
	},
	{
		path: '/tarik-saldo',
		name: prop + '.tarik-saldo',
		meta: {
			auth: true,
			name: 'Tarik Saldo',
			// roles: allRole
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: TarikSaldo
	},
	{
		path: '/pendataan-mandiri-izin-akses',
		name: prop + '.izin-akses',
		meta: {
			auth: true,
			name: 'Izin Akses',
			// roles: allRole
			roles: whereRole([
				// 'Admin Korwil Pendataan',
				'Admin Aplikasi'
				// 'Admin Pendataan Mandiri'
			])
		},
		component: PageIzinAkses
	},
	{
		path: '/rekap-pendataan',
		name: prop + '.rekap-pendataan',
		meta: {
			auth: true,
			name: 'Rekap Pendataan',
			permission: 'Dewasa',
			roles: whereRole([
				'Admin Aplikasi'
				// 'Admin Pusdatin', 'Admin Formulir'
			])
		},
		component: PageRekapPendataan
	},
	{
		path: '/rekap-pendataan/:id',
		name: prop + '.rekap-detail-pendataan',
		meta: {
			auth: true,
			name: 'Detail Data Pendataan',
			permission: 'Dewasa',
			roles: whereRole([
				'Admin Aplikasi'
				// 'Admin Pusdatin', 'Admin Formulir'
			])
		},
		component: PageDetailPendataan
	},
	{
		path: '/rekap-pendataan-update/:id',
		name: prop + '.rekap-update-pendataan',
		meta: {
			auth: true,
			name: 'Update Data Pendataan',
			permission: 'Dewasa',
			roles: whereRole([
				'Admin Aplikasi'
				// 'Admin Pusdatin', 'Admin Formulir'
			])
		},
		component: PageEditPendataan
	}
]

export default SelfRegisterRouter
