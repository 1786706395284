export default {
  setListMuscab (state, payload) {
    
    state.listMuscab = payload
  },

  addListMuscab (state, payload) {
    state.listMuscab.unshift(payload)
  }
}
