import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  PenggunaDisarankan: [],
  Berita: [],
  Tweet: [],
  myTweet: [],
  loading: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
