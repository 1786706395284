import axios from 'axios'
import router from '../../router'
import Swal from 'sweetalert2'
import { auth } from '../../firebase'
import { getMe } from '../../plugins/method'
import store from '../index.js'
// import jwt from 'jsonwebtoken'
import {
	getAuth,
	browserLocalPersistence,
	deleteUser,
	getAdditionalUserInfo,
	signInWithPopup,
	signOut,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	updatePassword,
	reauthenticateWithCredential,
	EmailAuthProvider,
	browserSessionPersistence,
	setPersistence,
	confirmPasswordReset
} from 'firebase/auth'

export default {
	async gantiPassword(context, payload) {
		const credential = EmailAuthProvider.credential(auth.currentUser.email, payload.passwordLama)
		reauthenticateWithCredential(auth.currentUser, credential)
			.then(() => {
				updatePassword(auth.currentUser, payload.passwordBaru)
					.then(() => {
						Swal.fire({
							title: 'Berhasil',
							text: 'Berhasil mengubah password',
							icon: 'success',
							iconColor: '#8E4AA1',
							showConfirmButton: false,
							timer: 4000
						})
					})
					.catch((error) => {
						console.log(error)
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Password tidak berhasil diubah',
							showConfirmButton: false,
							timer: 2000
						})
					})
			})
			.catch((error) => {
				console.log(error.code)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Password yang lama salah',
					showConfirmButton: false,
					timer: 2000
				})
			})
	},
	async forgotPassword(context, payload) {
		const auth = getAuth()
		sendPasswordResetEmail(auth, payload.email)
			.then(() => {
				Swal.fire({
					title: 'Berhasil',
					text: 'Link reset password berhasil dikirim ke alamat email Kakak, Silahkan melakukan reset password menggunakan link tersebut',
					icon: 'success',
					iconColor: '#8E4AA1',
					showConfirmButton: false,
					timer: 4000
				})
				context.commit('loadingCommit', false)
			})
			.catch((error) => {
				Swal.fire({
					title: 'Error',
					text: 'Email pengguna tidak ditemukan',
					icon: 'error',
					iconColor: '#8E4AA1',
					showConfirmButton: false,
					timer: 2000
				})
				context.commit('loadingCommit', false)
			})
	},
	async resetPassword(context, payload) {
		const auth = getAuth()

		confirmPasswordReset(auth, payload.actionCode, payload.password)
			.then((resp) => {
				Swal.fire({
					title: 'Berhasil',
					text: 'Password Baru Berhasil Dibuat',
					icon: 'success',
					iconColor: '#8E4AA1',
					showConfirmButton: false,
					timer: 4000
				})
				context.commit('loadingCommit', false)
				router.push({ path: '/auth/signin' })
			})
			.catch((error) => {
				Swal.fire({
					title: 'Gagal',
					text: 'Password pengguna Gagal disimpan',
					icon: 'error',
					iconColor: '#8E4AA1',
					showConfirmButton: false,
					timer: 2000
				})
				context.commit('loadingCommit', false)
			})
	},
	async onRegister(context, payload) {
		context.commit('errorCommit', null)
		if (payload.password !== payload.confirmPassword) {
			context.commit('errorCommit', 'Password yang dimasukkan tidak sama')
		} else {
			// const uid = await localStorage.getItem('userId')
			const uid = await auth?.currentUser?.uid
			axios
				.post('api/auth/register', {
					uid: uid,
					fcmToken: uid,
					nickname: payload.data.name,
					password: payload.password,
					userModel: {
						username: payload.username,
						noHp: payload.data?.phone || null,
						email: payload.data?.email || null,
						pangkalan: payload.data?.pangkalan || null,
						agama: payload.data?.agama || null,
						kwarran: payload.data?.kwarran || null,
						followers: payload.data?.followers || null,
						id: payload.data?.anggota_id || null,
						webSite: payload.data?.webSite || null,
						jenisAnggota: payload.data?.jenisAnggota || null,
						ntaKwarran: payload.data?.ntaKwarran || null,
						profilePicUrl: payload.data?.profilePicUrl || null,
						ntaKwarcab: payload.data?.ntaKwarcab || null,
						fcmToken: uid || null,
						silentPersonLis: payload.data?.silentPersonLis || null,
						kwarcab: payload.data?.kwarcab || null,
						userId: uid || null,
						blockedList: payload.data?.blockedList || null,
						golPramuka: payload.data?.golPramuka || null,
						tempatLahir: payload.data?.tempatLahir || null,
						following: payload.data?.following || null,
						notInterestPostList: payload.data?.notInterestPostList || null,
						createdAt: payload.data?.createdAt || null,
						bio: payload.data?.bio || null,
						contact: payload.data?.contact || null,
						key: uid || null,
						location: payload.data?.location || null,
						userName: payload.username || null,
						is_admin: payload.data?.is_admin || null,
						profilePic: payload.data?.profilePic || null,
						followingList: payload.data?.followingList || null,
						blockingList: payload.data?.blockingList || null,
						dob: payload.data?.dob || null,
						followerList: payload.data?.followerList || null,
						ntaPrimer: payload.data?.ntaPrimer || null,
						displayName: payload.data?.displayName || null,
						ntaKwarda: payload.data?.ntaKwarda || null,
						isVerified: payload.data?.isVerified || null,
						penggunaDisarankan: payload.data?.penggunaDisarankan || null,
						jenisKelamin: payload.data?.jenisKelamin || null,
						alamatPrimer: payload.data?.alamatPrimer || null,
						kwarda: payload.data?.kwarda || null,
						chatUsers: payload.data?.chatUsers || null,
						notification: payload.data?.notification || null,
						anggotaPenghargaan: payload.data?.anggotaPenghargaan || null,
						anggotaSekunder: payload.data?.anggotaSekunder || null,
						anggotaTkk: payload.data?.anggotaTkk || null,
						followersList: payload.data?.followerList || null,
						tanggalLahir: payload.data?.tanggalLahir || null,
						tanggalLantik: payload.data?.tanggalLantik || null,
						tkk: payload.data?.tkk || null,
						golDarah: payload.data?.golDarah || null,
						kegiatan: payload.data?.kegiatan || null
					}
				})
				.then(async (res) => {
					// await localStorage.setItem("userId", uid)
					context.commit('userCommit', auth.currentUser)
					// axios.defaults.headers.common['userID'] = await localStorage.getItem("userId");
					// axios.defaults.headers.common['userID'] = await auth?.currentUser?.uid
					// var token = await jwt.sign(auth?.currentUser?.uid, process.env.VUE_APP_API_AUTH)
					// await localStorage.setItem('token', token)
					router.push({ name: 'social.list' })
				})
				.catch((error) => {
					// context.commit('errorCommit', "tidak berhasil mendaptarkan data")
					// const auth = getAuth()
					deleteUser(auth.currentUser)
						.then(() => {
							context.commit('errorCommit', 'Akun belum terdaftar')
						})
						.catch((error) => {
							context.commit('errorCommit', 'Akun belum terdaftar')
						})
					context.commit('loadingCommit', false)
				})
		}
	},
	onSubmit(context, payload) {
		let bufferErrorCount = JSON.parse(localStorage.getItem('errorCount')) || []
		context.commit('errorCommit', null)
		setPersistence(auth, browserLocalPersistence).then(() => {
			signInWithEmailAndPassword(auth, payload.email, payload.password)
				.then(async (userCredential) => {
					console.log(userCredential)
					await axios.post('/api/firebase-auth', { firebase_token: userCredential._tokenResponse.idToken }).then(async (response) => {
						if (response.status === 200) {
							localStorage.setItem('token', response?.data?.data?.token?.access_token)
							localStorage.setItem('reToken', response?.data?.data?.token?.refresh_token)
							// axios.defaults.headers['Authorization'] = 'Bearer ' + response?.data?.data?.token?.access_token
							// axios.defaults.headers.post['Authorization'] = 'Bearer ' + response?.data?.data?.token?.access_token
							// axios.defaults.headers.common['userID'] = userCredential.user.uid
							const roles = response.data.data?.user?.roles
							await store.commit('RolePermission/setRoles', roles)
							const result = await axios.get('/api/auth/me')
							if (result.status == 200) {
								localStorage.setItem(
									'emailPhone',
									JSON.stringify({
										email: result.data.data.email,
										noHp: result.data.data.noHp
									})
								)
								await context.commit('userProfileCommit', result.data.data)
								await context.commit('userCommit', result.data.data)
								await context.commit('panggilanCommit', result.data?.data?.jenisAnggota === 'Muda' ? 'Adik' : 'Kakak')
							}
						}
					})
					const user = userCredential.user

					if (user.uid == process.env.VUE_APP_IDGUEST) {
						router.push('/berita')
					} else {
						router.push('/beranda')
						bufferErrorCount = bufferErrorCount.filter((res) => {
							return res.email !== payload.email
						})

						localStorage.setItem('errorCount', JSON.stringify(bufferErrorCount))
					}

					// await getMe()
					// await localStorage.setItem("userId", user.uid)
					// axios.defaults.headers.common['userID'] = await localStorage.getItem("userId");
					// axios.defaults.headers.common['userID'] = user.uid
					context.commit('userDataCommit', auth.currentUser)
					await axios.post('api/user/toggle-online', { userId: this.$store.state.Auth.userData.uid })
				})
				.catch((error) => {
					context.commit('loadingCommit', false)

					if (error.code == 'auth/user-not-found') {
						context.commit('errorCommit', 'Akun tidak ditemukan')
					}
					if (error.code == 'auth/wrong-password') {
						const bufferUser = bufferErrorCount.find((res) => res.email === payload.email)
						if (bufferUser && bufferUser.count === 5) {
							context.commit('errorCommit', 'Password salah sudah 5 kali, silahkan coba 5 menit lagi')
						} else {
							context.commit('errorCommit', 'Password salah')
						}
						if (bufferErrorCount.length === 0) {
							bufferErrorCount.push({
								email: payload.email,
								count: 1,
								createdAt: new Date()
							})
						} else {
							if (!bufferUser) {
								bufferErrorCount.push({
									email: payload.email,
									count: 1,
									createdAt: new Date()
								})
							} else {
								const newCount = new Date() > new Date(bufferUser.createdAt) ? 1 : bufferUser.count < 5 ? bufferUser.count + 1 : 5
								bufferErrorCount = bufferErrorCount.map((res) => {
									if (res.email === payload.email) {
										return {
											...res,
											count: newCount,
											createdAt: new Date()
										}
									} else {
										return res
									}
								})
							}
						}

						localStorage.setItem('errorCount', JSON.stringify(bufferErrorCount))
					}
					if (error.code === 'auth/too-many-requests') {
						context.commit('errorCommit', `Percobaan login terlalu banyak, silahkan coba beberapa saat lagi`)
					}
				})
		})
	},
	async onSubmitGuest(context, payload) {},
	async validateGuest(context, payload) {
		if (auth?.currentUser?.uid == process.env.VUE_APP_IDGUEST) {
			if (payload.alert) {
				Swal.fire({
					title: 'Akses Dibatasi',
					text: 'Login pengguna untuk melanjutkan',
					icon: 'warning',
					iconColor: '#8E4AA1',
					input: 'email',
					inputLabel: 'Alamat Email',
					inputPlaceholder: 'Masukkan Alamat Email',
					showCancelButton: true,
					showConfirmButton: false
				})
			}
			return true
		} else {
			return false
		}
		context.commit('loadingCommit', false)
		router.push({ name: 'social.list' })
	},
	async loginGoogle(context, payload) {
		const provider = new GoogleAuthProvider()
		// select account
		provider.setCustomParameters({
			prompt: 'select_account'
		})
		setPersistence(auth, browserLocalPersistence).then(() => {
			signInWithPopup(auth, provider)
				.then(async (result) => {
					const { isNewUser } = getAdditionalUserInfo(result)
					if (isNewUser) {
						const email = result.user.email
						await axios
							.get(`api/user/get-detail-by-email?email=${email}`)
							.then(async (response) => {
								if (response.status === 200) {
									const data = response.data.data
									context.commit('userRegist', data)
									const user = result.user

									context.commit('loadingCommit', false)
									router.push({ name: 'auth1.sign-up1' })
								}
							})
							.catch((error) => {
								// context.commit('errorCommit', "Akun tidak ditemukan")
								deleteUser(auth.currentUser)
									.then(() => {
										context.commit('errorCommit', 'Akun belum terdaftar')
									})
									.catch((error) => {
										context.commit('errorCommit', 'Akun belum terdaftar')
									})
							})
					} else {
						// This gives you a Google Access Token. You can use it to access the Google API.
						GoogleAuthProvider.credentialFromResult(result)
						// const token = credential.accessToken
						const user = result.user
						await axios.post('/api/firebase-auth', { firebase_token: result._tokenResponse.idToken }).then((response) => {
							if (response.status === 200) {
								localStorage.setItem('token', response?.data?.data?.token?.access_token)
								localStorage.setItem('reToken', response?.data?.data?.token?.refresh_token)
							}
						})

						// axios.defaults.headers.common['userID'] = user.uid
						context.commit('userDataCommit', auth.currentUser)
						// axios.post('api/user/toggle-online', { userId: user.uid,isOnline:1 })
						router.push('/beranda')
						// await getMe()
					}
				})
				.catch((error) => {
					const errorCode = error.code
					const errorMessage = error.message
					const email = error.email
					// The AuthCredential type that was used.
					const credential = GoogleAuthProvider.credentialFromError(error)
					if (error.code === 'auth/wrong-password') {
						context.commit('errorCommit', 'Password salah')
					}
					if (error.code === 'auth/user-not-found') {
						context.commit('errorCommit', 'Akun tidak ditemukan')
					}
				})
		})
	},
	async signOut(context) {
		localStorage.removeItem('token')
		localStorage.removeItem('reToken')
		localStorage.removeItem('vuex')
		localStorage.removeItem('closeHUT')
		await signOut(auth)
			.then(async () => {
				await axios.post('api/user/toggle-online', { userId: context, isOnline: 0 })

				context.commit('userCommit', null)
				router.push({ name: 'auth1.sign-in1' })
			})
			.catch((error) => {
				console.log(error)
			})
	},
	async userProfile(context, payload) {
		try {
			const response = await axios.get('api/user/get-user?userId=' + payload)
			if (response.status === 200) {
				context.commit('userCommit', response.data.data)
				context.commit('loginCommit', true)
				context.commit('panggilanCommit', response.data.data?.jenisAnggota == 'Muda' ? 'Adik' : 'Kakak')

				await axios.post('api/user/toggle-online', { userId: response.data.data.userId, isOnline: 1 })
				return true
			}
		} catch (error) {
			return error
		}
	},
	fetchUser(context) {
		auth.onAuthStateChanged(async (user) => {
			if (user === null) {
				context.commit('userDataCommit', null)
			} else {
				context.commit('userDataCommit', user)
				// axios.defaults.headers.common['userID'] = user.uid
				if (router.isReady() && router.currentRoute.value.path === '/auth/signin') {
					router.push('/beranda')
				}
			}
		})
	},
	async reloadUser(context) {
		try {
			axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
			axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`
			let response = await axios.get('/api/auth/me', {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				}
			})
			if (response.status == 200) {
				localStorage.setItem(
					'emailPhone',
					JSON.stringify({
						email: response.data.data.email,
						noHp: response.data.data.noHp
					})
				)
				// axios.defaults.headers.common['userID'] = response?.data?.data?.userId
				await context.commit('userCommit', response?.data?.data)
				await context.commit('panggilanCommit', response?.data?.data?.jenisAnggota === 'Muda' ? 'Adik' : 'Kakak')
			}
		} catch (error) {
			if (error?.response?.status == 404) {
				context.commit('errorCommit', 'login gagal')
				router.push({ name: 'auth1.sign-in1' })
			}
			console.log(error)
		}
	}
}
