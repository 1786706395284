import getters from './getters'
import actions from './action'
import mutations from './mutation'

const state = {
	title: 'Ruang Diklat',
	isAdmin: false,
	pelatihanDetail: {},
	sesiDetail: {},
	loadingDetailSesi: false,
	lampiran: [],
	sesiForm: {
		pelatihan_id: '',
		nama: '',
		tanggal: '',
		jam_mulai: '',
		jam_selesai: '',
		jenis_sesi: 'offline',
		ruang: '',
		catatan: ''
	},
	evaluasiForm: {
		simpan_kreteria: false,
		kreteria: []
	},
	tugasForm: {
		// catatan: '',
		// judul: '',
		// tanggal: null,
		// jam_selesai: null
	},
	ujianForm: {
		// judul: '',
		// tanggal: '',
		// jam_mulai: '',
		// jam_selesai: '',
		// durasi: '',
	},
	soals: [
		// {
		// 	no: 1,
		// 	soal: '',
		// 	image: '',
		// 	pilihan: [
		// 		{
		// 			text: '',
		// 			is_true: false
		// 		}
		// 	],
		// 	jenis_soal: 'pg',
		// 	nilai: ''
		// },
		// {
		// 	no: 2,
		// 	soal: '',
		// 	image: '',
		// 	pilihan: [],
		// 	jenis_soal: 'isian',
		// 	nilai: ''
		// }
	],
	asesmenForm: []
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
