import { auth } from "../../firebase"
import axios from "axios"
export default {
  async NotifCountAction(context, payload) {
    try {
      // let response = await axios.get("api/general/notification/count?userId="+localStorage.getItem("userId"))
      let response = await axios.get("/api/general/notification/count?userId="+auth?.currentUser?.uid)
      if (response.status == 200) {
        context.commit('notifCountCommit', response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async NotificationAction(context, payload) {
    try {
      let response = await axios.get("/api/general/notification",{params:payload})
      if (response.status == 200) {
        context.commit('notifListCommit', response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
}
