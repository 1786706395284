import store from '../../store/index.js'

export const handleConfirm = (functional) => {
	store.dispatch('Modal/saveFunction', async () => {
		handleLoadingConfirm(true)
		await functional.apply(this)
		handleLoadingConfirm(false)
		handleCloseModalConfirm()
	})
}

export const handleDenied = (functional) => {
	store.dispatch('Modal/saveDeniedFunction', async () => {
		handleLoadingConfirm(true)
		await functional.apply(this)
		handleLoadingConfirm(false)
		handleCloseModalConfirm()
	})
}

export const handleCustomContent = (loading) => {
	store.dispatch('Modal/handleCustomContent', loading)
}

export const handleLoadingConfirm = (loading) => {
	store.dispatch('Modal/handleLoading', loading)
}

export const handleCloseModalConfirm = () => {
	store.dispatch('Modal/closeModal')
}

export const openModalConfirm = (data) => {
	store.dispatch('Modal/openModal', data)
}

export const openModalInfo = (data) => {
	store.dispatch('Modal/openModalInfo', data)
}
