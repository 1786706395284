import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
	user: {},
	userData: null,
	panggilan: null,
	error: null,
	login: false,
	loading: false,
	chat: {},
	regis: null,
	wilayah: {},
	userProfile: {},
	currentStepBio: 0,
	formEditProfile: {}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
