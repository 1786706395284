import { allRole, whereRole, WhereNotRole, kwartirRole, garudaRole } from '@/plugins/RolePermision/role.js'

// LAPORAN
const Rekap = () => import(/* webpackChunkName: "Rekap" */ '../views/Apps/Report Rekap Golongan/ReportRekap.vue')
const RekapTKK = () => import(/* webpackChunkName: "RekapTKK" */ '../views/Apps/Report Rekap Golongan/TKK.vue')
const RekapTKU = () => import(/* webpackChunkName: "RekapTKU" */ '../views/Apps/Report Rekap Golongan/TKU.vue')
const RekapKMD = () => import(/* webpackChunkName: "RekapKMD" */ '../views/Apps/Report Rekap Golongan/KMD.vue')
const RekapKML = () => import(/* webpackChunkName: "RekapKML" */ '../views/Apps/Report Rekap Golongan/KML.vue')
const RekapKPD = () => import(/* webpackChunkName: "RekapKPD" */ '../views/Apps/Report Rekap Golongan/KPD.vue')
const RekapKPL = () => import(/* webpackChunkName: "RekapKPL" */ '../views/Apps/Report Rekap Golongan/KPL.vue')
const RekapPdf = () => import(/* webpackChunkName: "RekapPdf" */ '../views/Apps/Report Rekap Golongan/Components/Pdf.vue')
const PagePresensi = () => import(/* webpackChunkName: "PagePresensi" */ '../views/Apps/Presensi/Page/PagePresensi.vue')
const AbsensiPdf = () => import(/* webpackChunkName: "AbsensiPdf" */ '../views/Apps/Absensi/Print.vue')
const Potensi = () => import(/* webpackChunkName: "Potensi" */ '../views/Apps/Potensi/PagePotensi.vue')
const PagePotensi = () => import(/* webpackChunkName: "Potensi" */ '../views/Apps/DataPotensi/PageDataPotensi.vue')
const Pencapaian = () => import(/* webpackChunkName: "Pencapaian" */ '../views/Apps/DataPotensi/PagePencapaian.vue')
const DataAnggota = () => import(/* webpackChunkName: "DataAnggota" */ '../views/Apps/DataAnggota/PageDataAnggota.vue')
const Biodata = () => import(/* webpackChunkName: "DataPotensi" */ '../views/Apps/DataPotensi/PageDetail.vue')
const EditBiodata = () => import(/* webpackChunkName: "EditBiodata" */ '../views/Apps/DataPotensi/PageEditBiodata.vue')
const RekapKepramukaan = () => import(/* webpackChunkName: "RekapKepramukaan" */ '../views/Apps/RekapKepramukaan/Index.vue')
const RekapDataPotensi = () => import(/* webpackChunkName: "RekapDataPotensi" */ '../views/Apps/RekapDataPotensi/Index.vue')
const PramukaGaruda = () => import(/* webpackChunkName: "PramukaGaruda" */ '../views/Apps/PramukaGaruda/Page/PageListPengajuan.vue')
const DetailPramukaGaruda = () => import(/* webpackChunkName: "PramukaGaruda" */ '../views/Apps/PramukaGaruda/Page/PageDetailPengajuan.vue')
const PageRekapData = () => import(/* webpackChunkName: "PageRekapData" */ '../views/Apps/RekapData/Page/PageRekapData.vue')
const PageRekapDataModuleAkses = () =>
	import(/* webpackChunkName: "PageRekapDataModuleAkses" */ '../views/Apps/RekapData/Page/PageRekapDataModuleAkses.vue')
const PageRekapDetailData = () => import(/* webpackChunkName: "PageRekapDetailData" */ '../views/Apps/RekapData/Page/PageRekapDetailData.vue')
const PageEditRekapData = () => import(/* webpackChunkName: "PageEditRekapData" */ '../views/Apps/RekapData/Page/PageEditRekapData.vue')
const PageListPengajuanMutasi = () => import(/* webpackChunkName: "ListPengajuanMutasi" */ '../views/Apps/DataPotensi/PageListPengajuanMutasi.vue')
const PagePengajuanMutasi = () => import(/* webpackChunkName: "PengajuanMutasi" */ '../views/Apps/DataPotensi/PagePengajuanMutasi.vue')
const PageRekapKepemilikan = () => import(/* webpackChunkName: "PageRekapKepemilikan" */ '../views/Apps/RekapData/Page/PageRekapKepemilikan.vue')
const PageBiodataPencapaian = () => import(/* webpackChunkName: "PageBiodataPencapaian" */ '../views/Apps/DataPotensi/PageSkuSkk.vue')
const PageTerapkanDataAnggota = () =>
	import(/* webpackChunkName: "PageTerapkanDataAnggota" */ '../views/Apps/RekapData/Page/PageTerapkanDataAnggota.vue')
const PageKelolaLeveling = () => import(/* webpackChunkName: "PageRekapDetailData" */ '../views/Apps/DataPotensi/PageKelolaLeveling.vue')
const PageRekapFormulirKorwil = () =>
	import(/* webpackChunkName: "PageRekapFormulirKorwil" */ '../views/Apps/RekapFormulirKorwil/Pages/PageRekapFormulirKorwil.vue')
const PageFormulirKorwil = () => import(/* webpackChunkName: "PageFormulirKorwil" */ '../views/Apps/RekapFormulirKorwil/Pages/PageFormulirKorwil.vue')

var prop = 'social'

const LaporanRouter = [
	{
		path: '/rekap-golongan',
		name: prop + '.rakap',
		meta: { auth: true, name: 'Report Rekap Golongan', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: Rekap
	},
	{
		path: '/rekap-golongan/tkk',
		name: prop + '.rakap.tkk',
		meta: { auth: true, name: 'Report Rekap TKK', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapTKK
	},
	{
		path: '/rekap-golongan/tku',
		name: prop + '.rakap.tku',
		meta: { auth: true, name: 'Report Rekap TKU', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapTKU
	},
	{
		path: '/rekap-golongan/kmd',
		name: prop + '.rakap.kmd',
		meta: { auth: true, name: 'Report Rekap KMD', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapKMD
	},
	{
		path: '/rekap-golongan/kml',
		name: prop + '.rakap.kml',
		meta: { auth: true, name: 'Report Rekap KML', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapKML
	},
	{
		path: '/rekap-golongan/kpd',
		name: prop + '.rakap.kpd',
		meta: { auth: true, name: 'Report Rekap KPD', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapKPD
	},
	{
		path: '/rekap-golongan/kpl',
		name: prop + '.rakap.kpl',
		meta: { auth: true, name: 'Report Rekap KPL', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapKPL
	},
	{
		path: '/potensi',
		name: prop + '.potensi',
		meta: { auth: true, name: 'Potensi Anggota', permission: 'Dewasa' },
		// meta: { auth: true, name: 'Potensi Anggota', permission: 'Dewasa', roles: whereRole(['Admin Korwil Pendataan', 'Admin Aplikasi']) },
		component: Potensi
	},
	{
		path: '/presensi',
		name: prop + '.presensi',
		meta: { auth: true, name: 'Daftar Presensi', permission: 'Dewasa', roles: whereRole(['Admin Gudep', 'Admin Aplikasi', 'Anggota Muda']) },
		component: PagePresensi
	},
	{
		path: '/data-anggota',
		name: prop + '.data-anggota',
		meta: { auth: true, name: 'Data Anggota', permission: 'Dewasa', roles: whereRole(kwartirRole) },
		component: DataAnggota
	},
	{
		path: '/data-potensi',
		name: prop + '.data-potensi',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: PagePotensi
	},
	{
		path: '/data-potensi/list-pengajuan-mutasi',
		name: prop + '.list-pengajuan-mutasi',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: PageListPengajuanMutasi
	},
	{
		path: '/data-potensi/pengajuan-mutasi/:id',
		name: prop + '.pengajuan-mutasi',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: PagePengajuanMutasi
	},
	{
		path: '/biodata/:id',
		name: prop + '.data-potensi-biodata',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: Biodata
	},
	{
		path: '/biodata-edit/:id',
		name: prop + '.data-potensi-biodata-edit',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: EditBiodata
	},
	{
		path: '/rekap-kepramukaan',
		name: prop + '.rekap-kepramukaan',
		meta: { auth: true, name: 'Rekap Kepramukaan', permission: 'Dewasa', roles: WhereNotRole('Anggota Muda') },
		component: RekapKepramukaan
	},
	{
		path: '/rekap-data-potensi',
		name: prop + '.rekap-data-potensi',
		meta: { auth: true, name: 'Rekap Data Potensi', permission: 'Dewasa', roles: whereRole(['Admin Korwil Pendataan', 'Admin Aplikasi']) },
		component: RekapDataPotensi
	},
	{
		path: '/pencapaian',
		name: prop + '.pencapaian',
		meta: { auth: true, name: 'Rekap Pencapaian', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: Pencapaian
	},
	{
		path: '/pramuka-garuda',
		name: prop + '.pramuka-garuda',
		meta: { auth: true, name: 'Pramuka Garuda', permission: 'Dewasa', roles: whereRole([...garudaRole]) },
		component: PramukaGaruda
	},
	{
		path: '/pramuka-garuda/:id',
		name: prop + '.pramuka-garuda-detail',
		meta: { auth: true, name: 'Detail Pramuka Garuda', permission: 'Dewasa', roles: whereRole([...garudaRole]) },
		component: DetailPramukaGaruda
	},
	{
		path: '/rekap-data',
		name: prop + '.rekap-data-formulir',
		meta: { auth: true, name: 'Rekap Data', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', 'Admin Pusdatin', 'Admin Formulir']) },
		component: PageRekapData
	},
	{
		path: '/formulir-module-akses',
		name: prop + '.formulir-module-akses',
		meta: { auth: true, name: 'Formulir Module Akses', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', 'Admin Pusdatin']) },
		component: PageRekapDataModuleAkses
	},

	{
		path: '/rekap-data/:id',
		name: prop + '.rekap-detail-data',
		meta: { auth: true, name: 'Rekap Detail Data', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', 'Admin Pusdatin', 'Admin Formulir']) },
		component: PageRekapDetailData
	},
	{
		path: '/rekap-data-update/:id',
		name: prop + '.rekap-update-data',
		meta: { auth: true, name: 'Rekap Update Data', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', 'Admin Pusdatin', 'Admin Formulir']) },
		component: PageEditRekapData
	},
	{
		path: '/rekap-kepemilikan-akun',
		name: prop + '.rekap-kepemilikan-akun',
		meta: { auth: true, name: 'Rekap Kepemilikan Akun', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', ...kwartirRole]) },
		component: PageRekapKepemilikan
	},
	{
		path: '/rekap-formulir-online',
		name: prop + '.rekap-formulir-online',
		meta: {
			auth: true,
			name: 'Rekap Formulir Online',
			permission: 'Dewasa',
			roles: whereRole(['Admin Aplikasi', 'Admin Korwil Pendataan', 'Admin Produksi'])
		},
		component: PageRekapFormulirKorwil
	},
	{
		path: '/formulir-pendataan-online',
		name: prop + '.formulir-pendataan-online',
		meta: {
			auth: true,
			name: ' Formulir Online',
			permission: 'Dewasa',
			roles: whereRole(['Admin Aplikasi', 'Admin Korwil Pendataan', 'Admin Produksi'])
		},
		component: PageFormulirKorwil
	},
	{
		path: '/biodata/:id/pencapaian',
		name: prop + '.data-potensi-biodata-pencapaian',
		meta: { auth: true, name: 'Data Potensi', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: PageBiodataPencapaian
	},
	{
		path: '/terapkan-data-anggota/:id',
		name: prop + '.terapkan-data-anggota',
		meta: { auth: true, name: 'Rekap Kepemilikan Akun', permission: 'Dewasa', roles: whereRole(['Admin Aplikasi', 'Admin Pusdatin']) },
		component: PageTerapkanDataAnggota
	},
	{
		path: '/kelola-leveling',
		name: prop + '.kelola-leveling',
		meta: { auth: true, name: 'Kelola Leveling', permission: 'Dewasa', roles: whereRole([...kwartirRole, 'Admin Aplikasi']) },
		component: PageKelolaLeveling
	}
]

export default LaporanRouter
