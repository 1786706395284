import { allRole, whereRole, WhereNotRole, kwartirRole } from '@/plugins/RolePermision/role.js'
// SOCIAL
const Social = () => import(/* webpackChunkName: "Social" */ '../views/Apps/ExploreV2/Social/PageSocial')
const FeedId = () => import(/* webpackChunkName: "FeedId" */ '../views/Apps/ExploreV2/Social/PageDetailSocial')
const Pertemanan = () => import(/* webpackChunkName: "Pertemanan" */ '../views/Apps/Pengguna/Pengguna')
const Mengikuti = () => import(/* webpackChunkName: "Mengikuti" */ '../views/Apps/Pengguna/ListMengikuti')
const Pengikut = () => import(/* webpackChunkName: "Pengikut" */ '../views/Apps/Pengguna/ListPengikut')
const Laporkan = () => import(/* webpackChunkName: "Laporkan" */ '../views/Apps/Pengguna/LaporkanPage')
const DilihatOleh = () => import(/* webpackChunkName: "dilihatoleh" */ '../views/Apps/Pengguna/LihatPengguna')
const Berita = () => import(/* webpackChunkName: "Berita" */ '../views/Apps/ExploreV2/Berita/PageBerita')
const BeritaId = () => import(/* webpackChunkName: "berita-id" */ '../views/Apps/ExploreV2/Berita/PageDetailBerita2')
const ListBerita = () => import(/* webpackChunkName: "list-beita" */ '../views/Apps/ExploreV2/Berita/PageListBerita')
const AddBerita = () => import(/* webpackChunkName: "list-beita" */ '../views/Apps/ExploreV2/Berita/PageAddBerita')
const ForumId = () => import(/* webpackChunkName: "Forum" */ '../views/Apps/ExploreV2/Forum/PageDetailForum')
const Forum = () => import(/* webpackChunkName: "ForumId" */ '../views/Apps/ExploreV2/Forum/PageForum')
const Polling = () => import(/* webpackChunkName: "Polling" */ '../views/Apps/ExploreV2/Polling/PagePolling')
const PollingId = () => import(/* webpackChunkName: "PollingId" */ '../views/Apps/Poling/DetailPoling')
const Kegiatan = () => import(/* webpackChunkName: "Kegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/PageListKegiatan')
const KegiatanId = () => import(/* webpackChunkName: "KegiatanId" */ '../views/Apps/ExploreV2/KegiatanV2/PageDetailKegiatan')
const AddKegiatan = () => import(/* webpackChunkName: "AddKegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/Create/PageCreateKegiatan.vue')
const EditKegiatan = () => import(/* webpackChunkName: "EditKegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/Update/PageUpdateKegiatan.vue')
const PesertaKegiatan = () => import(/* webpackChunkName: "PesertaKegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/PagePesertaKegiatan')
const PesertaDetail = () => import(/* webpackChunkName: "PesertaDetail" */ '../views/Apps/ExploreV2/KegiatanV2/PagePesertaDetail')
const IkutiKegiatan = () => import(/* webpackChunkName: "IkutiKegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/PageFormIkuti')
const ApproveKegiatan = () => import(/* webpackChunkName: "ApproveKegiatan" */ '../views/Apps/ExploreV2/KegiatanV2/PageApproveKegiatan')
const EditFormulirPeserta = () => import(/* webpackChunkName: "EditFormulirPeserta" */ '../views/Apps/ExploreV2/KegiatanV2/PageEditFormulirPeserta')

// new berita
const IndexBerita = () => import(/* webpackChunkName: "EditFormulirPeserta" */ '../views/Apps/ExploreV2/NewBerita/IndexPage.vue')
const NewsDetail = () => import(/* webpackChunkName: "EditFormulirPeserta" */ '../views/Apps/ExploreV2/NewBerita/NewsDetail.vue')
const NewsManagement = () => import(/* webpackChunkName: "EditFormulirPeserta" */ '../views/Apps/ExploreV2/NewBerita/NewsManagement.vue')
const NewsCreate = () => import(/* webpackChunkName: "EditFormulirPeserta" */ '../views/Apps/ExploreV2/NewBerita/NewsCreate.vue')

const PageIndexFAQ = () => import(/* webpackChunkName: "PageIndexFAQ" */ '../views/Apps/FAQ/Pages/PageIndex.vue')
const TransEntertaiment = () => import(/* webpackChunkName: "Social" */ '../views/Apps/ExploreV2/Social/PageTransEntertaiment')
const DetailVoucher = () => import(/* webpackChunkName: "Social" */ '../views/Apps/ExploreV2/Social/PageDetailVoucher')
const VoucherSaya = () => import(/* webpackChunkName: "Social" */ '../views/Apps/ExploreV2/Social/PageVoucherSaya')
const VoucherDetail = () => import(/* webpackChunkName: "Social" */ '../views/Apps/ExploreV2/Social/PageVoucherDetail')

var prop = 'social'

const SocialRouter = [
	{
		path: '/jelajah',
		name: 'ayo-pramuka-kwarnas',
		meta: { auth: false, name: 'Ayo Pramuka Kwarnas', roles: allRole },
		beforeEnter: (to, from, next) => {
			window.location.reload()
		}
	},
	{
		path: '/beranda',
		name: prop + '.list',
		meta: { auth: true, name: 'Kwarnas', roles: WhereNotRole(['Guest Mode']) },
		component: Social
	},
	{
		path: '/pertemanan',
		name: prop + '.pengguna',
		meta: { auth: true, name: 'Pengguna App', roles: allRole },
		component: Pertemanan
	},
	{
		path: '/mengikuti/:id',
		name: prop + '.mengikuti',
		meta: { auth: true, name: 'Mengikuti App', roles: WhereNotRole(['Guest Mode']) },
		component: Mengikuti
	},
	{
		path: '/pengikut/:id',
		name: prop + '.pengikut',
		meta: { auth: true, name: 'Pengikut App', roles: WhereNotRole(['Guest Mode']) },
		component: Pengikut
	},
	{
		path: '/laporkan/:id',
		name: prop + '.laporkan',
		meta: { auth: true, name: 'Laporkan Pengguna', roles: WhereNotRole(['Guest Mode']) },
		component: Laporkan
	},
	{
		path: '/dilihatoleh/:id',
		name: prop + '.dilihatpengguna',
		meta: { auth: true, name: 'Pengguna App', roles: WhereNotRole(['Guest Mode']) },
		component: DilihatOleh
	},
	{
		path: '/list-berita',
		name: prop + '.list-berita',
		meta: {
			auth: true,
			name: 'List Berita',
			permission: 'Pengurus',
			roles: WhereNotRole([])
			// roles: WhereNotRole(['Anggota Muda', 'Anggota Dewasa', 'Parent' + 'Guest Mode'])
		},
		component: NewsManagement
	},
	{
		path: '/add-berita',
		name: prop + '.add-berita',
		meta: {
			auth: true,
			name: 'Tambah Berita',
			permission: 'Pengurus',
			roles: WhereNotRole([])
			// roles: WhereNotRole(['Anggota Muda', 'Anggota Dewasa', 'Parent' + 'Guest Mode'])
		},
		component: NewsCreate
	},
	{
		path: '/berita',
		name: prop + '.berita',
		meta: { auth: true, name: 'Berita App', roles: allRole },
		component: IndexBerita
	},
	{
		path: '/berita/:id',
		name: 'berita-detail',
		meta: { auth: true, name: 'Detail Berita', roles: allRole },
		component: NewsDetail
	},
	{
		path: '/berita/approve/:id',
		name: 'berita-approve',
		meta: { auth: true, name: 'Persetujuan Berita', roles: allRole },
		component: NewsDetail
	},
	{
		path: '/forum/:id',
		name: 'forum-detail',
		meta: { auth: true, name: 'Detail Forum', roles: allRole },
		component: ForumId
	},
	{
		path: '/forum',
		name: prop + '.forum',
		meta: { auth: true, name: 'Forum App', roles: allRole },
		component: Forum
	},
	{
		path: '/kegiatan/:id',
		name: 'kegiatan-detail',
		meta: { auth: true, name: 'Detail Kegiatan', roles: allRole },
		component: KegiatanId
	},
	{
		path: '/kegiatan/list-peserta/:id',
		name: 'list-peserta-kegiatan',
		meta: { auth: true, name: 'List Peserta Kegiatan', roles: allRole },
		component: PesertaKegiatan
	},
	{
		path: '/kegiatan/peserta/:idPeserta/:idKegiatan',
		name: 'detail-peserta-kegiatan',
		meta: { auth: true, name: 'Peserta Detail', roles: allRole },
		component: PesertaDetail
	},
	{
		path: '/kegiatan/peserta/edit/:idPeserta/:idKegiatan',
		name: 'edit-formulir-peserta-kegiatan',
		meta: { auth: true, name: 'Edit Formulir Peserta', roles: allRole },
		component: EditFormulirPeserta
	},
	{
		path: '/kegiatan',
		name: prop + '.kegiatan',
		meta: { auth: true, name: 'Kegiatan App', roles: allRole },
		component: Kegiatan
	},
	{
		path: '/kegiatan/edit/:id',
		name: prop + '.editkegiatan',
		meta: { auth: true, name: 'Edit Kegiatan', roles: whereRole(['Admin Aplikasi', ...kwartirRole]) },
		component: EditKegiatan
	},
	{
		path: '/buatkegiatan',
		name: prop + '.buatkegiatan',
		meta: { auth: true, name: 'Buat Kegiatan', roles: whereRole(['Admin Aplikasi', ...kwartirRole]) },
		component: AddKegiatan
	},
	{
		path: '/kegiatan/ikuti/:id',
		name: prop + '.ikutikegiatan',
		meta: { auth: true, name: 'Ikuti Kegiatan', roles: WhereNotRole(['Guest Mode']) },
		component: IkutiKegiatan
	},
	{
		path: '/kegiatan/konfirmasi/:id',
		name: prop + '.konfirmasikegiatan',
		meta: { auth: true, name: 'Konfirmasi Kegiatan', roles: WhereNotRole(['Guest Mode']) },
		component: ApproveKegiatan
	},
	{
		path: '/polling/:id',
		name: 'polling-detail',
		meta: { auth: true, name: 'Detail Polling', roles: allRole },
		component: PollingId
	},
	{
		path: '/polling',
		name: prop + '.poling',
		meta: { auth: true, name: 'Polling App', roles: allRole },
		component: Polling
	},
	{
		path: '/feed/:id',
		name: 'feed-detail',
		meta: { auth: true, name: 'Detail Feed', roles: allRole },
		component: FeedId
	},
	{
		path: '/pusat-bantuan',
		name: prop + '.pusat-bantuan',
		meta: { auth: true, name: 'Pusat Bantuan', roles: allRole },
		component: PageIndexFAQ
	},
	{
		path: '/transmedia',
		name: prop + '.transmedia',
		meta: { auth: true, name: 'Trans Entertaiment', roles: allRole },
		component: TransEntertaiment
	},
	{
		path: '/transmedia/voucher-saya',
		name: prop + '.voucher-saya',
		meta: { auth: true, name: 'Voucher Saya', roles: allRole },
		component: VoucherSaya
	},
	{
		path: '/transmedia/detail/:id',
		name: prop + '.detail-voucher',
		meta: { auth: true, name: 'Detail Voucher', roles: allRole },
		component: DetailVoucher
	},
	{
		path: '/transmedia/voucher-saya/:id',
		name: prop + '.voucher-detail',
		meta: { auth: true, name: 'Voucher Detail', roles: allRole },
		component: VoucherDetail
	}
]

export default SocialRouter
