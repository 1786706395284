import { allRole, whereRole, WhereNotRole, atasRole } from '@/plugins/RolePermision/role.js'

const PageListPengajuan = () => import(/* webpackChunkName: "PageListPengajuan" */ '../views/Apps/Atas/Page/PageListPengajuan.vue')
const PageDetailPengajuan = () => import(/* webpackChunkName: "PageDetailPengajuan" */ '../views/Apps/Atas/Page/PageDetailPengajuan.vue')
const PageFormPengajuan = () => import(/* webpackChunkName: "PageFormPengajuan" */ '../views/Apps/Atas/Page/PageFormPengajuan.vue')
const PageTataCara = () => import(/* webpackChunkName: "PageTataCara" */ '../views/Apps/Atas/Page/PageTataCara.vue')
const PageRekapAtas = () => import(/* webpackChunkName: "PageRekapAtas" */ '../views/Apps/Atas/Page/PageRekapAtas.vue')
const PageKTAAtas = () => import(/* webpackChunkName: "PageKTAAtas" */ '../views/Apps/Atas/Page/PageKTAAtas.vue')

var prop = 'atas'

const GameRouter = [
	{
		path: '/atas',
		name: prop + '.list-pengajuan',
		meta: { auth: true, name: 'List Pengajuan ATAS', roles: atasRole },
		component: PageListPengajuan
	},
	{
		path: '/pengajuan-atas',
		name: prop + '.pengajuan-anggota',
		meta: { auth: true, name: 'Pengajuan ATAS', roles: allRole },
		component: PageFormPengajuan
	},
	{
		path: '/tatacara-pengajuan-atas',
		name: prop + '.tatacara-pengajuan',
		meta: { auth: true, name: 'Tata Cara Pengajuan ATAS', roles: allRole },
		component: PageTataCara
	},
	{
		path: '/atas/:id',
		name: prop + '.detail-pengajuan',
		meta: { auth: true, name: 'Detail Pengajuan ATAS', roles: allRole },
		component: PageDetailPengajuan
	},
	{
		path: '/rekap-atas',
		name: prop + '.rekap',
		meta: { auth: true, name: 'Rekap ATAS', roles: atasRole },
		component: PageRekapAtas
	}
	// {
	// 	path: '/kta-atas',
	// 	name: prop + '.kta',
	// 	meta: { auth: true, name: 'KTA ATAS', roles: whereRole(['Admin Aplikasi']) },
	// 	component: PageKTAAtas
	// }
]

export default GameRouter
