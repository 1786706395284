import getters from './getters'
import actions from './action'
import mutations from './mutation'

const state = {
  listMusda: []
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
