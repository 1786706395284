import axios from "axios"
import { auth } from "../../firebase"
export default {
  PenggunaDisarankanCommit (state, data) {
    if (data == null) {
      state.PenggunaDisarankan = []
    }else{
      state.PenggunaDisarankan.push(...data)
    }
  },
  BeritaCommit (state, data) {
    state.Berita = data
  },
  tweetCommit (state, data) {
    
      state.Tweet.push(...data)
  },
  mytweetCommit (state, data) {
    state.myTweet.push(...data)
  },
  likeCommit(state, data) {
    state.Tweet.forEach(element => {
      if (element.key == data.feedKey) {
        if(element.likeList.includes(auth?.currentUser?.uid))
        {
          element.likeList.pop(auth?.currentUser?.uid)
          element.likeCount--
        }else{
          element.likeList.push(auth?.currentUser?.uid)
          element.likeCount++
        }
      }
    });
  },
  resetTweetCommit (state,data) {
    state.Tweet = []
  },
  deleteTweetCommit (state,data) {
    state.Tweet = state.Tweet.filter( item => item.key !== data)
    .map((item) => {
        return item
    })
  },
  muteTweetCommit (state,data) {
    // state.Tweet = state.Tweet.filter( item => item.user.userId !== data)
    state.Tweet = state.Tweet.filter( item => item.key !== data)
    .map((item) => {
        return item
    })
  },
  reloadPostTweetCommit (state,data) {
        state.Tweet = state.Tweet
        .map((item) => {
          if (item.key==data.key) {
            return item = data
          }else{
            return item
          }
        })
  },
  followCommit (state,data) {
    if (data == null) {
      state.PenggunaDisarankan = []
    }else{
      state.PenggunaDisarankan = state.PenggunaDisarankan.filter( item => item.userId !== data)
      .map((item) => {
          return item
      })
    }
  },
  loadingCommit (state,data) {
    state.loading = data
  }
}
