const state = {
  tweets: [],
  tweetDetail: {}
}

const mutations = {
  setClearTweets (state) {
    state.tweets = []
  },
  setTweetDetail (state, value) {
    state.tweetDetail = value
  },
  setTweets (state, value) {
    if (value === null) {
      state.tweets = []
    } else {
      state.tweets.push(...value)
    }
  },
  setNewTweet (state, newTweet) {
    state.tweets.unshift(newTweet)
  },
  setEditTweet (state, newTweet) {
    state.tweets.forEach((item) => {
      if (item.key === newTweet.key) {
        item.description = newTweet.description
        item.imagePath = newTweet.imagePath
      }
    })
  },
  setReloadTweet (state, newTweet) {
    state.tweets.forEach((item) => {
      if (item.key === newTweet.key) {
        Object.keys(item).forEach(key => {
          item[key] = newTweet[key]
        })
      }
    })
  },
  deleteAndInterestTweet (state, key) {
    state.tweets = state.tweets.filter(item => {
      return item.key !== key
    })
  },
  deleteAndInterestComment (state, data) {
    state.tweets.forEach(item => {
      if (item.key === data.keyTweet) {
        item.commentCount = item.commentCount - 1
        item.comments = item.comments.filter(it => {
          return it.key !== data.keyComment
        })
      }
    })
  },
  muteBlockAndFollowTweet (state, user) {
    state.tweets = state.tweets.filter(item => {
      return item.user.userId !== user.userId
    })

    state.tweets.forEach(item => {
      item.comments = item.comments.filter(it => {
        return it.user.userId !== user.userId
      })
    })
  },
  muteBlockAndFollowComment (state, keyTweet, user) {
    state.tweets.forEach(item => {
      if (item.key === keyTweet.key) {
        item.comments = item.comments.filter(it => {
          return it.user.userId !== user.userId
        })
      }
    })
  },
  setLikeDislikeTweet (state, data) {
    // data = isLike, userId and key
    state.tweets = state.tweets.map((item) => {
      if (item.key === data.key) {
        item.likeList = item.likeList ? item.likeList : []
        if (data.isLike) {
          item.likeList.push(data.userId)
          item.likeCount = item.likeCount + 1
        } else {
          item.likeList = item.likeList.filter(item => item !== data.userId)
          item.likeCount = item.likeCount - 1
        }
        return item
      }
      return item
    })
  },
  setCommentTweet (state, data) {
    state.tweets.forEach((item) => {
      if (item.key === data.key) {
        item.commentCount++
        item.comments.splice(0, 0, data.newComment)
      }
    })
  },
  setCommentInComment (state, data) {
    state.tweets.forEach((item) => {
      if (item.key === data.keyParent) {
        item.comments.forEach(cmn => {
          if (cmn.key === data.key) {
            cmn.comments.splice(0, 0, data.newComment)
          }
        })
      }
    })
  },
  setRepostTweet (state, key) {
    state.tweets.forEach(item => {
      if (key === item.key) {
        item.retweetCount = item.retweetCount + 1
      }
    })
  },
  setRepostComment (state, data) {
    // data = key, keyParent
    state.tweets.forEach(item => {
      if (item.key === data.keyParent) {
        item.comments.forEach(cmn => {
          if (cmn.key === data.key) cmn.retweetCount = cmn.retweetCount + 1
        })
      }
    })
  },
  setShareTweet (state, data) {
    state.tweets.forEach(item => {
      if (data === item.key) {
        item.shareLinkCount = item.shareLinkCount + 1
      }
    })
  },
  setIkutiBatalIkutiKegiatan (state, data) {
    state.tweets = state.tweets.map((item) => {
      if (item.key === data.key) {
        item.attendList = item.attendList ? item.attendList : []
        if (data.isAttend) {
          item.attendList.push(data.userId)
        } else {
          item.attendList = item.attendList.filter(item => item !== data.userId)
        }
        return item
      }
      return item
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
