export default {
  userCommit (state, data) {
    state.user = data
  },
  errorCommit (state, data) {
    state.error = data
  },
  loginCommit (state, data) {
    state.login = data
  },
  notifCountCommit (state, data) {
    state.notifCount = data
  },
  notifListCommit (state, data) {
    state.Notification = data
  },
  notifListPushCommit (state, data) {
    state.Notification.push(...data)
  },
  filterCommit (state, data) {
    state.filter = data
    console.log(data)
  }
}
