export default {
	openModal({ commit, state }, payload) {
		$('#modal-confirm-component').modal('show')
		commit('setModalData', payload)
	},
	closeModal({ commit, state }, payload) {
		$('#modal-confirm-component').modal('hide')
		commit('setModalData', {
			message: '',
			title: '',
			type: 'success',
			withTitle: true,
			loading: false,
			confirmButtonText: 'Konfirmasi',
			deniedButtonText: 'Batal',
			customContent: null,
			customLoading: null
		})
	},
	openModalInfo({ commit, state }, payload) {
		commit('setModalInfo', payload)
		setTimeout(() => {
			$('#modal-info').modal('show')
		}, 100)
	},
	handleLoading({ commit, state }, payload) {
		commit('setLoading', payload)
	},
	async saveFunction({ commit }, func) {
		await commit('setFunction', func)
	},
	async executeFunction({ commit, state }, payload) {
		if (typeof state.myFunction === 'function') {
			state.myFunction(payload)
		} else {
			console.error('No valid function stored in state.')
		}
	},
	async saveDeniedFunction({ commit }, func) {
		await commit('setDeniedFunction', func)
	},
	async executeDeniedFunction({ commit, state }, payload) {
		if (typeof state.deniedFunction === 'function') {
			state.deniedFunction(payload)
			await commit('setDeniedFunction', null)
		} else {
			$('#modal-confirm-component').modal('hide')
			commit('setModalData', {
				message: '',
				title: '',
				type: 'success',
				withTitle: true,
				loading: false,
				confirmButtonText: 'Konfirmasi',
				deniedButtonText: 'Batal',
				customContent: null,
				customLoading: null
			})
		}
	}
}
