import {allRole,whereRole,WhereNotRole} from '@/plugins/RolePermision/role.js'

// MINI GAME
const Game = () => import(/* webpackChunkName: "game" */ '../views/Apps/Games/Game.vue')
const GameLevelId = () => import(/* webpackChunkName: "game-level" */ '../views/Apps/Games/Components/DetailLevel.vue')
const GameDetail = () => import(/* webpackChunkName: "game-detail" */ '../views/Apps/Games/Components/DetailSoal.vue')
const BuatGame = () => import(/* webpackChunkName: "buat-game" */ '../views/Apps/Games/Components/BuatGame.vue')

var prop = 'social'

const GameRouter = [
  {
    path: '/game',
    name: prop + '.game',
    meta: { auth: true, name: 'List Game' , roles : whereRole('Admin Aplikasi') },
    component: Game
  },
  {
    path: '/game/level/:id',
    name: prop + '.detailLevel',
    meta: { auth: true, name: 'Detail Level', roles : whereRole('Admin Aplikasi') },
    component: GameLevelId
  },
  {
    path: '/game/detailsoal/:id',
    name: prop + '.game.soal',
    meta: { auth: true, name: 'detail soal Game', roles : whereRole('Admin Aplikasi') },
    component: GameDetail
  },
  {
    path: '/game/buatgame',
    name: prop + '.buatgame',
    meta: { auth: true, name: 'Buat Game', roles : whereRole('Admin Aplikasi')  },
    component: BuatGame
  },
]

export default GameRouter
