import axios from "axios"
// import Swal from "sweetalert2"
export default {
  async BeritaAction (context, payload) {
    console.log(payload)
    try {
      let response = await axios.get('api/article', {params : payload})
      console.log(response.data)
      if (response.status === 200) {
        let check = context.state.berita.includes(response.data.data[0])
        if (!check) {
          context.commit('beritaCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async BeritaActionKwartir (context, payload) {
    try {
      const response = await axios.get('api/v2/article', { params: payload })
      const data = response.data.data
      if (data && data.length === 0) context.state.stop = true
      if (response.status === 200) {
        const check = context.state.berita.includes(data[0])
        if (!check) {
          context.commit('beritaCommit', data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async BukuAction (context, payload) {
    try {
      let response = await axios.get('api/page', {params : payload})
      
      if (response.status === 200) {
        const data = response.data.data
        if (data && data.length === 0) context.state.stop = true
        let check = context.state.buku.includes(response.data.data[0])
        if (!check) {
          context.commit('bukuCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async PanduanAction (context, payload) {
    try {
      let response = await axios.get('api/page', {params : payload})
      
      if (response.status === 200) {
        const data = response.data.data
        if (data && data.length === 0) context.state.stop = true
        let check = context.state.panduan.includes(response.data.data[0])
        if (!check) {
          context.commit('panduanCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async LearningAction (context, payload) {
    try {
      let response = await axios.get('api/video', {params : payload})
      console.log(response)
      if (response.status === 200) {
        const data = response.data.data
        if (data && data.length === 0) context.state.stop = true
        let check = context.state.learning.includes(response.data.data[0])
        if (!check) {
          context.commit('learningCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
//   async reloadAction(context, payload){
//     try {
//         let response = await axios.get("api/feed?key="+payload.key)
//         if (response.status == 200) {
//           console.log(response.data.data)
//         context.commit("reloadTweetCommit",response.data.data[0])
//         context.commit("detailTweetCommit",null)
//         context.commit("commentCommit",null)
//         this.upload = false
//         }
//      } catch (error) {
//         console.log(error)
//      }
//  },
}
