<template>
  <div class="dropdown">        
    <div class="button" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      <select disabled class="w-100" v-model="valueContent" style="background-color: white;border:0px;">
        <option v-for="(data,index) in options" :key="index" :value="data.id" :selected="data.id==model">{{data.name}}</option>
      </select>
    </div>
    <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuLink">
      <ul class="list-group dropdown-menu w-100 p-1" style="max-height:500px;overflow:scroll">
        <input class="w-100 form-control" v-model="cari" placeholder="Cari..."/>
        <li class="p-0 list-data-selected" v-for="(data,index) in optionsFilter" :key="index" v-on:click="clickSelected(data)"><a :class="data.id==model?'dropdown-item active-selected':'dropdown-item'" href="javascript:void(0);">{{data.name }}</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'selection',
  emits: ["eventSelected"],
  props: {
    options: { type: Array ,default:[]},
    model: { type: String ,default:''},
  },
  data () {
    return {
      cari: '',
      showCari:false
    }
  },
  computed: {
    valueContent: {
      get () {
        return this.model
      },
      set (model) {
        this.$emit('input', model)
      }
    },
    optionsFilter () {
      return this.options.filter(item => {
        return item.name.toLowerCase().indexOf(this.cari.toLowerCase()) > -1
      })
    },
    valueContent: {
      get () {
        return this.model
      },
      set (model) {
        this.$emit('input', model)
      }
    }
  },
  mounted () {

  },
  methods: {
    clickSelected(data){
      this.cari = ''
      this.$emit('eventSelected', data)
    }
  }
}
</script>

<style scoped>
.list-data-selected:hover{
  background-color: white;
}
.active-selected{
  background-color: white;
}
</style>
