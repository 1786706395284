export default {
	setTitle(state, payload) {
		state.title = payload
	},
	setIsAdmin(state, payload) {
		state.isAdmin = payload
	},
	setPelatihanDetail(state, payload) {
		state.pelatihanDetail = payload
	},
	setSesiDetail(state, payload) {
		state.sesiDetail = payload
	},
	setSesiForm(state, payload) {
		state.sesiForm = payload
	},
	setEvaluasiForm(state, payload) {
		state.evaluasiForm = payload
	},
	setTugasForm(state, payload) {
		state.tugasForm = payload
	},
	setUjianForm(state, payload) {
		state.ujianForm = payload
	},
	setSoal(state, payload) {
		state.soals.push(payload)
	},
	clearSoal(state, payload) {
		state.soals = []
	},
	deleteSoal(state, payload) {
		state.soals = state.soals.filter((res) => {
			return res.no !== payload
		})
	},
	editSoal(state, payload) {
		state.soals = state.soals.map((res) => {
			if (res.no === payload.no) {
				return payload
			} else {
				return res
			}
		})
	},
	setAsesmenForm(state, payload) {
		state.asesmenForm = payload
	},
	setLoadingDetailSesi(state, payload) {
		state.loadingDetailSesi = payload
	},
	setLampiran(state, payload) {
		state.lampiran = payload
	},
	pushLampiran(state, payload) {
		state.lampiran.push(payload)
	},
	removeLampiran(state, payload) {
		state.lampiran.splice(payload, 1)
	},
	clearAllForm(state, payload) {
		state.sesiForm = {
			pelatihan_id: '',
			nama: '',
			tanggal: '',
			jam_mulai: '',
			jam_selesai: '',
			jenis_sesi: 'offline',
			ruang: '',
			catatan: ''
		}
		state.evaluasiForm = {
			simpan_kreteria: false,
			kreteria: []
		}
		state.tugasForm = {}
		state.ujianForm = {}
		state.soals = []
		state.asesmenForm = []
		state.lampiran = []
	}
}
