import store from '@/store'

const allRole = [
	'Anggota Muda',
	'Anggota Dewasa',
	'Admin Gudep',
	'Admin Kwartir Ranting',
	'Admin Kwartir Cabang',
	'Admin Kwartir Daerah',
	'Admin Kwartir Nasional',
	'Admin Organisasi',
	'Admin Aplikasi',
	'Parent',
	'Guest Mode',
	'Konseptor Kwarnas',
	'Tata Usaha Kwarnas',
	'Sekretaris Sesjen Kwarnas',
	'Sesjen Kwarnas',
	'Seslak Kwarnas',
	'Ka Kwarnas',
	'Kabag Kwarnas',
	'Kabid Kwarnas',
	'Waka Kwarnas',
	'DKN Kwarnas',
	'Kapusinfo Kwarnas',
	'Ketua Unit Usaha Kwarnas',
	'Bendahara Kwarnas',
	'Admin Korwil Pendataan',
	'Kapusdiklat Kwarnas',
	'Kapuslitbang Kwarnas',
	'Sespusdiklat Kwarnas',
	'Sespuslitbang Kwarnas',
	'Sespusinfo Kwarnas',
	'Admin Regional Atas',
	'Admin Nasional Atas',
	'Sespus Kwarnas',
	'Kapus Kwarnas',
	'Sekretaris Desesjen Kwarnas',
	'Admin Pendataan Mandiri',
	'Ka Dewan Kehormatan Kwarnas',
	'Ka Dewan Kehormatan Kwarda',
	'Ka Dewan Kehormatan Kwarcab',
	'Ka Dewan Kehormatan Kwarran',
	'Ka Kwarda',
	'Ka Kwarcab',
	'Ka Kwarran',
	'Anggota Dewan Kerja',
	'Admin Formulir',
	'Sekretaris Kwarda',
	'Sekretaris Kwarcab',
	'Tata Usaha Kwarda',
	'Kepala Sekretariat Kwarda',
	'Konseptor Kwarda',
	'Sekretaris Bidang Kwarda',
	'Tata Usaha Kwarcab',
	'Sekretaris Kwarcab',
	'Kepala Sekretariat Kwarcab',
	'Konseptor Kwarcab',
	'Sekretaris Bidang Kwarcab',
	'Waka/Kapus Kwarda',
	'Waka/Kapus Kwarcab',
	'Bendahara Kwarda',
	'Bendahara Kwarcab',
	'DKD',
	'DKC',
	'Admin TPGP',
	'PIC TPGP',
	'Verifikator KBID',
	'Admin Produksi'
]

const kwartirRole = [
	'Admin Gudep',
	'Admin Kwartir Ranting',
	'Admin Kwartir Cabang',
	'Admin Kwartir Daerah',
	'Admin Kwartir Nasional',
	'Admin Korwil Pendataan'
]

const sismintirRole = [
	'Konseptor Kwarnas',
	'Tata Usaha Kwarnas',
	'Sekretaris Sesjen Kwarnas',
	'Sesjen Kwarnas',
	'Seslak Kwarnas',
	'Ka Kwarnas',
	'Kabag Kwarnas',
	'Kabid Kwarnas',
	'Waka Kwarnas',
	'DKN Kwarnas',
	'Kapusinfo Kwarnas',
	'Ketua Unit Usaha Kwarnas',
	'Admin Kwartir Cabang',
	'Admin Kwartir Daerah',
	'Ka Kwarda',
	'Ka Kwarcab',
	'Bendahara Kwarnas',
	'Kapusdiklat Kwarnas',
	'Kapuslitbang Kwarnas',
	'Sespusdiklat Kwarnas',
	'Sespuslitbang Kwarnas',
	'Sespusinfo Kwarnas',
	'Sespus Kwarnas',
	'Kapus Kwarnas',
	'Sekretaris Desesjen Kwarnas',
	'Sekretaris Kwarda',
	'Sekretaris Kwarcab',
	'Tata Usaha Kwarda',
	'Kepala Sekretariat Kwarda',
	'Konseptor Kwarda',
	'Sekretaris Bidang Kwarda',
	'Tata Usaha Kwarcab',
	'Sekretaris Kwarcab',
	'Kepala Sekretariat Kwarcab',
	'Konseptor Kwarcab',
	'Sekretaris Bidang Kwarcab',
	'Waka/Kapus Kwarda',
	'Waka/Kapus Kwarcab',
	'Bendahara Kwarda',
	'Bendahara Kwarcab',
	'DKD',
	'DKC'
	// 'Admin Aplikasi'
]

const penghargaanRole = [
	'Ka Dewan Kehormatan Kwarnas',
	'Ka Dewan Kehormatan Kwarda',
	'Ka Dewan Kehormatan Kwarcab',
	'Ka Dewan Kehormatan Kwarran',
	'Ka Kwarnas',
	'Ka Kwarda',
	'Ka Kwarcab',
	'Ka Kwarran'
]

const garudaRole = [
	'Ka Dewan Kehormatan Kwarcab',
	'Ka Dewan Kehormatan Kwarran',
	'Admin Kwartir Nasional',
	'Admin Kwartir Daerah',
	'Admin Kwartir Cabang',
	'Admin Kwartir Ranting',
	'Admin Gudep',
	'Ka Kwarcab',
	'Ka Kwarran',
	'Admin Aplikasi'
]

const EdiklatRole = ['Admin Kwartir Cabang', 'Admin Kwartir Daerah', 'Admin Kwartir Nasional', 'Admin Aplikasi', 'Anggota Dewan Kerja']

const penghargaanRoleAdminKwartir = [
	'Admin Kwartir Ranting',
	'Admin Kwartir Cabang',
	'Admin Kwartir Daerah',
	'Admin Kwartir Nasional',
	'Admin Aplikasi'
]

const atasRole = ['Admin Regional Atas', 'Admin Nasional Atas', 'Admin Aplikasi']

function whereRole(data) {
	const result = []
	if (Array.isArray(data)) {
		data.forEach((role) => {
			if (allRole.includes(role)) {
				result.push(role)
			} else {
				// console.log('tidak menemukan role ' + role)
			}
		})
	} else {
		result.push(data)
	}

	return result
}

function WhereNotRole(data) {
	const result = []
	if (Array.isArray(data)) {
		allRole.forEach((role) => {
			if (!data.includes(role)) {
				result.push(role)
			}
		})
	} else {
		allRole.forEach((role) => {
			if (role != data) {
				result.push(role)
			}
		})
	}

	return result
}
function isPermisionRole(data) {
	var bool = false
	if (store.state.Auth.user && data) {
		store.state.RolePermission.roles?.forEach((userRole) => {
			if (data.includes(userRole.name)) {
				bool = true
			}
		})
	}
	return bool
}

export {
	kwartirRole,
	sismintirRole,
	allRole,
	penghargaanRole,
	garudaRole,
	penghargaanRoleAdminKwartir,
	whereRole,
	WhereNotRole,
	isPermisionRole,
	EdiklatRole,
	atasRole
}
