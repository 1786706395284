const state = {
  roles: [],
  permissions: []
}
const mutations = {
  setRoles (state, payload) {
    state.roles = payload
  },
  setPermissions (state, payload) {
    state.permissions = payload
  },
  setClearRole (state) {
    state.roles = []
  },
  setClearPermission (state) {
    state.permissions = []
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
