import { whereRole, kwartirRole, allRole, sismintirRole, WhereNotRole } from '@/plugins/RolePermision/role.js'

const Sismintir = () => import(/* webpackChunkName: "Sismintir" */ '../views/Apps/Sismintir/PageIndex.vue')
const PageSuratMasuk = () => import(/* webpackChunkName: "PageSuratMasuk" */ '../views/Apps/Sismintir/PageSuratMasuk.vue')
const PageSuratKeluar = () => import(/* webpackChunkName: "PageSuratKeluar" */ '../views/Apps/Sismintir/PageSuratKeluar.vue')
const PageSuratKeluarKwartir = () => import(/* webpackChunkName: "PageSuratKeluarKwartir" */ '../views/Apps/Sismintir/PageSuratKeluarKwartir.vue')
const PageFormSuratKeluar = () => import(/* webpackChunkName: "PageFormSuratKeluar" */ '../views/Apps/Sismintir/PageFormSuratKeluar.vue')
const PageFormSuratKeluarKwartir = () =>
	import(/* webpackChunkName: "PageFormSuratKeluarKwartir" */ '../views/Apps/Sismintir/PageFormSuratKeluarKwartir.vue')
const PageFormSuratMasuk = () => import(/* webpackChunkName: "PageFormSuratMasuk" */ '../views/Apps/Sismintir/PageFormSuratMasuk.vue')
const PageDitujukanKepada = () => import(/* webpackChunkName: "PageDitujukanKepada" */ '../views/Apps/Sismintir/PageDitujukanKepada.vue')
const PageKeluarDitujukanKepada = () =>
	import(/* webpackChunkName: "PageKeluarDitujukanKepada" */ '../views/Apps/Sismintir/PageKeluarDitujukanKepada.vue')
// const PageDetailSurat = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/Sismintir/PageDetailSurat.vue')
const PageDetailSuratKeluar = () => import(/* webpackChunkName: "PageDetailSuratKeluar" */ '../views/Apps/Sismintir/PageDetailSuratKeluar.vue')
const PageDetailSuratKeluarKwartir = () =>
	import(/* webpackChunkName: "PageDetailSuratKeluarKwartir" */ '../views/Apps/Sismintir/PageDetailSuratKeluarKwartir.vue')
const PageDetailSuratMasuk = () => import(/* webpackChunkName: "PageDetailSuratMasuk" */ '../views/Apps/Sismintir/PageDetailSuratMasuk.vue')
const PageSuratDisposisi = () => import(/* webpackChunkName: "PageSuratDisposisi" */ '../views/Apps/Sismintir/PageSuratDisposisi.vue')

const PageLaporanJurnal = () => import(/* webpackChunkName: "PageLaporanJurnal" */ '../views/Apps/Sismintir/PageLaporanJurnal.vue')

const PageNota = () => import(/* webpackChunkName: "PageNota" */ '../views/Apps/Sismintir/PageNota.vue')
const PageFormNota = () => import(/* webpackChunkName: "PageFormNota" */ '../views/Apps/Sismintir/PageFormNota.vue')
const PageFormNotaPimpinan = () => import(/* webpackChunkName: "PageFormNotaPimpinan" */ '../views/Apps/Sismintir/PageFormNotaPimpinan.vue')
const PageDetailNota = () => import(/* webpackChunkName: "PageDetailNota" */ '../views/Apps/Sismintir/PageDetailNota.vue')
const PageDetailNotaDinas = () => import(/* webpackChunkName: "PageDetailNotaDinas" */ '../views/Apps/Sismintir/PageDetailNotaDinas.vue')
const PageFormNotaDinas = () => import(/* webpackChunkName: "PageFormNotaDinas" */ '../views/Apps/Sismintir/PageFormNotaDinas.vue')

var prop = 'e-surat'

const SismintirRouter = [
	{
		path: prop + '/',
		name: prop + '.beranda',
		meta: {
			auth: true,
			name: 'E-surat Beranda',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: Sismintir
	},
	{
		path: prop + '/detail-surat-keluar/:id',
		name: prop + '.detail-surat-keluar',
		meta: {
			auth: true,
			name: 'Detail Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailSuratKeluar
	},
	{
		path: prop + '/detail-surat-masuk-kwartir/:id',
		name: prop + '.detail-surat-masuk-kwartir',
		meta: {
			auth: true,
			name: 'Detail Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailSuratKeluarKwartir
	},
	{
		path: prop + '/detail-surat-keluar-kwartir/:id',
		name: prop + '.detail-surat-keluar-kwartir',
		meta: {
			auth: true,
			name: 'Detail Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailSuratKeluarKwartir
	},
	{
		path: prop + '/detail-nota/:id',
		name: prop + '.detail-nota',
		meta: {
			auth: true,
			name: 'Detail Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailNota
	},
	{
		path: prop + '/detail-nota-dinas/:id',
		name: prop + '.detail-nota-dinas',
		meta: {
			auth: true,
			name: 'Detail Nota Dinas',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailNotaDinas
	},
	{
		path: prop + '/detail-surat-masuk/:id',
		name: prop + '.detail-surat-masuk',
		meta: {
			auth: true,
			name: 'Detail Surat Masuk',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDetailSuratMasuk
	},
	{
		path: prop + '/surat-masuk',
		name: prop + '.surat-masuk',
		meta: {
			auth: true,
			name: 'E-surat Surat Masuk',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageSuratMasuk
	},
	{
		path: prop + '/surat-keluar',
		name: prop + '.surat-keluar',
		meta: {
			auth: true,
			name: 'E-surat Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageSuratKeluar
	},
	{
		path: prop + '/nota-pimpinan',
		name: prop + '.pimpinan',
		meta: {
			auth: true,
			name: 'E-surat Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageNota
	},
	{
		path: prop + '/nota',
		name: prop + '.nota',
		meta: {
			auth: true,
			name: 'E-surat Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageNota
	},
	{
		path: prop + '/surat',
		name: prop + '.surat-masuk-kwartir',
		meta: {
			auth: true,
			name: 'E-surat Surat Masuk',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageSuratKeluar
	},
	{
		path: prop + '/surat-keluar-kwartir',
		name: prop + '.surat-keluar-kwartir',
		meta: {
			auth: true,
			name: 'E-surat Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageSuratKeluarKwartir
	},
	{
		path: prop + '/tambah-surat-keluar-kwartir',
		name: prop + '.tambah-surat-keluar-kwartir',
		meta: {
			auth: true,
			name: 'Tambah Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratKeluarKwartir
	},
	{
		path: prop + '/tambah-surat-keluar',
		name: prop + '.tambah-surat-keluar',
		meta: {
			auth: true,
			name: 'Tambah Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratKeluar
	},
	{
		path: prop + '/tambah-nota',
		name: prop + '.tambah-nota',
		meta: {
			auth: true,
			name: 'Tambah Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNota
	},
	{
		path: prop + '/tambah-nota-dinas',
		name: prop + '.tambah-nota-dinas',
		meta: {
			auth: true,
			name: 'Tambah Nota Dinas',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNotaDinas
	},
	{
		path: prop + '/tambah-nota-pimpinan',
		name: prop + '.tambah-nota-pimpinan',
		meta: {
			auth: true,
			name: 'Tambah Nota Pimpinan',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNotaPimpinan
	},
	{
		path: prop + '/edit-nota/:id',
		name: prop + '.edit-nota',
		meta: {
			auth: true,
			name: 'Edit Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNota
	},
	{
		path: prop + '/edit-nota-dinas/:id',
		name: prop + '.edit-nota-dinas',
		meta: {
			auth: true,
			name: 'Edit Nota Dinas',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNotaDinas
	},
	{
		path: prop + '/edit-nota-pimpinan/:id',
		name: prop + '.edit-nota-pimpinan',
		meta: {
			auth: true,
			name: 'Edit Nota',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormNotaPimpinan
	},
	{
		path: prop + '/edit-surat-keluar/:id',
		name: prop + '.edit-surat-keluar',
		meta: {
			auth: true,
			name: 'Edit Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratKeluar
	},
	{
		path: prop + '/edit-surat-keluar-kwartir/:id',
		name: prop + '.edit-surat-keluar-kwartir',
		meta: {
			auth: true,
			name: 'Edit Surat Keluar',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratKeluarKwartir
	},
	{
		path: prop + '/tambah-surat-masuk',
		name: prop + '.tambah-surat-masuk',
		meta: {
			auth: true,
			name: 'Tambah Surat Masuk',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratMasuk
	},
	{
		path: prop + '/edit-surat-masuk/:id',
		name: prop + '.edit-surat-masuk',
		meta: {
			auth: true,
			name: 'Edit Surat Masuk',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageFormSuratMasuk
	},
	{
		path: prop + '/ditujukan-kepada/:id',
		name: prop + '.ditujukan-kepada',
		meta: {
			auth: true,
			name: 'Ditujukan Kepada',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageDitujukanKepada
	},
	{
		path: prop + '/surat-keluar/ditujukan-kepada/:id',
		name: prop + '.keluar-ditujukan-kepada',
		meta: {
			auth: true,
			name: 'Surat Keluar Ditujukan Kepada',
			permission: 'Dewasa',
			roles: ['Tata Usaha Kwarnas', 'Tata Usaha Kwarda', 'Tata Usaha Kwarcab']
		},
		component: PageKeluarDitujukanKepada
	},
	{
		path: prop + '/surat-disposisi',
		name: prop + '.surat-disposisi',
		meta: {
			auth: true,
			name: 'Surat Disposisi',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageSuratDisposisi
	},
	{
		path: prop + '/laporan-jurnal',
		name: prop + '.laporan-jurnal',
		meta: {
			auth: true,
			name: 'Laporan Jurnal',
			permission: 'Dewasa',
			roles: sismintirRole
		},
		component: PageLaporanJurnal
	}
]

export default SismintirRouter