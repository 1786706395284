import { whereRole, kwartirRole, penghargaanRole, penghargaanRoleAdminKwartir, allRole } from '@/plugins/RolePermision/role.js'

const PagePersetujuan = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/Penghargaan/Page/PagePersetujuan.vue')
const PagePengajuan = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/Penghargaan/Page/PagePengajuan.vue')
const PageDetailPersetujuan = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/Penghargaan/Page/PageDetailPersetujuan.vue')
const PageDetailPengajuan = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/Penghargaan/Page/PageDetailPengajuan.vue')
const PageFormPengajuanAdmin = () => import(/* webpackChunkName: "TambahAnggota" */ '../views/Apps/Penghargaan/Page/PageFormPengajuanAdmin.vue')
const PageTataCaraPengajuan = () => import(/* webpackChunkName: "TataCara" */ '../views/Apps/Penghargaan/Page/PageTataCaraPengajuan.vue')

// new page
const PenghargaanLayout = () => import(/* webpackChunkName: "Penghargaanlayout" */ '../views/Apps/PenghargaanNew/layout/index.vue')
const Pengajuan = () => import(/* webpackChunkName: "Pengajuan" */ '../views/Apps/PenghargaanNew/pages/Pengajuan.vue')
const PengajuanLink = () => import(/* webpackChunkName: "PengajuanLink" */ '../views/Apps/PenghargaanNew/pages/PengajuanLink.vue')
const Persetujuan = () => import(/* webpackChunkName: "Persetujuan" */ '../views/Apps/PenghargaanNew/pages/Persetujuan.vue')
const KelolaPelaksanaan = () => import(/* webpackChunkName: "KelolaPelaksanaan" */ '../views/Apps/PenghargaanNew/pages/KelolaPelaksanaan.vue')
const KelolaPICKwartir = () => import(/* webpackChunkName: "KelolaPICKwartir" */ '../views/Apps/PenghargaanNew/pages/KelolaPICKwartir.vue')
const KelolaDewanKehormatan = () => import(/* webpackChunkName: "KelolaDewanKehormatan" */ '../views/Apps/PenghargaanNew/pages/KelolaDewanKehormatan.vue')
const KelolaStatusKwartir = () => import(/* webpackChunkName: "KelolaStatusKwartir" */ '../views/Apps/PenghargaanNew/pages/KelolaStatusKwartir.vue')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '../views/Apps/PenghargaanNew/pages/Dashboard.vue')
const RiwayatPage = () => import(/* webpackChunkName: "RiwayatPage" */ '../views/Apps/PenghargaanNew/pages/RiwayatPage.vue')
const BeritaAcara = () => import(/* webpackChunkName: "BeritaAcara" */ '../views/Apps/PenghargaanNew/pages/BeritaAcara.vue')
const FormulirPage = () => import(/* webpackChunkName: "Dashboard" */ '../views/Apps/PenghargaanNew/formulir/FormulirPage.vue')

var prop = 'penghargaan'

const SelfRegisterRouter = [
	{
		path: '/' + prop + '/persetujuan',
		name: prop + '.persetujuan',
		meta: { auth: true, name: 'Persetujuan Penghargaan', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PagePersetujuan
	},
	{
		path: '/' + prop + '/persetujuan/:id',
		name: prop + '.persetujuan-detail',
		meta: { auth: true, name: 'Detail Persetujuan Penghargaan', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PageDetailPersetujuan
	},
	{
		path: '/' + prop + '/pengajuan',
		name: prop + '.pengajuan',
		meta: { auth: true, name: 'Pengajuan Penghargaan', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PagePengajuan
	},
	{
		path: '/' + prop + '/pengajuan/:id',
		name: prop + '.pengajuan-detail',
		meta: { auth: true, name: 'Detail Pengajuan Penghargaan', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PageDetailPengajuan
	},
	{
		path: '/' + prop + '/tata-cara',
		name: prop + '.tata-cara',
		meta: { auth: true, name: 'Tata Cara Pengajuan Penghargaan', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PageTataCaraPengajuan
	},
	{
		path: '/' + prop + '/pengajuan-anggota',
		name: prop + '.pengajuan-anggota',
		meta: { auth: true, name: 'Pengajuan Penghargaan Anggota', permission: 'Dewasa', roles: [...penghargaanRole, ...penghargaanRoleAdminKwartir] },
		component: PageFormPengajuanAdmin
	},

	// new route
	{
		path: '/penghargaan/dashboard',
		name: prop,
		meta: { auth: true, name: 'Penghargaan Layout', permission: 'Dewasa', roles: allRole },
		component: PenghargaanLayout,
		children: [
			{
				path: '',
				name: 'penghargaan.dashboard',
				meta: { auth: true, name: 'dashboard', permission: 'Dewasa', roles: allRole },
				component: Dashboard,
			},
			{
				path: '/penghargaan/pengajuan-list',
				name: 'penghargaan.pengajuan-list',
				meta: { auth: true, name: 'pengajuan-list', permission: 'Dewasa', roles: allRole },
				component: Pengajuan,
			},
			{
				path: '/penghargaan/pengajuan-list-link',
				name: 'penghargaan.pengajuan-list-link',
				meta: { auth: true, name: 'pengajuan-list-link', permission: 'Dewasa', roles: allRole },
				component: PengajuanLink,
			},
			{
				path: '/penghargaan/persetujuan-list',
				name: 'penghargaan.persetujuan-list',
				meta: { auth: true, name: 'persetujuan-list', permission: 'Dewasa', roles: allRole },
				component: Persetujuan,
			},
			{
				path: '/penghargaan/kelola-pelaksanaan-list',
				name: 'penghargaan.kelola-pelaksanaan-list',
				meta: { auth: true, name: 'kelola-pelaksanaan-list', permission: 'Dewasa', roles: allRole, grupId: 'penghargaan.kelola-data-list' },
				component: KelolaPelaksanaan,
			},
			{
				path: '/penghargaan/kelola-pic-list',
				name: 'penghargaan.kelola-pic-list',
				meta: { auth: true, name: 'kelola-pic-list', permission: 'Dewasa', roles: allRole, grupId: 'penghargaan.kelola-data-list' },
				component: KelolaPICKwartir,
			},
			{
				path: '/penghargaan/kelola-dewan-list',
				name: 'penghargaan.kelola-dewan-list',
				meta: { auth: true, name: 'kelola-dewan-list', permission: 'Dewasa', roles: allRole, grupId: 'penghargaan.kelola-data-list' },
				component: KelolaDewanKehormatan,
			},
			{
				path: '/penghargaan/kelola-status-list',
				name: 'penghargaan.kelola-status-list',
				meta: { auth: true, name: 'kelola-status-list', permission: 'Dewasa', roles: allRole, grupId: 'penghargaan.kelola-data-list' },
				component: KelolaStatusKwartir,
			},
		]
	},
	{
		path: '/penghargaan/formulir',
		name: 'penghargaan.formulir',
		meta: { auth: true, name: 'formulir', permission: 'Dewasa', roles: allRole },
		component: FormulirPage,
	},
	{
		path: '/penghargaan/riwayat',
		name: 'penghargaan.riwayat',
		meta: { auth: true, name: 'riwayat', permission: 'Dewasa', roles: allRole },
		component: RiwayatPage,
	},
	{
		path: '/penghargaan/berita-acara',
		name: 'penghargaan.berita-acara',
		meta: { auth: true, name: 'berita-acara', permission: 'Dewasa', roles: allRole },
		component: BeritaAcara,
	},
]

export default SelfRegisterRouter
