import { allRole, whereRole, WhereNotRole, kwartirRole, garudaRole, EdiklatRole } from '@/plugins/RolePermision/role.js'

const DiklatLayout = () => import(/* webpackChunkName: "DiklatLayout" */ '../views/Apps/Diklat-revamp/layout/index.vue')

const TambahTugas = () => import(/* webpackChunkName: "TambahTugas" */ '../views/Apps/Diklat-revamp/page/admin/TambahTugas.vue')
const MengumpulkanTugas = () => import(/* webpackChunkName: "MengumpulkanTugas" */ '../views/Apps/Diklat-revamp/page/admin/MengumpulkanTugas.vue')
const Evaluasi = () => import(/* webpackChunkName: "Evaluasi" */ '../views/Apps/Diklat-revamp/page/admin/Evaluasi.vue')
const Presensi = () => import(/* webpackChunkName: "Presensi" */ '../views/Apps/Diklat-revamp/page/admin/Presensi.vue')
const TambahSesi = () => import(/* webpackChunkName: "TambahSesi" */ '../views/Apps/Diklat-revamp/page/admin/TambahSesi.vue')
const TambahUjian = () => import(/* webpackChunkName: "TambahUjian" */ '../views/Apps/Diklat-revamp/page/admin/TambahUjian.vue')
const KelolaUjian = () => import(/* webpackChunkName: "KelolaUjian" */ '../views/Apps/Diklat-revamp/page/admin/KelolaUjian.vue')
const RekapUjian = () => import(/* webpackChunkName: "RekapUjian" */ '../views/Apps/Diklat-revamp/page/RekapUjian.vue')
const StatistikUjian = () => import(/* webpackChunkName: "StatistikUjian" */ '../views/Apps/Diklat-revamp/page/admin/StatistikUjian.vue')
const StatistikEvaluasi = () => import(/* webpackChunkName: "StatistikEvaluasi" */ '../views/Apps/Diklat-revamp/page/admin/StatistikEvaluasi.vue')
const RuangDiklat = () => import(/* webpackChunkName: "RuangDiklat" */ '../views/Apps/Diklat-revamp/page/RuangDiklat.vue')
const TambahAssesmen = () => import(/* webpackChunkName: "TambahAssesmen" */ '../views/Apps/Diklat-revamp/page/admin/TambahAssesmen.vue')

const TranskripPeserta = () => import(/* webpackChunkName: "TranskripPeserta" */ '../views/Apps/Diklat-revamp/page/admin/TranskripPeserta.vue')
const TranskripNilai = () => import(/* webpackChunkName: "TranskripNilai" */ '../views/Apps/Diklat-revamp/page/TranskripNilai.vue')
const TranskripNilaiPeserta = () => import(/* webpackChunkName: "TranskripNilaiPeserta" */ '../views/Apps/Diklat-revamp/page/TranskripNilaiPeserta.vue')

const VerifikasiPeserta = () => import(/* webpackChunkName: "VerifikasiPeserta" */ '../views/Apps/Diklat-revamp/page/admin/VerifikasiPeserta.vue')
const VerifikasiPesertaByKwartir = () => import(/* webpackChunkName: "VerifikasiPesertaByKwartir" */ '../views/Apps/Diklat-revamp/page/admin/VerifikasiPesertaByKwartir.vue')
const PendaftaranUlang = () => import(/* webpackChunkName: "PendaftaranUlang" */ '../views/Apps/Diklat-revamp/page/admin/PendaftaranUlang.vue')
const PendaftaranUlangByKwartir = () => import(/* webpackChunkName: "PendaftaranUlangByKwartir" */ '../views/Apps/Diklat-revamp/page/admin/PendaftaranUlangByKwartir.vue')
const PembagianKit = () => import(/* webpackChunkName: "PembagianKit" */ '../views/Apps/Diklat-revamp/page/admin/PembagianKit.vue')
const PembagianKitByKwartir = () => import(/* webpackChunkName: "PembagianKitByKwartir" */ '../views/Apps/Diklat-revamp/page/admin/PembagianKitByKwartir.vue')
const PembagianIjazah = () => import(/* webpackChunkName: "PembagianIjazah" */ '../views/Apps/Diklat-revamp/page/admin/PembagianIjazah.vue')
const PembagianIjazahByKwartir = () => import(/* webpackChunkName: "PembagianIjazahByKwartir" */ '../views/Apps/Diklat-revamp/page/admin/PembagianIjazahByKwartir.vue')
const RekapDataAnggota = () => import(/* webpackChunkName: "RekapDataAnggota" */ '../views/Apps/Diklat-revamp/page/admin/RekapDataAnggota.vue')
const RekapDataAnggotaDetail = () =>
	import(/* webpackChunkName: "RekapDataAnggotaDetail" */ '../views/Apps/Diklat-revamp/page/admin/RekapDataAnggotaDetail.vue')
const PesertaCreate = () =>
	import(/* webpackChunkName: "PesertaCreate" */ '../views/Apps/Diklat-revamp/page/admin/PesertaCreate.vue')
const DetailPeserta = () =>
	import(/* webpackChunkName: "DetailPeserta" */ '../views/Apps/Diklat-revamp/page/admin/DetailPeserta.vue')

const KelolaFormulir = () => import(/* webpackChunkName: "KelolaFormulir" */ '../views/Apps/Diklat-revamp/page/admin/KelolaFormulir.vue')
const FormulirCreate = () => import(/* webpackChunkName: "FormulirCreate" */ '../views/Apps/Diklat-revamp/page/admin/FormulirCreate.vue')
const KelolaPICKwartir = () => import(/* webpackChunkName: "KelolaPICKwartir" */ '../views/Apps/Diklat-revamp/page/admin/KelolaPICKwartir.vue')
const KelolaKit = () => import(/* webpackChunkName: "KelolaKit" */ '../views/Apps/Diklat-revamp/page/admin/KelolaKit.vue')
const KelolaPanitia = () => import(/* webpackChunkName: "KelolaPanitia" */ '../views/Apps/Diklat-revamp/page/admin/KelolaPanitia.vue')

const TranskripPesertaNilai = () =>
	import(/* webpackChunkName: "TranskripPesertaNilai" */ '../views/Apps/Diklat-revamp/page/peserta/TranskripPeserta.vue')
const UjianPeserta = () => import(/* webpackChunkName: "UjianPeserta" */ '../views/Apps/Diklat-revamp/page/peserta/UjianPeserta.vue')
const InputEvaluasi = () => import(/* webpackChunkName: "InputEvaluasi" */ '../views/Apps/Diklat-revamp/page/peserta/InputEvaluasi.vue')

const DiklatList = () => import(/* webpackChunkName: "DiklatList" */ '../views/Apps/Diklat-revamp/page/DiklatList.vue')
const PelatihanDetail = () => import(/* webpackChunkName: "PelatihanDetail" */ '../views/Apps/Diklat-revamp/page/PelatihanDetail.vue')
const KelolaPelatihan = () => import(/* webpackChunkName: "KelolaPelatihan" */ '../views/Apps/Diklat-revamp/page/admin/KelolaPelatihan.vue')
const PelatihanCreate = () => import(/* webpackChunkName: "PelatihanCreate" */ '../views/Apps/Diklat-revamp/page/admin/PelatihanCreate.vue')

var prop = 'diklat'

const DiklatRevampRouter = [
	{
		path: '/diklat/:id',
		name: prop,
		meta: { auth: true, name: 'diklat', permission: 'Dewasa', roles: allRole },
		component: DiklatLayout,
		children: [
			{
				path: '',
				name: prop + '.ruang-diklat',
				meta: { auth: true, name: 'diklat', permission: 'Dewasa', roles: allRole },
				component: RuangDiklat
			},
			{
				path: '/diklat/rekap-data-anggota',
				name: prop + '.rekap-data-anggota',
				meta: { auth: true, name: 'diklat/rekap-data-anggota', permission: 'Dewasa', roles: allRole },
				component: RekapDataAnggota
			},
			{
				path: '/diklat/rekap-data-anggota/:id',
				name: prop + '.rekap-data-anggota-detail',
				meta: { auth: true, name: 'diklat/rekap-data-anggota-detail', permission: 'Dewasa', roles: allRole },
				component: RekapDataAnggotaDetail
			},
			{
				path: '/diklat/tambah-tugas',
				name: prop + '.tambah-tugas',
				meta: { auth: true, name: 'DIKLAT Tambah Tugas', permission: 'Dewasa', roles: allRole },
				component: TambahTugas
			},
			{
				path: '/diklat/edit-tugas/:id',
				name: prop + '.edit-tugas',
				meta: { auth: true, name: 'DIKLAT Edit Tugas', permission: 'Dewasa', roles: allRole },
				component: TambahTugas
			},
			{
				path: '/diklat/tugas/:id',
				name: prop + '.mengumpulkan-tugas',
				meta: { auth: true, name: 'DIKLAT Mengumpulkan Tugas', permission: 'Dewasa', roles: allRole },
				component: MengumpulkanTugas
			},
			{
				path: '/diklat/evaluasi/:id',
				name: prop + '.evaluasi',
				meta: { auth: true, name: 'DIKLAT Evaluasi', permission: 'Dewasa', roles: allRole },
				component: Evaluasi
			},
			{
				path: '/diklat/statistik-evaluasi/:id',
				name: prop + '.statistik-evaluasi',
				meta: { auth: true, name: 'DIKLAT Statistik Evaluasi', permission: 'Dewasa', roles: allRole },
				component: StatistikEvaluasi
			},
			{
				path: '/diklat/presensi/:id',
				name: prop + '.presensi',
				meta: { auth: true, name: 'DIKLAT Presensi', permission: 'Dewasa', roles: allRole },
				component: Presensi
			},
			{
				path: '/diklat/edit-sesi',
				name: prop + '.edit-sesi',
				meta: { auth: true, name: 'DIKLAT Edit Sesi', permission: 'Dewasa', roles: allRole },
				component: TambahSesi
			},
			{
				path: '/diklat/tambah-sesi',
				name: prop + '.tambah-sesi',
				meta: { auth: true, name: 'DIKLAT TambahSesi', permission: 'Dewasa', roles: allRole },
				component: TambahSesi
			},
			{
				path: '/diklat/tambah-ujian',
				name: prop + '.tambah-ujian',
				meta: { auth: true, name: 'DIKLAT TambahUjian', permission: 'Dewasa', roles: allRole },
				component: TambahUjian
			},
			{
				path: '/diklat/edit-ujian/:id',
				name: prop + '.edit-ujian',
				meta: { auth: true, name: 'DIKLAT Edit Ujian', permission: 'Dewasa', roles: allRole },
				component: TambahUjian
			},
			{
				path: '/diklat/kelola-ujian/:id',
				name: prop + '.kelola-ujian',
				meta: { auth: true, name: 'DIKLAT KelolaUjian', permission: 'Dewasa', roles: allRole },
				component: KelolaUjian
			},
			{
				path: '/diklat/rekap-ujian/:id',
				name: prop + '.rekap-ujian',
				meta: { auth: true, name: 'DIKLAT RekapUjian', permission: 'Dewasa', roles: allRole },
				component: RekapUjian
			},
			{
				path: '/diklat/statistik-ujian/:id',
				name: prop + '.statistik-ujian',
				meta: { auth: true, name: 'DIKLAT StatistikUjian', permission: 'Dewasa', roles: allRole },
				component: StatistikUjian
			},
			{
				path: '/diklat/transkrip-peserta/:id',
				name: prop + '.transkrip-peserta',
				meta: { auth: true, name: 'transkrip', permission: 'Dewasa', roles: allRole },
				component: TranskripPeserta
			},
			{
				path: '/diklat/transkrip-nilai/:id',
				name: prop + '.transkrip-nilai',
				meta: { auth: true, name: 'transkrip', permission: 'Dewasa', roles: allRole },
				component: TranskripNilai
			},
			{
				path: '/diklat/transkrip-nilai-peserta/:id',
				name: prop + '.transkrip-nilai-peserta',
				meta: { auth: true, name: 'transkrip', permission: 'Dewasa', roles: allRole },
				component: TranskripNilaiPeserta
			},
			{
				path: '/diklat/detail-peserta/:id',
				name: prop + '.detail-peserta',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: DetailPeserta
			},
			{
				path: '/diklat/verifikasi-peserta/:id',
				name: prop + '.verifikasi-peserta',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: VerifikasiPeserta
			},
			{
				path: '/diklat/verifikasi-peserta-by-kwartir/:id',
				name: prop + '.verifikasi-peserta-by-kwartir',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: VerifikasiPesertaByKwartir
			},
			{
				path: '/diklat/pendaftaran-ulang/:id',
				name: prop + '.pendaftaran-ulang',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PendaftaranUlang
			},
			{
				path: '/diklat/pendaftaran-ulang-by-kwartir/:id',
				name: prop + '.pendaftaran-ulang-by-kwartir',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PendaftaranUlangByKwartir
			},
			{
				path: '/diklat/tambah-peserta/:id',
				name: prop + '.tambah-peserta',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PesertaCreate
			},
			{
				path: '/diklat/pembagian-kit/:id',
				name: prop + '.pembagian-kit',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PembagianKit
			},
			{
				path: '/diklat/pembagian-kit-by-kwartir/:id',
				name: prop + '.pembagian-kit-by-kwartir',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PembagianKitByKwartir
			},
			{
				path: '/diklat/pembagian-ijazah/:id',
				name: prop + '.pembagian-ijazah',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PembagianIjazah
			},
			{
				path: '/diklat/pembagian-ijazah-by-kwartir/:id',
				name: prop + '.pembagian-ijazah-by-kwartir',
				meta: { auth: true, name: 'pendaftaran', permission: 'Dewasa', roles: allRole },
				component: PembagianIjazahByKwartir
			},
			{
				path: '/diklat/kelola-formulir/:id',
				name: prop + '.kelola-formulir',
				meta: { auth: true, name: 'kelola', permission: 'Dewasa', roles: allRole },
				component: KelolaFormulir
			},
			{
				path: '/diklat/tambah-formulir/:id',
				name: prop + '.tambah-formulir',
				meta: { auth: true, name: 'tambah-formulir', permission: 'Dewasa', roles: allRole },
				component: FormulirCreate
			},
			{
				path: '/diklat/kelola-pic/:id',
				name: prop + '.kelola-pic',
				meta: { auth: true, name: 'kelola', permission: 'Dewasa', roles: allRole },
				component: KelolaPICKwartir
			},
			{
				path: '/diklat/kelola-kit/:id',
				name: prop + '.kelola-kit',
				meta: { auth: true, name: 'kelola', permission: 'Dewasa', roles: allRole },
				component: KelolaKit
			},
			{
				path: '/diklat/kelola-panitia/:id',
				name: prop + '.kelola-panitia',
				meta: { auth: true, name: 'kelola', permission: 'Dewasa', roles: allRole },
				component: KelolaPanitia
			},
			{
				path: '/diklat/ujian/:id',
				name: prop + '.ujian-peserta',
				meta: { auth: true, name: 'ujian-peserta', permission: 'Dewasa', roles: allRole },
				component: UjianPeserta
			},
			{
				path: '/diklat/transkrip-peserta-nilai',
				name: prop + '.transkrip-peserta-nilai',
				meta: { auth: true, name: 'diklat/transkrip-peserta-nilai', permission: 'Dewasa', roles: allRole },
				component: TranskripPesertaNilai
			},
			{
				path: '/diklat/input-evaluasi/:id',
				name: prop + '.input-evaluasi',
				meta: { auth: true, name: 'input-evaluasi', permission: 'Dewasa', roles: allRole },
				component: InputEvaluasi
			},
			{
				path: '/diklat/tambah-assesmen',
				name: prop + '.tambah-assesmen',
				meta: { auth: true, name: 'tambah-assesmen', permission: 'Dewasa', roles: allRole },
				component: TambahAssesmen
			},
			{
				path: '/diklat/input-assesmen/:id',
				name: prop + '.input-assesmen',
				meta: { auth: true, name: 'input-assesmen', permission: 'Dewasa', roles: allRole },
				component: InputEvaluasi
			},
			{
				path: '/diklat/asesmen/:id',
				name: prop + '.asesmen',
				meta: { auth: true, name: 'DIKLAT Asesmen', permission: 'Dewasa', roles: allRole },
				component: Evaluasi
			},
		]
	},
	{
		path: '/diklat-list',
		name: prop + '.diklat-list',
		meta: { auth: true, name: 'diklat-list', permission: 'Dewasa', roles: allRole },
		component: DiklatList
	},
	{
		path: '/diklat/pelatihan/:id',
		name: prop + '.diklat-kelola-pelatihan',
		meta: { auth: true, name: 'diklat-kelola-pelatihan', permission: 'Dewasa', roles: allRole },
		component: PelatihanDetail
	},
	{
		path: '/diklat/kelola-pelatihan',
		name: prop + '.diklat-pelatihan-detail',
		meta: { auth: true, name: 'diklat-pelatihan-detail', permission: 'Dewasa', roles: allRole },
		component: KelolaPelatihan
	},
	{
		path: '/diklat/tambah-pelatihan',
		name: prop + '.diklat-pelatihan-create',
		meta: { auth: true, name: 'diklat-pelatihan-create', permission: 'Dewasa', roles: allRole },
		component: PelatihanCreate
	}
	// {
	// 	path: '/diklat/pelatihan/:id',
	// 	name: prop + 'detail.pelatihan',
	// 	meta: { auth: true, name: 'DIKLAT Detail Pelatihan', permission: 'Dewasa', roles: allRole },
	// 	component: DetailPelatihan
	// },
	// {
	// 	path: '/diklat/pelatihan/buat',
	// 	name: prop + '.buat-pelatihan',
	// 	meta: { auth: true, name: 'DIKLAT Buat Pelatihan', permission: 'Dewasa', roles: EdiklatRole },
	// 	component: AddPelatihan
	// },
	// {
	// 	path: '/diklat/pelatihan/edit/:id',
	// 	name: prop + '.edit-pelatihan',
	// 	meta: { auth: true, name: 'DIKLAT Edit Pelatihan', permission: 'Dewasa', roles: EdiklatRole },
	// 	component: UpdatePelatihan
	// },
	// {
	// 	path: '/ujian',
	// 	name: prop + 'ujian',
	// 	meta: { auth: true, name: 'DIKLAT Ujian' },
	// 	component: UjianPeserta
	// }
]

export default DiklatRevampRouter
