import { allRole, whereRole, WhereNotRole } from '@/plugins/RolePermision/role.js'

// GIAT
const Agama = () => import(/* webpackChunkName: "Referensi-Agama" */ '../views/Apps/ReferensiMasterData/Page/PageAgama.vue')
const GolDarah = () => import(/* webpackChunkName: "Referensi-Golongan-Darah" */ '../views/Apps/ReferensiMasterData/Page/PageGolDarah.vue')
const Jabatan = () => import(/* webpackChunkName: "Referensi-Jabatan" */ '../views/Apps/ReferensiMasterData/Page/PageJabatan.vue')
const TKU = () => import(/* webpackChunkName: "Referensi-TKU" */ '../views/Apps/ReferensiMasterData/Page/PageTKU.vue')
const TKK = () => import(/* webpackChunkName: "Referensi-TKK" */ '../views/Apps/ReferensiMasterData/Page/PageTKK.vue')
const SKU = () => import(/* webpackChunkName: "Referensi-SKU" */ '../views/Apps/ReferensiMasterData/Page/PageSKU.vue')
const SKK = () => import(/* webpackChunkName: "Referensi-SKK" */ '../views/Apps/ReferensiMasterData/Page/PageSKK.vue')
const JenisKegiatan = () => import(/* webpackChunkName: "Referensi-Jenis-Kegiatan" */ '../views/Apps/ReferensiMasterData/Page/PageJenisKegiatan.vue')
const JenisPenghargaan = () =>
	import(/* webpackChunkName: "Referensi-Jenis-Penghargaan" */ '../views/Apps/ReferensiMasterData/Page/PageJenisPenghargaan.vue')
const Bidang = () => import(/* webpackChunkName: "Referensi-Bidang" */ '../views/Apps/ReferensiMasterData/Page/PageBidang.vue')
const FormulirPendataan = () =>
	import(/* webpackChunkName: "Referensi-Formulir-Pendataan" */ '../views/Apps/ReferensiMasterData/Page/PageFormulirPendataan.vue')
const PusatBantuan = () => import(/* webpackChunkName: "Referensi-Pusat-Bantuan" */ '../views/Apps/ReferensiMasterData/Page/PagePusatBantuan.vue')
const CoachMark = () => import(/* webpackChunkName: "Referensi-Pusat-Bantuan" */ '../views/Apps/ReferensiMasterData/Page/PageCoachMark.vue')
const Peraturan = () => import(/* webpackChunkName: "Referensi-Peraturan" */ '../views/Apps/ReferensiMasterData/Page/PagePeraturan.vue')
const PeraturanKategori = () =>
	import(/* webpackChunkName: "Referensi-PeraturanKategori" */ '../views/Apps/ReferensiMasterData/Page/PagePeraturanKategori.vue')
const IndexListRole = () => import(/* webpackChunkName: "IndexListRole" */ '../views/Apps/Referensi/ListRole/IndexListRole.vue')
const TambahListRole = () => import(/* webpackChunkName: "TambahListRole" */ '../views/Apps/Referensi/ListRole/TambahListRole.vue')
const TransEntertaiment = () =>
	import(/* webpackChunkName: "Referensi-Jenis-Penghargaan" */ '../views/Apps/ReferensiMasterData/Page/PageTransEntertaiment.vue')
const Sponsor = () => import(/* webpackChunkName: "Referensi-Jenis-Penghargaan" */ '../views/Apps/ReferensiMasterData/Page/PageSponsor.vue')
const LayoutLeveling = () => import(/* webpackChunkName: "LayoutLeveling" */ '../views/Apps/ReferensiMasterData/Page/PageLeveling/LayoutLeveling.vue')
const PagePoinLevel = () => import(/* webpackChunkName: "PagePoinLevel" */ '../views/Apps/ReferensiMasterData/Page/PageLeveling/PagePoinLevel.vue')
const PageAktivitasHarian = () =>
	import(/* webpackChunkName: "PageAktivitasHarian" */ '../views/Apps/ReferensiMasterData/Page/PageLeveling/PageAktivitasHarian.vue')
const PageJenisPencapaian = () =>
	import(/* webpackChunkName: "PageJenisPencapaian" */ '../views/Apps/ReferensiMasterData/Page/PageLeveling/PageJenisPencapaian.vue')
const KelolaPenghargaan = () =>
	import(/* webpackChunkName: "Referensi-Jenis-Penghargaan" */ '../views/Apps/ReferensiMasterData/Page/PageKelolaPenghargaan.vue')
const LayoutPanduan = () => import(/* webpackChunkName: "LayoutLeveling" */ '../views/Apps/ReferensiMasterData/Page/PagePanduan/LayoutPanduan.vue')
const PagePanduanKategori = () =>
	import(/* webpackChunkName: "LayoutLeveling" */ '../views/Apps/ReferensiMasterData/Page/PagePanduan/PagePanduanKategori.vue')
const PagePanduanList = () =>
	import(/* webpackChunkName: "LayoutLeveling" */ '../views/Apps/ReferensiMasterData/Page/PagePanduan/PagePanduanList.vue')

var prop = 'referensi'

const RefMasterRouter = [
	{
		path: prop + '/agama',
		name: prop + '.agama',
		meta: { auth: true, name: 'Referensi Agama', roles: whereRole('Admin Aplikasi') },
		component: Agama
	},
	{
		path: prop + '/golongan-darah',
		name: prop + '.golongan-darah',
		meta: { auth: true, name: 'Referensi Golongan Darah', roles: whereRole('Admin Aplikasi') },
		component: GolDarah
	},
	{
		path: prop + '/golongan',
		name: prop + '.golongan-jabatan',
		meta: { auth: true, name: 'Referensi Golongan / Jabatan', roles: whereRole('Admin Aplikasi') },
		component: Jabatan
	},
	{
		path: prop + '/tku',
		name: prop + '.tku',
		meta: { auth: true, name: 'Referensi TKU', roles: whereRole('Admin Aplikasi') },
		component: TKU
	},
	{
		path: prop + '/tkk',
		name: prop + '.tkk',
		meta: { auth: true, name: 'Referensi TKK', roles: whereRole('Admin Aplikasi') },
		component: TKK
	},
	{
		path: prop + '/sku',
		name: prop + '.sku',
		meta: { auth: true, name: 'Referensi SKU', roles: whereRole('Admin Aplikasi') },
		component: SKU
	},
	{
		path: prop + '/skk',
		name: prop + '.skk',
		meta: { auth: true, name: 'Referensi SKK', roles: whereRole('Admin Aplikasi') },
		component: SKK
	},
	{
		path: prop + '/jenis-kegiatan',
		name: prop + '.jenis-kegiatan',
		meta: { auth: true, name: 'Referensi Jenis Kegiatan', roles: whereRole('Admin Aplikasi') },
		component: JenisKegiatan
	},
	{
		path: prop + '/jenis-penghargaan',
		name: prop + '.jenis-penghargaan',
		meta: { auth: true, name: 'Referensi Jenis Penghargaan', roles: whereRole('Admin Aplikasi') },
		component: JenisPenghargaan
	},
	{
		path: prop + '/bidang',
		name: prop + '.bidang',
		meta: { auth: true, name: 'Referensi Bidang', roles: whereRole('Admin Aplikasi') },
		component: Bidang
	},
	{
		path: prop + '/formulir-pendataan',
		name: prop + '.formulir-pendataan',
		meta: { auth: true, name: 'Formulir Pendataan', roles: whereRole('Admin Aplikasi') },
		component: FormulirPendataan
	},
	{
		path: prop + '/pusat-bantuan',
		name: prop + '.pusat-bantuan',
		meta: { auth: true, name: 'Pusat Bantuan', roles: whereRole('Admin Aplikasi') },
		component: PusatBantuan
	},
	{
		path: prop + '/coachmark',
		name: prop + '.coachmark',
		meta: { auth: true, name: 'CoachMark', roles: whereRole('Admin Aplikasi') },
		component: CoachMark
	},
	{
		path: prop + '/peraturan',
		name: prop + '.peraturan',
		meta: { auth: true, name: 'Referensi Peraturan', roles: whereRole('Admin Aplikasi') },
		component: Peraturan
	},
	{
		path: prop + '/peraturan/kategori',
		name: prop + '.peraturan-kategori',
		meta: { auth: true, name: 'Referensi Peraturan Kategori', roles: whereRole('Admin Aplikasi') },
		component: PeraturanKategori
	},
	{
		path: prop + '/list-role',
		name: prop + '.list-role',
		meta: { auth: true, name: 'List Role', permission: 'Dewasa', roles: allRole },
		component: IndexListRole
	},
	{
		path: prop + '/tambah-list-role',
		name: prop + '.tambah-list-role',
		meta: { auth: true, name: 'Tambah List Role', permission: 'Dewasa', roles: allRole },
		component: TambahListRole
	},
	{
		path: prop + '/trans-entertaiment',
		name: prop + '.trans-entertaiment',
		meta: { auth: true, name: 'Referensi Trans Entertaiment', roles: whereRole('Admin Aplikasi') },
		component: TransEntertaiment
	},
	{
		path: prop + '/sponsor',
		name: prop + '.sponsor',
		meta: { auth: true, name: 'Referensi Sponsor', roles: whereRole('Admin Aplikasi') },
		component: Sponsor
	},
	{
		path: prop + '/leveling',
		name: prop + '.leveling',
		meta: { auth: true, name: 'Referensi Leveling', roles: whereRole('Admin Aplikasi') },
		component: LayoutLeveling,
		children: [
			{
				path: '',
				redirect: (to) => {
					return prop + `/leveling/poin-level`
				}
			},
			{
				path: 'poin-level',
				name: prop + '.leveling.poin-level',
				meta: { auth: true, name: 'Referensi Poin Level', roles: whereRole('Admin Aplikasi') },
				component: PagePoinLevel
			},
			{
				path: 'aktivitas-harian',
				name: prop + '.leveling.aktivitas-harian',
				meta: { auth: true, name: 'Referensi Aktivitas Harian', roles: whereRole('Admin Aplikasi') },
				component: PageAktivitasHarian
			},
			{
				path: 'jenis-pencapaian',
				name: prop + '.leveling.jenis-pencapaian',
				meta: { auth: true, name: 'Jenis Pencapaian', roles: whereRole('Admin Aplikasi') },
				component: PageJenisPencapaian
			}
		]
	},
	{
		path: prop + '/kelola-penghargaan',
		name: prop + '.kelola-penghargaan',
		meta: { auth: true, name: 'Referensi Penghargaan', roles: whereRole('Admin Aplikasi') },
		component: KelolaPenghargaan
	},
	{
		path: prop + '/panduan',
		name: prop + '.panduan',
		meta: { auth: true, name: 'Referensi Panduan', roles: whereRole('Admin Aplikasi') },
		component: LayoutPanduan,
		children: [
			{
				path: '',
				redirect: (to) => {
					return prop + `/panduan/list`
				}
			},
			{
				path: 'list',
				name: prop + '.panduan.list',
				meta: { auth: true, name: 'Referensi List Panduan', roles: whereRole('Admin Aplikasi') },
				component: PagePanduanList
			},
			{
				path: 'kategori',
				name: prop + '.panduan.kategori',
				meta: {
					auth: true,
					name: 'Referensi Panduan Kategori',
					roles: whereRole('Admin Aplikasi')
				},
				component: PagePanduanKategori
			}
		]
	}
]

export default RefMasterRouter
