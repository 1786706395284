import { allRole, whereRole, WhereNotRole } from '@/plugins/RolePermision/role.js'

// BUKU SAKU
const Buku = () => import(/* webpackChunkName: "buku" */ '../views/Apps/Buku/Buku')
const BukuId = () => import(/* webpackChunkName: "buku-id" */ '../views/Apps/ExploreV2/Buku/PageDetailBuku')
const Panduan = () => import(/* webpackChunkName: "panduan" */ '../views/Apps/Panduan/Panduan')
const PanduanId = () => import(/* webpackChunkName: "panduan-id" */ '../views/Apps/ExploreV2/Panduan/PageDetailPanduan')
const Learning = () => import(/* webpackChunkName: "learning" */ '../views/Apps/E-learning/E-learning')
const LearningId = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/Learning/PageDetailLearning')

// new design
const EBook = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-book/index.vue')
const EPanduan = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-panduan/index.vue')
const ELearning = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-learning/index.vue')
const EBuletin = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-buletin/index.vue')

const EBuletinDetail = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-buletin/EbuletinDetail.vue')
const EBookDetail = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-book/EbookDetail.vue')
const EPanduanDetail = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-panduan/EpanduanDetail.vue')
const ELearningDetail = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/E-learning/ElearningDetail.vue')

const CreateContent = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/Component/CreateContent.vue')
const MasterContent = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/Component/MasterContent.vue')
const Dokumen = () => import(/* webpackChunkName: "learning-id" */ '../views/Apps/ExploreV2/NewBukuSaku/Dokumen/index.vue')

var prop = 'social'

const BukuSakuRouter = [
	{
		path: '/buku',
		name: prop + '.buku',
		meta: { auth: true, name: 'Buku App', roles: allRole },
		component: EBook
	},
	{
		path: '/buku/list',
		name: 'buku-list',
		meta: { auth: true, name: 'Buku List', roles: allRole },
		component: MasterContent
	},
	{
		path: '/buku/:id',
		name: 'buku-detail',
		meta: { auth: true, name: 'Detail Buku', roles: allRole },
		component: EBookDetail
	},
	{
		path: '/panduan',
		name: prop + '.panduan',
		meta: { auth: true, name: 'Panduan App', roles: allRole },
		component: EPanduan
	},
	{
		path: '/panduan/list',
		name: 'panduan-list',
		meta: { auth: true, name: 'Panduan List', roles: allRole },
		component: MasterContent
	},
	{
		path: '/panduan/:id',
		name: 'panduan-detail',
		meta: { auth: true, name: 'Detail Panduan', roles: allRole },
		component: EPanduanDetail
	},
	{
		path: '/e-learning/:id',
		name: 'learning-detail',
		meta: { auth: true, name: 'Detail E-learning', roles: allRole },
		component: ELearningDetail
	},
	{
		path: '/e-learning',
		name: prop + '.learning',
		meta: { auth: true, name: 'E-learning App', roles: allRole },
		component: ELearning
	},
	{
		path: '/e-learning/list',
		name: 'learning-list',
		meta: { auth: true, name: 'E-learning List', roles: allRole },
		component: MasterContent
	},
	{
		path: '/e-buletin/:id',
		name: 'buletin-detail',
		meta: { auth: true, name: 'Detail E-buletin', roles: allRole },
		component: EBuletinDetail
	},
	{
		path: '/e-buletin',
		name: prop + '.buletin',
		meta: { auth: true, name: 'E-buletin App', roles: allRole },
		component: EBuletin
	},
	{
		path: '/e-buletin/list',
		name: 'buletin-list',
		meta: { auth: true, name: 'E-buletin List', roles: allRole },
		component: MasterContent
	},
	{
		path: '/buku/create',
		name: 'buku-create',
		meta: { auth: true, name: 'Buku Digital Create', roles: allRole },
		component: CreateContent
	},
	{
		path: '/e-buletin/create',
		name: 'buletin-create',
		meta: { auth: true, name: 'E-buletin Digital Create', roles: allRole },
		component: CreateContent
	},
	{
		path: '/panduan/create',
		name: 'panduan-create',
		meta: { auth: true, name: 'Panduan Digital Create', roles: allRole },
		component: CreateContent
	},
	{
		path: '/e-learning/create',
		name: 'learning-create',
		meta: { auth: true, name: 'E-Learning Digital Create', roles: allRole },
		component: CreateContent
	},
	{
		path: '/dokumen-berkas',
		name: prop + '.dokumen',
		meta: { auth: true, name: 'Dokumen App', roles: allRole },
		component: Dokumen
	}
]

export default BukuSakuRouter
