import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
// import './plugins/axios'
import './plugins/_axios'
import router from './router'
import store from './store'
// import './plugins'
// import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// import 'leaflet/dist/leaflet.css'
// import { initializeApp } from 'firebase/app'
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Swal from 'sweetalert2'
import { isPermisionRole } from '@/plugins/RolePermision/role.js'
import { handleConfirm, openModalConfirm, openModalInfo, handleDenied } from '@/plugins/ModalGlobal/index.js'
import 'leaflet/dist/leaflet.css'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import 'driver.js/dist/driver.css';

import './assets/css/index.css'
import './assets/css/tms.css'

import 'vue-good-table-next/dist/vue-good-table-next.css'

import './assets/css/index.css'

// import { appFire } from './firebase'
// import VueApexCharts from 'vue3-apexcharts';

// import 'viewerjs/dist/viewer.css'

const app = createApp(App)
app.use(store).use(router)
app.config.globalProperties.$checkPermisionRole = isPermisionRole
app.config.globalProperties.$handleConfirm = handleConfirm
app.config.globalProperties.$handleDenied = handleDenied
app.config.globalProperties.$openModalConfirm = openModalConfirm
app.config.globalProperties.$openModalInfo = openModalInfo
app.config.globalProperties.$checkGuest = {
	validateGuest(payload) {
		const author = payload?.author || []
		if (isPermisionRole(['Guest Mode'])) {
			if (payload.alert) {
				Swal.fire({
					title: 'Akses Dibatasi',
					text: 'Login pengguna untuk melanjutkan',
					icon: 'warning',
					iconColor: '#8E4AA1',
					showCancelButton: true,
					confirmButtonText: 'Login',
					cancelButtonText: 'Lanjutkan Menjelajah',
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						router.push({ path: '/auth/signin' })
					}
				})
			}
			return true
		} else if (isPermisionRole(['Parent'])) {
			if (payload.alert) {
				Swal.fire({
					imageUrl: require('@/assets/images/user/parentmaaf.png'),
					title: 'Mohon Maaf',
					text: 'Fitur ini tidak tersedia di akun Pengawasan orang tua',
					iconColor: '#8E4AA1',
					confirmButtonText: 'TUTUP',
					reverseButtons: true,
					confirmButtonColor: '#8E4AA1'
				}).then((result) => {
					if (result.isConfirmed) {
						return false
					}
				})
			}
			return true
		} else {
			return false
		}
	}
}

app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GMAP,
		autobindAllEvents: true
	}
})
// app.use(VueApexCharts);
// app.use(VueSweetalert2)
app.mount('#app')

app.mixin({
	data() {
		return {
			breadcrumb: true
		}
	}
})
// Global Components
app.component('iq-card', require('./components/custom/cards/iq-card').default)
app.component('selection', require('./components/bootstrap/selection/selection').default)
app.component('loader', require('./components/custom/loader/Loader').default)
app.component('alert', require('./components/bootstrap/alert/alert').default)
app.component('carousel', require('./components/bootstrap/carousel/carousel').default)
app.component('carouselItems', require('./components/bootstrap/carousel/carouselItems').default)

app.component('progressbar', require('./components/bootstrap/Progressbar/progressbar').default)
app.component('popover', require('./components/bootstrap/popover/popover').default)
app.component('tooltip', require('./components/bootstrap/tooltip/tooltip').default)
// app.component('Lottie', require('./components/socialapp/lottie/Lottie').default)
app.component('tab-nav', require('./components/bootstrap/tab/tab-nav').default)
app.component('tab-nav-items', require('./components/bootstrap/tab/tab-nav-items').default)
app.component('tab-content', require('./components/bootstrap/tab/tab-content').default)
app.component('tab-content-item', require('./components/bootstrap/tab/tab-content-item').default)
app.component('TimeLine', require('./components/custom/timeline/TimeLine').default)
app.component('dropdown', require('./components/bootstrap/dropdown/dropdown').default)
app.component('dropdown-items', require('./components/bootstrap/dropdown/dropdown-items').default)
app.component('collapse', require('./components/bootstrap/collapse/collapse').default)
app.component('collapse-content', require('./components/bootstrap/collapse/collapse-content').default)
app.component('modal', require('./components/bootstrap/modal/modal').default)
app.component('model-header', require('./components/bootstrap/modal/model-header').default)
app.component('model-body', require('./components/bootstrap/modal/model-body').default)
app.component('model-footer', require('./components/bootstrap/modal/model-footer').default)
app.component('offcanvas', require('./components/bootstrap/offcanvas/offcanvas').default)
app.component('offcanvas-header', require('./components/bootstrap/offcanvas/offcanvas-header').default)
app.component('offcanvas-body', require('./components/bootstrap/offcanvas/offcanvas-body').default)
// export default {
//   created () {
//   }
// }
export { app }
