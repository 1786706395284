import axios from 'axios'
const moment = require('moment')
require('moment/locale/id')

export default {
  async getListMusdaAction (context, provinsiId) {
    try {
      const response = await axios.get(`/api/provinsi/${provinsiId}/musda`)
      if (response.status === 200) {
        context.commit('setListMusda', response.data.data.reverse())
      }
    } catch (error) {
      console.log(error)
    }
  },

  async storeMusdaAction (context, payload) {
    try {
      const form = new FormData()

      form.append('place', payload.tempat)
      form.append('date', payload.tanggal)

      const response = await axios.post(`api/provinsi/${payload.propinsiId}/musda`, form)

      context.commit('addListMusda', response.data.data)
    } catch (err) {
      throw err.response.data.meta.message
      // console.log(err)

      // let date = err.response.data.meta.message.split(' ')
      // date = date[date.length - 1]
      // const tanggal = moment(date)
      // tanggal.locale('id')
      // const hasilFormat = tanggal.format('dddd, D MMMM YYYY')

      // console.log(hasilFormat)

      // throw `Tidak bisa membuat di tanggal ${hasilFormat}`
    }
  },

  async updateMusdaAction ({ commit, state }, payload) {
    try {
      const form = new FormData()

      form.append('place', payload.tempat)
      form.append('date', payload.tanggal)

      const response = await axios.post(`api/provinsi/${payload.propinsiId}/musda/${payload.id}`, form)

      const newData = state.listMusda.map((data) => {
        if (data.id === payload.id) {
          data = response.data.data
        }

        return data
      })
      commit('setListMusda', newData)
    } catch (err) {
      throw err.response.data.meta.message
      // console.log(err)
    }
  },

  async deleteMusdaAction ({ commit, state }, payload) {
    try {
      const response = await axios.delete(`api/provinsi/${payload.propinsiId}/musda/${payload.id}`)

      if (response.status === 200) {
        const newData = state.listMusda?.filter((data) => {
          return parseInt(data.id) !== parseInt(payload.id)
        })
        commit('setListMusda', newData)
      }
    } catch (err) {
      throw err.response.data.meta.message
      // console.log(err)
    }
  }
}
