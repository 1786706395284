import axios from 'axios'
const moment = require('moment')
require('moment/locale/id')

export default {
  async getListMuscabAction (context, kabKotId) {
    try {
      const response = await axios.get(`api/kabupaten/${kabKotId}/muscab`)
      if (response.status === 200) {
        context.commit('setListMuscab', response.data.data.reverse())
      }
    } catch (error) {
      console.log(error)
    }
  },

  async storeMuscabAction (context, payload) {
    try {
      const form = new FormData()
      
      form.append('place', payload.tempat)
      form.append('date', payload.tanggal)
      
      const response = await axios.post(`api/kabupaten/${payload.kabKotId}/muscab`, form)
      
      context.commit('addListMuscab', response.data.data)
    } catch (err) {
      throw err.response.data.meta.message

      // let date = err.response.data.meta.message.split(' ')
      // date = date[date.length - 1]
      // const tanggal = moment(date)
      // tanggal.locale('id')
      // const hasilFormat = tanggal.format('dddd, D MMMM YYYY')

      // throw `Tidak bisa membuat di tanggal ${hasilFormat}`
    }
  },
  
  async updateMuscabAction ({ commit, state }, payload) {
    try {
      const form = new FormData()

      form.append('place', payload.tempat)
      form.append('date', payload.tanggal)

      const response = await axios.post(`/api/kabupaten/${payload.kabKotId}/muscab/${payload.id}`, form)

      const newData = state.listMuscab.map((data) => {
        if (data.id === payload.id) {
          data = response.data.data
        }

        return data
      })
      commit('setListMuscab', newData)
    } catch (err) {
      throw err.response.data.meta.message
    }
  },

  async deleteMuscabAction ({ commit, state }, payload) {
    try {
      const response = await axios.delete(`api/kabupaten/${payload.kabKotId}/muscab/${payload.id}`)

      if (response.status === 200) {
        const newData = state.listMuscab?.filter((data) => {
          return parseInt(data.id) !== parseInt(payload.id)
        })
        commit('setListMuscab', newData)
      }
    } catch (err) {
      throw err.response.data.meta.message
      // console.log(err)
    }
  }
}
