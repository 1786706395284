import getters from './getters'
import actions from './action'
import mutations from './mutation'

const state = {
  modalInfo: {
		message: '',
		title: '',
		type: 'success',
    withTitle: true,
	},
	modalData: {
		message: '',
		title: '',
		type: 'confirm',
    withTitle: true,
    loading: false,
		customContent: null,
		customLoading: null,
    confirmButtonText: 'Konfirmasi',
		deniedButtonText: 'Batal'
	},
  myFunction: null,
	deniedFunction: null
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
