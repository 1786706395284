import axios from "axios"
export default {
  beritaCommit (state, data) { 
    if (data == null) {
      state.berita = []
    }else{
      state.berita.push(...data)
    }
  },
  detailBeritaCommit(state,data){
    state.detailBerita = data
  },
  tweetCommit (state, data) { 
    if (data == null) {
      state.tweet = []
    }else{
      state.tweet.push(...data)
    }
  },
  detailTweetCommit(state,data){
    state.detailTweet = data
  },
  reloadTweetCommit (state,data) {
    state.tweet = state.tweet
    .map((item) => {
      if (item.key==data.key) {
        return item = data
      }else{
        return item
      }
    })
  },
  reloadCommentCommit (state,data) {
    state.comment = state.comment
    .map((item) => {
      if (item.key==data.key) {
        return item = data
      }else{
        return item
      }
    })
  },
  commentCommit (state, data) {
    if (data == null) {
      state.comment = []
    }else{
      state.comment.push(...data)
    }
  },
  deleteTweetCommit (state,data) {
    state.tweet = state.tweet.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
    state.comment = state.comment.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
  },
  deleteCommentCommit (state,data) {
    state.tweet = state.tweet.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
    state.comment = state.comment.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
  },
  muteTweetCommit (state,data) {
    state.tweet = state.tweet.filter( item => item.user.userId !== data.user.userId)
    .map((item) => {
        return item
    })
    state.comment = state.comment.filter( item => item.user.userId !== data.user.userId)
    .map((item) => {
        return item
    })
  },
  // BUKU
  bukuCommit (state, data) { 
    if (data == null) {
      state.buku = []
    }else{
      state.buku.push(...data)
    }
  },
  //E-learning
  learningCommit (state, data) { 
    if (data == null) {
      state.learning = []
    }else{
      state.learning.push(...data)
    }
  },
  panduanCommit (state, data) { 
    if (data == null) {
      state.panduan = []
    }else{
      state.panduan.push(...data)
    }
  },
  stopCommit (state, data) {
    state.stop = data
  }
}
