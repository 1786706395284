const state = {
	currentStep: 0,
	nomorPesanan: '',
	gudep: {},
	dataAnggota: [],
	dataAnggotaNotVerified: [],
	isDetailAnggota: false,
	paymentStep: 1,
	dataPengirim: {},
	invoiceNumber: null,
	paymentSelected: {},
	isPageAnggota: false,
	isUnggah: true,
	isAggrement: false,
	isEditAnggota: false,
	isEditDataAnggota: false,
	isPesanan: false,
	kwarda: null,
	kwarcab: null,
	kwarran: null,
	sekolah: null,
	sakaSako: null,
	isSakaSako: false,
	paymentSchema: null
}
const mutations = {
	setPaymentSchema(state, value) {
		state.paymentSchema = value
	},
	setIsSakaSako(state, value) {
		state.isSakaSako = value
	},
	setKwartir(state, value) {
		switch (value.type) {
			case 'kwarda':
				state.kwarda = value.value
				break

			case 'kwarcab':
				state.kwarcab = value.value
				break

			case 'kwarran':
				state.kwarran = value.value
				break

			case 'sekolah':
				state.sekolah = value.value
				break

			default:
				break
		}
	},
  setSakaSako(state, value) {
    state.sakaSako = value
  },
	setCurrentStep(state, value) {
		state.currentStep = value
	},
	setPaymentStep(state, value) {
		state.paymentStep = value
	},
	setNomorPesanan(state, value) {
		state.nomorPesanan = value
	},
	setGudep(state, value) {
		state.gudep = value
	},
	setDataAnggota(state, value) {
		state.dataAnggota = value
	},
	setDataAnggotaNotVerified(state, value) {
		state.dataAnggotaNotVerified = value
	},
	setIsDetailAnggota(state, value) {
		state.isDetailAnggota = value
	},
	setDataPengirim(state, value) {
		state.dataPengirim = value
	},
	setInvoiceNumber(state, value) {
		state.invoiceNumber = value
	},
	setPaymentSelected(state, value) {
		state.paymentSelected = value
	},
	setIsPageAnggota(state, value) {
		state.isPageAnggota = value
	},
	setIsUnggah(state, value) {
		state.isUnggah = value
	},
	setAggrement(state, value) {
		state.isAggrement = value
	},
	setIsEditAnggota(state, value) {
		state.isEditAnggota = value
	},
	setIsEditDataAnggota(state, value) {
		state.isEditDataAnggota = value
	},
	setIsPesanan(state, value) {
		state.isPesanan = value
	},
	setClearKta(state) {
		state.currentStep = 0
		state.nomorPesanan = ''
		state.gudep = {}
		state.paymentSelected = {}
		state.isDetailAnggota = false
		state.paymentStep = 1
		state.dataPengirim = {}
		state.dataAnggota = []
		state.dataAnggotaNotVerified = []
		state.invoiceNumber = null
		state.isPageAnggota = false
		state.isAggrement = false
		state.isUnggah = true
		state.isPesanan = false
    state.kwarda = null
    state.kwarcab = null
    state.kwarran = null
    state.sekolah = null
    state.sakaSako = null
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
