import axios from 'axios'
import { doRefresh } from './method'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const apiRequest = axios.create({ timeout: 60 * 1000 })

axios.interceptors.request.use(
	(config) => {
		const excepted = ['api/firebase-auth'].includes(config.url)
		const token = localStorage.getItem('token')
		if (!excepted && token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}
		return config
	},
	(error) => Promise.reject(error)
)

// Response interceptor for API calls
axios.interceptors.response.use(
	(response) => {
		return response
	},
	async function (error) {
		const originalRequest = error.config
		if ([401].includes(error.response.status) && !originalRequest._retry) {
			originalRequest._retry = true
			const { token } = await doRefresh()
			originalRequest.headers['Authorization'] = `Bearer ${token}`

			return apiRequest(originalRequest)
		}
		return Promise.reject(error)
	}
)

export default apiRequest
