import axios from "axios"
import Swal from "sweetalert2"
export default {
  async PenggunaDisarankanAction (context, payload) {
    try {

      let response = await axios.get('api/user/v2/recommended-users', {params : payload})
      if (response.status === 200) {
        context.commit('PenggunaDisarankanCommit', response.data.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async BeritaAction (context, payload) {
    try {
      let response = await axios.get('api/article', {params : payload})
      if (response.status === 200) {
        context.commit('BeritaCommit', response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async TweetAction (context, payload) {
    try {
      let response = await axios.get('api/feed/get-feed', {params : payload})
      
      if (response.status === 200) {
        let check = context.state.Tweet.includes(response.data.data[0])
        if (!check) {
          context.commit('tweetCommit', response.data.data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  async myTweetAction (context, payload) {
    try {
      let response = await axios.get('/api/feed/get-by-profile?userId='+user+'&page=1&limit=100&filter=komentar,galery', {params : payload})
      
      if (response.status === 200) {
        if (response.status === 200) {
          let check = context.state.myTweet.includes(response.data.data[0])
          if (!check) {
            context.commit('mytweetCommit', response.data.data)
          }
        }
      
      }
    } catch (error) {
      console.log(error)
    }
  },
  async LikeAction (context, payload) {
    try {
      let response = await axios.post('api/feed/like', payload)

    } catch (error) {
      console.log(error)
    }
  },
  async FollowAction (context, payload) {
    try {
      let response = await axios.post('api/user/follow', payload)
      if (response.status === 200) {
        // Swal.fire({
        //   title: 'Berhasil',
        //   text: payload.follow=='1'?'Berhasil mengikuti pengguna':'Berhasil berhenti mengikuti pengguna',
        //   icon: 'success',
        //   iconColor:'#8E4AA1',
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      }
    } catch (error) {
      console.log(error)
    }
  }
}
