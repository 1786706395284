import Swal from 'sweetalert2'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import axios from 'axios'
// Page Social Dengan Header SIdebar
import SocialRouter from './SocialRouter.js'
import BukuSakuRouter from './BukuSakuRouter.js'
import LaporanRouter from './LaporanRouter.js'
import GerakanPramukaRouter from './GerakanPramukaRouter.js'
import ProfileRouter from './ProfileRouter.js'
import GameRouter from './GameRouter.js'
import SelfRegisterRouter from './SelfRegisterRouter.js'
import AuthentifikasiRouter from './AuthentifikasiRouter.js'
import SismintirRouter from './SismintirRouter.js'
import ReferensiRouter from './ReferensiRouter'
import PenghargaanRouter from './PenghargaanRouter'
import EdiklatRouter from './EdiklatRouter'
import AtasRouter from './AtasRouter'
import GiatRouter from './GiatRouter.js'
import RoleManagementRouter from './RoleMagagementRouter.js'
import RefMasterRouter from './RefMasterRouter.js'
import DiklatRevamp from './DiklatRevampRouter.js'
import SafeFromHarm from './SafeFromHarmRouter.js'

import { allRole, whereRole, WhereNotRole } from '@/plugins/RolePermision/role.js'
import { getDecodeToken, getExpiredToken, getMe, isAdminAppsRole } from '../plugins/method.js'
import { path } from 'ramda'

// PAGE KHUSUS
const DataWilayah = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/DataWilayah/PageIndex.vue')
const DocumentEditor = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/DocumentEditor/PageIndex.vue')
const DetailDocumentEditor = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/DocumentEditor/PageDetailIndex.vue')
const Mobile = () => import(/* webpackChunkName: "mobile" */ '../views/Mobile/Index')
const MobileKta = () => import(/* webpackChunkName: "mobile-kta" */ '../views/Mobile/RegistrasiKTA')
const MobilePesanan = () => import(/* webpackChunkName: "mobile-pesanan" */ '../views/Mobile/Pesanan')
const TataCaraMobile = () => import(/* webpackChunkName: "mobile" */ '../views/Mobile/TataCaraMobile')
const RekapPdf = () => import(/* webpackChunkName: "rekap-pdf" */ '../views/Apps/Report Rekap Golongan/Components/Pdf.vue')
const RegistPdf = () => import(/* webpackChunkName: "absensi-pdf" */ '../views/Apps/Kwarran/print.vue')
const AbsensiPdf = () => import(/* webpackChunkName: "absensi-pdf" */ '../views/Apps/Absensi/Print.vue')
const PageNotFound = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/Error/PageNotFound')
const PageForbiden = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/Error/PageForbiden')
const LacakSurat = () => import(/* webpackChunkName: "error-404" */ '../views/AuthPages/Default/SigninV2/LacakSurat.vue')
const PendaftaranPelatihan = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/E-diklat/Page/Pendaftaran.vue')
const PageFormulirMandiri = () => import(/* webpackChunkName: "PageFormulirMandiri" */ '../views/Apps/FormulirMandiri/Pages/PageFormulirMandiri.vue')
const PendaftaranGiatPramuka = () =>
	import(/* webpackChunkName: "PendaftaranGiatPramuka" */ '../views/Apps/PendaftaranGiatPramuka/Pages/PagePendaftaranGiat.vue')
const PendaftaranMandiriGiatPramuka = () =>
	import(/* webpackChunkName: "PendaftaranMandiriGiat" */ '../views/Apps/PendaftaranMandiriGiatPramuka/Pages/PagePendaftaranMandiriGiat.vue')
const PembayaranMandiriGiatPramuka = () =>
	import(/* webpackChunkName: "PembayaranMandiriGiat" */ '../views/Apps/PendaftaranMandiriGiatPramuka/Pages/PagePembayaranGiat.vue')
const QRPendaftaranGiat = () =>
	import(/* webpackChunkName: "QRPendaftaranGiat" */ '../views/Apps/PendaftaranMandiriGiatPramuka/Pages/PageQRPendaftaranGiat.vue')
const PageInvoice = () => import(/* webpackChunkName: "PageInvoice" */ '../views/Apps/Giat/Page/PageInvoice.vue')
const DashboardWilayah = () => import(/* webpackChunkName: "error-404" */ '../views/Apps/DashboardWilayah/PageIndex.vue')
const PageFormulirPusdatin = () =>
	import(/* webpackChunkName: "PageFormulirPusdatin" */ '../views/Apps/FormulirPusdatin/Pages/PageFormulirPusdatin.vue')
const PageInvoiceDiklat = () => import(/* webpackChunkName: "PageInvoiceDiklat" */ '../views/Apps/Diklat-revamp/page/PageInvoiceDiklat.vue')

const PendaftaranDiklat = () => import(/* webpackChunkName: "E-Diklat" */ '../views/Apps/Diklat-revamp/page/Pendaftaran.vue')

// PAGE LINK PENGAJUAN PENDAFTARAN DARI WA
const PageFormPengajuanLink = () => import(/* webpackChunkName: "PageFormPengajuanLink" */ '../views/Apps/Penghargaan/Page/PageFormPengajuanLink.vue')
// const PageFormPengajuanLinkAtas = () =>
// 	import(/* webpackChunkName: "PageFormPengajuanLinkAtas" */ '../views/Apps/Atas/Page/PageFormPengajuanLink.vue')
// const PageTatacaraPengajuanLinkAtas = () =>
// 	import(/* webpackChunkName: "PageTatacaraPengajuanL	inkAtas" */ '../views/Apps/Atas/Page/PageTatacaraPengajuanLinkAtas.vue')
// const PageDetailPengajuanLink = () => import(/* webpackChunkName: "PageDetailPengajuanLink" */ '../views/Apps/Atas/Page/PageDetailPengajuanLink.vue')
// const PageFormOrderKTA = () => import(/* webpackChunkName: "PageFormOrderKTA" */ '../views/Apps/Atas/Page/PageFormOrderKTA.vue')
const PageFormulirPendataan = () =>
	import(/* webpackChunkName: "PageFormulirPendataan" */ '../views/Apps/FormulirPendataan/Pages/PageFormulirPendataan.vue')
const PagePembayaranPendataan = () =>
	import(/* webpackChunkName: "PagePembayaranPendataan" */ '../views/Apps/FormulirPendataan/Pages/PagePembayaranPendataan.vue')
const PageFormulirKwarda = () => import(/* webpackChunkName: "PageFormulirKwarda" */ '../views/Apps/FormulirKwarda/Pages/PageFormulirKwarda.vue')
const PageKebijakanFormulir = () =>
	import(/* webpackChunkName: "PageKebijakanFormulir" */ '../views/Apps/FormulirKwarda/Pages/PageKebijakanFormulir.vue')
const PageFormulirKwarcab = () => import(/* webpackChunkName: "PageFormulirKwarcab" */ '../views/Apps/FormulirKwarcab/Pages/PageFormulirKwarcab.vue')
const PageFormulirPendataanMandiri = () =>
	import(/* webpackChunkName: "PageFormulirPendataanMandiri" */ '../views/Apps/FormulirPendataanMandiri/Pages/PageFormulirPendataanMandiri.vue')
const PageFormulirKwartir = () => import(/* webpackChunkName: "PageFormulirKwartir" */ '../views/Apps/FormulirKwartir/Pages/PageFormulirKwartir.vue')
const PageFormulirKPMN = () => import(/* webpackChunkName: "PageFormulirKPMN" */ '../views/Apps/FormulirKPMN/Pages/PageFormulirKPMN.vue')
const PageFormulirKPMNPeserta = () =>
	import(/* webpackChunkName: "PageFormulirKPMNPeserta" */ '../views/Apps/FormulirKPMNPeserta/Pages/PageFormulirKPMN.vue')
const PageFormulirKDN = () => import(/* webpackChunkName: "PageFormulirKDN" */ '../views/Apps/FormulirKDN/Pages/PageFormulirKDN.vue')
const PageFormulirPendataanAnggota = () =>
	import(/* webpackChunkName: "PageFormulirPendataanAnggota" */ '../views/Apps/FormulirPendataanAnggota/Pages/PageFormulirPendataan.vue')
const PagePembayaranPendataanAnggota = () =>
	import(/* webpackChunkName: "PagePembayaranPendataanAnggota" */ '../views/Apps/FormulirPendataanAnggota/Pages/PagePembayaranPendataan.vue')
const VerifyBSREDoc = () => import(/* webpackChunkName: "VerifyBSREDoc" */ '../views/Apps/VerifyBSREDoc/index.vue')
const PageFormulirSFH = () => import(/* webpackChunkName: "PageFormulirKPMN" */ '../views/Apps/FormulirSFH/Pages/PageFormulirSFH.vue')
const PageScanKTA = () => import(/* webpackChunkName: "PageScanKTA" */ '../views/Apps/ScanKTA/Pages/PageScanKTA.vue')
const FormulirTPGP = () => import(/* webpackChunkName: "FormulirTPGP" */ '../views/Apps/PenghargaanNew/formulir/index.vue')
const PageVerifikasiKTA = () => import(/* webpackChunkName: "PageVerifikasiKTA" */ '../views/Apps/VerifikasiKTA/Pages/PageVerifikasiKTA.vue')
const RiwayatTPGP = () => import(/* webpackChunkName: "RiwayatTPGP" */ '../views/Apps/PenghargaanNew/pages/RiwayatPage.vue')
const PageFormDaftarAnggota = () =>
	import(/* webpackChunkName: "PageFormDaftarAnggota" */ '../views/Apps/FormDaftarAnggota/Pages/PageFormulirPendataan.vue')
const PagePembayaranDaftarAnggota = () =>
	import(/* webpackChunkName: "PagePembayaranDaftarAnggota" */ '../views/Apps/FormDaftarAnggota/Pages/PagePembayaranPendataan.vue')

const pagesCetak = (prop, mode = false) => [
	{
		path: 'rekap-pdf',
		name: prop + '.rekap-pdf',
		meta: { auth: false },
		component: RekapPdf
	},
	{
		path: 'presensi',
		name: prop + 'presensi',
		meta: { auth: false },
		component: AbsensiPdf
	},
	{
		path: 'registrasi-anggota',
		name: prop + 'kwarran-print',
		meta: { auth: false },
		component: RegistPdf
	}
]

const routes = [
	{
		path: '/',
		name: 'social',
		component: () => import('../layouts/Default'),
		children: [
			...SocialRouter,
			...BukuSakuRouter,
			...LaporanRouter,
			...GerakanPramukaRouter,
			...ProfileRouter,
			...GameRouter,
			...SelfRegisterRouter,
			...SismintirRouter,
			...ReferensiRouter,
			...PenghargaanRouter,
			...EdiklatRouter,
			...AtasRouter,
			...RoleManagementRouter,
			...GiatRouter,
			...RefMasterRouter,
			...DiklatRevamp,
			...SafeFromHarm
		]
	},
	{
		path: '/auth',
		name: 'auth',
		component: () => import('../layouts/Empty'),
		children: [...AuthentifikasiRouter]
	},
	{
		path: '/lacak-surat',
		meta: { auth: false, name: 'Lacak Surat' },
		component: LacakSurat
	},
	{
		path: '/data-wilayah',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				meta: { auth: false, name: 'Data Wilayah' },
				component: DataWilayah
			}
		]
	},
	{
		path: '/dashboard-wilayah',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				meta: { auth: false, name: 'Dashboard Wilayah' },
				component: DashboardWilayah
			}
		]
	},
	{
		path: '/document-editor',
		meta: { auth: false, name: 'Document' },
		component: DocumentEditor
	},
	{
		path: '/document-editor/:id',
		meta: { auth: false, name: 'Detail Document' },
		component: DetailDocumentEditor
	},
	{
		path: '/cetak',
		name: 'cetak',
		component: () => import('../layouts/Empty'),
		children: pagesCetak('default')
	},
	{
		path: '/formulir-mandiri',
		name: 'formulir-mandiri',
		meta: { auth: false, name: 'Formulir Pendaftaran' },
		component: PageFormulirMandiri
	},
	{
		path: '/formulir-pusdatin',
		name: 'formulir-pusdatin',
		meta: { auth: false, name: 'Formulir Pendaftaran' },
		component: PageFormulirPusdatin
	},
	// {
	// 	path: '/formulir-pendataan-potensi',
	// 	name: 'formulir-pendataan-potensi',
	// 	meta: { auth: false, name: 'Formulir Pendataan' },
	// 	component: PageFormulirPendataan
	// },
	// {
	// 	path: '/formulir-pendataan-potensi/pembayaran/:id',
	// 	name: 'formulir-pendataan-potensi-pembayaran',
	// 	meta: { auth: false, name: 'Pembayaran Formulir Pendataan' },
	// 	component: PagePembayaranPendataan
	// },
	// {
	// 	path: '/formulir-pendataan-anggota',
	// 	name: 'formulir-pendataan-anggota',
	// 	meta: { auth: false, name: 'Formulir Pendataan Anggota' },
	// 	component: PageFormulirPendataanAnggota
	// },
	// {
	// 	path: '/formulir-pendataan-anggota/pembayaran/:id',
	// 	name: 'formulir-pendataan-anggota-pembayaran',
	// 	meta: { auth: false, name: 'Pembayaran Formulir Pendataan Anggota' },
	// 	component: PagePembayaranPendataanAnggota
	// },
	{
		path: '/form-daftar-anggota',
		name: 'form-daftar-anggota',
		meta: { auth: false, name: 'Formulir Pendataan Anggota' },
		component: PageFormDaftarAnggota
	},
	{
		path: '/form-daftar-anggota/pembayaran/:id',
		name: 'form-daftar-anggota-pembayaran',
		meta: { auth: false, name: 'Pembayaran Formulir Pendataan Anggota' },
		component: PagePembayaranDaftarAnggota
	},
	{
		path: '/formulir-pendataan-kwarda',
		name: 'formulir-pendataan-kwarda',
		meta: { auth: false, name: 'Formulir Pendataan Kwarda' },
		component: PageFormulirKwarda
	},
	{
		path: '/formulir-pendataan-kwarcab/:id',
		name: 'formulir-pendataan-kwarcab',
		meta: { auth: false, name: 'Formulir Pendataan Kwarcab' },
		component: PageFormulirKwarcab
	},
	{
		path: '/formulir-pendataan-kwartir/:id',
		name: 'formulir-pendataan-kwartir',
		meta: { auth: false, name: 'Formulir Pendataan Kwartir' },
		component: PageFormulirKwartir
	},
	// {
	// 	path: '/formulir-kpmn-2024',
	// 	name: 'formulir-kpmn',
	// 	meta: { auth: false, name: 'Formulir KPMN' },
	// 	component: PageFormulirKPMN
	// },
	// {
	// 	path: '/formulir-peserta-kpmn-2024',
	// 	name: 'formulir-peserta-kpmn',
	// 	meta: { auth: false, name: 'Formulir Peserta KPMN' },
	// 	component: PageFormulirKPMNPeserta
	// },
	{
		path: '/workshop-kolaborasi-inovasi',
		name: 'workshop-kolaborasi-inovasi',
		meta: { auth: false, name: 'Formulir KDN' },
		component: PageFormulirKDN
	},

	{
		path: '/ver-doc/:id',
		name: 'ver-doc',
		meta: { auth: false, name: 'Verifikasi BSRE Doc' },
		component: VerifyBSREDoc
	},
	{
		path: '/formulir-pendataan-admin',
		name: 'formulir-pendataan-admin',
		meta: { auth: false, name: 'Formulir Pendataan Admin' },
		component: PageFormulirPendataanMandiri
	},
	{
		path: '/kebijakan-formulir-pendataan',
		name: 'kebijakan-formulir-pendataan',
		meta: { auth: false, name: 'Kebijakan Formulir Pendataan' },
		component: PageKebijakanFormulir
	},
	{
		path: '/giat-pramuka/ruang-giat/:id/:kontingen',
		name: 'invoice-giat-pramuka',
		meta: { auth: true, name: 'giat pramuka invoice' },
		component: PageInvoice
	},
	{
		path: '/diklat/invoice/:id/:kontingen',
		name: 'diklat-invoice',
		meta: { auth: true, name: 'diklat-invoice' },
		component: PageInvoiceDiklat
	},
	{
		path: '/pendaftaran-pelatihan/:id',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				name: 'pendaftaran-pelatihan',
				meta: { auth: false, name: 'Pendaftaran Pelatihan' },
				component: PendaftaranDiklat
			}
		]
	},
	{
		path: '/pendaftaran-formulir-sfh',
		name: 'pendaftaran-formulir-sfh',
		meta: { auth: false, name: 'Pendaftaran Formulir SFH' },
		component: PageFormulirSFH
	},
	{
		path: '/pendaftaran-diklat/:id',
		name: 'pendaftaran-diklat',
		meta: { auth: false, name: 'Pendaftaran Diklat' },
		component: PendaftaranDiklat
	},
	{
		path: '/pendaftaran-giat/:id',
		name: 'pendaftaran-giat',
		meta: { auth: false, name: 'Pendaftaran Giat Pramuka' },
		component: PendaftaranGiatPramuka
	},
	{
		path: '/pendaftaran-giat/peserta/:id',
		name: 'pendaftaran-mandiri-giat-peserta',
		meta: { auth: false, name: 'Pendaftaran Giat Pramuka' },
		component: PendaftaranMandiriGiatPramuka
	},
	{
		path: '/pendaftaran-giat/panitia/:id',
		name: 'pendaftaran-mandiri-giat-panitia',
		meta: { auth: false, name: 'Pendaftaran Giat Pramuka' },
		component: PendaftaranMandiriGiatPramuka
	},
	{
		path: '/pembayaran-giat/:id',
		name: 'pembayaran-mandiri-giat',
		meta: { auth: false, name: 'Pembayaran Mandiri Giat' },
		component: PembayaranMandiriGiatPramuka
	},
	{
		path: '/qr-pendaftaran-giat/:id',
		name: 'qr-mandiri-giat',
		meta: { auth: false, name: 'QR Pendaftaran Giat' },
		component: QRPendaftaranGiat
	},
	{
		path: '/mobile',
		name: 'mobile-download',
		meta: { auth: false, name: 'Mobile Download App' },
		component: Mobile
	},
	{
		path: '/mobile/tata-cara',
		name: 'mobile-tata-cara',
		meta: { auth: false, name: 'Mobile tata Cara' },
		component: TataCaraMobile
	},
	{
		path: '/mobile/registrasi-kta',
		name: 'mobile-registrasi-kta',
		meta: { auth: false, name: 'Mobile Registrasi Kta App' },
		component: MobileKta
	},
	{
		path: '/mobile/pesanan',
		name: 'mobile-pesanan',
		meta: { auth: false, name: 'Mobile Pesanan Kta App' },
		component: MobilePesanan
	},
	{
		path: '/link-pengajuan/:id',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				meta: { auth: false, name: 'link pengajuan' },
				component: PageFormPengajuanLink
			}
		]
	},
	// {
	// 	path: '/tatacara-link-pengajuan-atas',
	// 	component: () => import('../layouts/Empty'),
	// 	children: [
	// 		{
	// 			path: '',
	// 			meta: { name: 'Tata Cara Pengajuan Atas' },
	// 			component: PageTatacaraPengajuanLinkAtas
	// 		}
	// 	]
	// },
	// {
	// 	path: '/link-detail-atas/:id',
	// 	component: () => import('../layouts/Empty'),
	// 	children: [
	// 		{
	// 			path: '',
	// 			meta: { name: 'Detail  Pengajuan Atas' },
	// 			component: PageDetailPengajuanLink
	// 		}
	// 	]
	// },
	// {
	// 	path: '/pengajuan/atas',
	// 	component: () => import('../layouts/Empty'),
	// 	children: [
	// 		{
	// 			path: '',
	// 			meta: { name: ' Pengajuan Atas' },
	// 			component: PageFormPengajuanLinkAtas
	// 		}
	// 	]
	// },
	// {
	// 	path: '/pemesanan/kta/:id',
	// 	component: () => import('../layouts/Empty'),
	// 	children: [
	// 		{
	// 			path: '',
	// 			meta: { auth: false, name: ' Pemesanan KTA', permission: 'Dewasa', roles: allRole },
	// 			component: PageFormOrderKTA
	// 		}
	// 	]
	// },
	{
		path: '/kepramukaan/:id',
		name: 'kepramukaan',
		meta: { auth: false, name: 'Kepramukaan' },
		component: PageScanKTA
	},
	{
		path: '/verifikasi-kta',
		name: 'verifikasi-kta',
		meta: { auth: false, name: 'Verifikasi KTA' },
		component: PageVerifikasiKTA
	},
	{
		path: '/formulir-tpgp/:id',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				name: 'formulir-tpgp',
				meta: { auth: false, name: 'formulir-tpgp' },
				component: FormulirTPGP
			}
		]
	},
	{
		path: '/tracking-tpgp/:id',
		component: () => import('../layouts/Empty'),
		children: [
			{
				path: '',
				name: 'tracking-tpgp',
				meta: { auth: false, name: 'tracking-tpgp' },
				component: RiwayatTPGP
			}
		]
	},

	{
		path: '/:catchAll(.*)',
		meta: { auth: false, name: 'Page tidak ditemukan' },
		component: PageNotFound
	},
	{
		path: '/error403',
		meta: { auth: false, name: 'Akses dibatasi' },
		component: PageForbiden
	}
]

const router = createRouter({
	history: createWebHistory(),
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const removeListener = onAuthStateChanged(
			getAuth(),
			(user) => {
				removeListener()
				resolve(user)
			},
			reject
		)
	})
}

const getTokenMe = async (curentUser) => {
	try {
		axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
		axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`

		const response = await axios.get('/api/auth/me', {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
		if (response.status == 200) {
			await store.commit('Auth/userCommit', response.data.data)
			await store.commit('Auth/panggilanCommit', response.data?.data?.jenisAnggota === 'Muda' ? 'Adik' : 'Kakak')
			await axios.post('/api/firebase-auth', { firebase_token: curentUser.uid }).then(async (res) => {
				if (res.status === 200) {
					localStorage.setItem('token', res?.data?.data?.token?.access_token)
					localStorage.setItem('reToken', res?.data?.data?.token?.refresh_token)
					axios.defaults.headers['Authorization'] = 'Bearer ' + res?.data?.data?.token?.access_token
					axios.defaults.headers.post['Authorization'] = 'Bearer ' + res?.data?.data?.token?.access_token
					// axios.defaults.headers.common['userID'] = curentUser.uid
					const roles = res.data.data?.user?.roles
					await store.commit('RolePermission/setRoles', roles)
					return true
				}
			})
			return true
		}
	} catch (error) {
		return false
	}
}

window.mobileCheck = function () {
	let check = false
	;(function (a) {
		if (
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		)
			check = true
	})(navigator.userAgent || navigator.vendor || window.opera)
	return check
}

router.beforeEach(async (to, from, next) => {
	store.commit('Auth/loadingCommit', true)

	if (to.name !== 'diklat.ruang-diklat') {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant'
		})
	}

	var titlePage = to.meta.name ? to.meta.name : ''
	document.title = `AyoPramuka Kwarnas - ` + titlePage
	var redirect = null
	// const curentUser = await getCurrentUser()
	// console.log(to.name)
	if (!to.meta.auth) {
		next()
	}
	if (
		to.path != '/mobile' &&
		(await window.mobileCheck()) &&
		!(
			[
				'/auth/resetpassword',
				'/formulir-mandiri',
				'/formulir-pusdatin',
				'/formulir-pendataan-kwarda',
				'/kebijakan-formulir-pendataan',
				'/formulir-pendataan-admin',
				'/formulir-pendataan-kwartir',
				'/formulir-pendataan-kwarcab',
				'/formulir-pendataan-potensi',
				'/verifikasi-kta'
			].includes(to.path) ||
			[
				'ayo-pramuka-kwarnas',
				'formulir-pendataan-kwartir',
				'formulir-pendataan-kwarcab',
				'pendaftaran-giat',
				// 'formulir-kpmn',
				// 'formulir-peserta-kpmn',
				'workshop-kolaborasi-inovasi',
				'formulir-pendataan-potensi',
				'formulir-pendataan-potensi-pembayaran',
				'formulir-pendataan-anggota',
				'formulir-pendataan-anggota-pembayaran',
				'form-daftar-anggota',
				'form-daftar-anggota-pembayaran',
				'pendaftaran-mandiri-giat-panitia',
				'pendaftaran-mandiri-giat-peserta',
				'pembayaran-mandiri-giat',
				'qr-mandiri-giat',
				'ver-doc',
				'kepramukaan',
				'formulir-tpgp',
				'tracking-tpgp'
			].includes(to.name)
		)
	) {
		redirect = { path: '/mobile' }
		next(redirect)
		return
	}

	if (Object.keys(store.state.Auth.user).length == 0 && to.meta.roles) {
		await getMe()
		// next()
		// return
	}

	const userRoles = store.state.Auth.user ? store.state.RolePermission.roles : []
	if (to.meta.roles) {
		const isAllowed = isAdminAppsRole(userRoles, to.meta.roles)
		if (!isAllowed) {
			return next('/error403')
		}
	}

	const access_token = localStorage.getItem('token')
	const isAuthRequired = to.meta.auth || false
	let isLoggedIn = false

	const parsedToken = await getDecodeToken(access_token)

	if ((isAuthRequired || ['/auth/signin'].includes(to.path)) && parsedToken) {
		const parsedTokenValid = getExpiredToken(parsedToken)
		isLoggedIn = !parsedTokenValid?.expired
	}

	// if (access_token) {

	// 	if (to.path === '/auth/signin') {
	// 		next(from.path)
	// 		return
	// 	}
	// }

	if (!isLoggedIn && to.meta.roles) {
		await getMe()
		next()
		return
	}

	// if (['/auth/signin', '/auth/recoverpassword'].includes(to.path) && isLoggedIn) {
	// 	next(from.path)
	// 	return
	// }

	next()
})

// router.beforeEach(async (to, from, next) => {
// 	store.commit('Auth/loadingCommit', true)
// 	window.scrollTo({
// 		top: 0,
// 		left: 0,
// 		behavior: 'instant'
// 	})

// 	var titlePage = to.meta.name ? to.meta.name : ''
// 	document.title = `AyoPramuka ${titlePage === 'Login' ? 'Kwarnas' : ''} - ` + titlePage

// 	const curentUser = await getCurrentUser()
// 	var redirect = null
// 	if (to.path != '/mobile' && (await window.mobileCheck())) {
// 		redirect = { path: '/mobile' }
// 		next(redirect)
// 		return
// 	}
// 	if (!to?.meta?.auth) {
// 		next()
// 		return
// 	}
// 	if (!curentUser) {
// 		redirect = { name: 'auth1.sign-in1' }
// 		next(redirect)
// 		return
// 	}

// 	redirect = { path: '/error403' }
// 	if (!store.state.Auth.user || !to.meta.roles) {
// 		if (await getTokenMe(curentUser)) {
// 			await store.state.RolePermission.roles?.forEach((userRole) => {
// 				if (to.meta.roles.includes(userRole.name)) {
// 					redirect = 'next'
// 				}
// 			})
// 		} else {
// 			redirect = { name: 'auth1.sign-in1' }
// 			next(redirect)
// 			return
// 		}
// 	} else {
// 		await store.state.RolePermission.roles?.forEach((userRole) => {
// 			if (to.meta.roles.includes(userRole.name)) {
// 				redirect = null
// 			}
// 		})
// 	}
// 	if (redirect == 'next') {
// 		next()
// 	} else {
// 		next(redirect)
// 	}
// 	return
// })

// router.beforeEach(async (to, from, next) => {
//   store.commit('Auth/loadingCommit', true)
//   window.scrollTo({
//     top: 0,
//     left: 0,
//     behavior: 'instant'
//   })

//   var titlePage = to.meta.name ? to.meta.name : ''
//   document.title = 'Ayo Pramuka - ' + titlePage
//   const curentUser = await getCurrentUser()
//   if (to?.meta?.auth) {
//     if (to.path!='/mobile' && await window.mobileCheck()) {
//       next('/mobile')
//     }
//     if (!curentUser) {
//       next({ name: 'auth1.sign-in1' })
//     }
//     try {
//       if (store.state.Auth.user && to.meta.roles) {
//         await store.state.RolePermission.roles?.forEach((userRole)=>{
//           if (to.meta.roles.includes(userRole.name)) {
//             next()
//           }
//         })
//         next({ path: '/error403' })
//         return false
//       }

//       axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
//       axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('token')}`
//       const response = await axios.get('/api/auth/me', {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`
//         }
//       })
//       if (response.status == 200) {

//           axios.defaults.headers.common['userID'] = curentUser.uid
//           await store.commit('Auth/userCommit', response.data.data)
//           await store.commit('Auth/panggilanCommit', response.data?.data?.jenisAnggota === 'Muda' ? 'Adik' : 'Kakak')
//           const roles = response.data.data.roles
//           store.commit('RolePermission/setRoles', roles)

//         if (store.state.Auth.user && to.meta.roles) {
//           await store.state.RolePermission.roles?.forEach((userRole)=>{
//             if (to.meta.roles.includes(userRole.name)) {
//               next()
//             }
//           })
//           next({ path: '/error403' })
//         }
//       }
//     } catch (error) {
//       if (error.response.status==404) {
//         store.commit('Auth/errorCommit', "login gagal")
//         next({ name: 'auth1.sign-in1' })

//       }
//       console.log(error)
//     }
//   } else {
//     next()
//   }
// })

router.afterEach((to, from) => {
	document.querySelector('#loader').style.display = 'none'
	document.querySelector('body').style.visibility = 'visible'

	if (['/auth/signin'].includes(to.path)) {
		window.location.reload()
	}

	if (to.name !== 'diklat.ruang-diklat') {
		window.scrollTo(0, 0)
	}

	store.commit('Auth/loadingCommit', false)
})

export default router
