export default {
	userCommit(state, data) {
		state.user = data
	},
	panggilanCommit(state, data) {
		state.panggilan = data
	},
	userDataCommit(state, data) {
		state.userData = data
	},
	errorCommit(state, data) {
		state.error = data
	},
	loginCommit(state, data) {
		state.login = data
	},
	loadingCommit(state, data) {
		state.loading = data
	},
	chatCommit(state, data) {
		state.chat = data
	},

	userRegist(state, data) {
		state.regis = data
	},
	idwilCommit(state, data) {
		state.idwilayah = data
	},
	userProfileCommit(state, data) {
		state.userProfile = data
	},
	updateCount(state, data) {
		if (data == 'plus') {
			state.userProfile.count_post = state.userProfile.count_post + 1
		} else {
			state.userProfile.count_post = state.userProfile.count_post - 1
		}
	},
	setCurrentStepBio(state, value) {
		state.currentStepBio = value
	},
	setFormEditProfile(state, value) {
		Object.assign(state.formEditProfile, value)
	}
}
