import { allRole, whereRole, WhereNotRole } from '@/plugins/RolePermision/role.js'
import { isPermisionRole } from '@/plugins/RolePermision/role.js'

// GIAT
const Kegiatan = () => import(/* webpackChunkName: "Giat-Kegiatan" */ '../views/Apps/Giat/Page/PageListKegiatan.vue')
const KelolaKegiatan = () => import(/* webpackChunkName: "Giat-Kelola-Kegiatan" */ '../views/Apps/Giat/Page/PageKelolaKegiatan.vue')
const TambahKegiatan = () => import(/* webpackChunkName: "Giat-Tambah-Kegiatan" */ '../views/Apps/Giat/Page/PageTambahKegiatan.vue')
const EditKegiatan = () => import(/* webpackChunkName: "Giat-Ubah-Kegiatan" */ '../views/Apps/Giat/Page/PageEditKegiatan.vue')
const DetailKegiatan = () => import(/* webpackChunkName: "Giat-Detail-Kegiatan" */ '../views/Apps/Giat/Page/PageDetailKegiatan.vue')
const Beranda = () => import(/* webpackChunkName: "Giat-Beranda" */ '../views/Apps/Giat/Page/PageBerandaGiat.vue')
const RuangGiat = () => import(/* webpackChunkName: "Giat-Ruang-Giat" */ '../views/Apps/Giat/Page/PageRuangGiat.vue')
const TambahFormulir = () => import(/* webpackChunkName: "Giat-Tambah-Formulir" */ '../views/Apps/Giat/Page/PageTambahFormulir.vue')
const KelolaGiat = () => import(/* webpackChunkName: "Giat-Kelola-Giat" */ '../views/Apps/Giat/Page/PageKelolaGiat.vue')
const LogGiat = () => import(/* webpackChunkName: "Giat-Log-Giat" */ '../views/Apps/Giat/Page/PageLogGiat.vue')
const GaleriKegiatan = () => import(/* webpackChunkName: "Giat-Galeri-Kegiatan" */ '../views/Apps/Giat/Page/PageGaleriKegiatan.vue')
const LogSaranaPrasarana = () => import(/* webpackChunkName: "Giat-Log-SaranaPrasarana" */ '../views/Apps/Giat/Page/PageLogSarpras.vue')
const KegiatanKaryaBakti = () => import(/* webpackChunkName: "Giat-KegiatanKaryaBakti" */ '../views/Apps/Giat/Page/KaryaBakti/PageListKaryaBakti.vue')
const TambahKegiatanKaryaBakti = () =>
	import(/* webpackChunkName: "Giat-TambahKegiatanKaryaBakti" */ '../views/Apps/Giat/Page/KaryaBakti/PageTambahKaryaBakti.vue')
const EditKegiatanKaryaBakti = () =>
	import(/* webpackChunkName: "Giat-EditKegiatanKaryaBakti" */ '../views/Apps/Giat/Page/KaryaBakti/PageEditKaryaBakti.vue')
const LayoutKelolaKaryaBakti = () =>
	import(/* webpackChunkName: "Giat-LayoutKelolaKaryaBakti" */ '../views/Apps/Giat/Page/KaryaBakti/LayoutKelola.vue')
const KelolaKaryaBaktiDashbor = () =>
	import(/* webpackChunkName: "Giat-KelolaKaryaBaktiDashbor" */ '../views/Apps/Giat/Page/KaryaBakti/KelolaDashbor.vue')
const KelolaKaryaBaktiVerifikator = () =>
	import(/* webpackChunkName: "Giat-KelolaKaryaBaktiVerifikator" */ '../views/Apps/Giat/Page/KaryaBakti/KelolaVerifikator.vue')
const PageCheckSFH = () => import(/* webpackChunkName: "Giat-PageCheckSFH" */ '../views/Apps/Giat/Page/KaryaBakti/PageCheckSFH.vue')
const LayoutKelolaSertifikat = () =>
	import(/* webpackChunkName: "Giat-LayoutKelolaSertifikat" */ '../views/Apps/Giat/Page/KaryaBakti/LayoutKelolaSertifikat.vue')
const PageKelolaSertifikat = () =>
	import(/* webpackChunkName: "Giat-PageKelolaSertifikat" */ '../views/Apps/Giat/Page/KaryaBakti/KelolaSertifikat.vue')
const PageKelolaAnggotaKB = () => import(/* webpackChunkName: "Giat-PageKelolaAnggotaKB" */ '../views/Apps/Giat/Page/KaryaBakti/KelolaAnggota.vue')
const PageLihatKBID = () => import(/* webpackChunkName: "Giat-PageLihatKBID" */ '../views/Apps/Giat/Page/KaryaBakti/PageLihatKBID.vue')
const KelolaKaryaBaktiLaporan = () =>
	import(/* webpackChunkName: "Giat-KelolaKaryaBaktiLaporan" */ '../views/Apps/Giat/Page/KaryaBakti/KelolaLaporan.vue')

var prop = 'giat-pramuka'

const GiatRouter = [
	{
		path: '/giat-pramuka',
		name: prop + '.kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Kegiatan', roles: allRole },
		component: Kegiatan
	},
	// {
	// 	path: '/giat-pramuka',
	// 	name: prop + '.beranda',
	// 	meta: { auth: true, name: 'Giat Pramuka - Beranda', roles: allRole },
	// 	component: Beranda
	// },
	{
		path: '/giat-pramuka/tambah-kegiatan',
		name: prop + '.tambah.kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Tambah Kegiatan', roles: allRole },
		component: TambahKegiatan
	},
	{
		path: '/giat-pramuka/ubah-kegiatan/:id',
		name: prop + '.edit.kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Ubah Kegiatan', roles: allRole },
		component: EditKegiatan
	},
	{
		path: '/giat-pramuka/kelola-kegiatan',
		name: prop + '.kelola.kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Kelola Kegiatan', roles: allRole },
		component: KelolaKegiatan
	},
	{
		path: '/giat-pramuka/kegiatan/:id',
		name: prop + '.detail.kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Detail Kegiatan', roles: allRole },
		component: DetailKegiatan
	},
	{
		path: '/giat-pramuka/ruang-giat/:id',
		name: prop + '.ruang-giat',
		meta: { auth: true, name: 'Giat Pramuka - Ruang Giat', roles: allRole },
		component: RuangGiat
	},
	{
		path: '/giat-pramuka/tambah-formulir/:id',
		name: prop + '.tambah.formulir',
		meta: { auth: true, name: 'Giat Pramuka - Tambah Formulir', roles: allRole },
		component: TambahFormulir
	},
	{
		path: '/giat-pramuka/kelola-giat/:id',
		name: prop + '.kelola-giat',
		meta: { auth: true, name: 'Giat Pramuka - Kelola Giat', roles: allRole },
		component: KelolaGiat
	},
	{
		path: '/giat-pramuka/kelola-giat/:id/log-giat/:giat_id',
		name: prop + '.log-giat',
		meta: { auth: true, name: 'Giat Pramuka - Log Giat', roles: allRole },
		component: LogGiat
	},
	{
		path: '/giat-pramuka/kelola-giat/:id/log-sarpras/:giat_id',
		name: prop + '.log-sarpras',
		meta: { auth: true, name: 'Giat Pramuka - Log Sarana Prasarana', roles: allRole },
		component: LogSaranaPrasarana
	},
	{
		path: '/giat-pramuka/galeri-kegiatan/:id',
		name: prop + '.galeri-kegiatan',
		meta: { auth: true, name: 'Giat Pramuka - Galeri', roles: allRole },
		component: GaleriKegiatan
	},

	{
		path: '/giat-karya-bakti',
		redirect: (to) => {
			if (isPermisionRole(['Admin Aplikasi', 'Admin Kwartir Nasional'])) {
				return { path: '/giat-karya-bakti/list' }
			} else {
				return { path: '/giat-karya-bakti/check-sfh' }
			}
		}
	},
	{
		path: '/giat-karya-bakti/list',
		name: prop + '.karya-bakti.list',
		meta: {
			auth: true,
			name: 'Giat Pramuka -Tambah Karya Bakti',
			roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional'])
		},
		component: KegiatanKaryaBakti
	},
	{
		path: '/giat-karya-bakti/tambah',
		name: prop + '.karya-bakti.add',
		meta: { auth: true, name: 'Giat Pramuka -Tambah Karya Bakti', roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional']) },
		component: TambahKegiatanKaryaBakti
	},
	{
		path: '/giat-karya-bakti/edit/:id',
		name: prop + '.karya-bakti.update',
		meta: { auth: true, name: 'Giat Pramuka - Ubah Karya Bakti', roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional']) },
		component: EditKegiatanKaryaBakti
	},

	{
		path: '/giat-karya-bakti/kelola/aktif',
		name: prop + '.karya-bakti.kelola',
		component: LayoutKelolaKaryaBakti,
		children: [
			{
				path: '',
				redirect: (to) => {
					return {
						path: '/giat-karya-bakti/kelola/aktif/dashbor'
					}
				}
			},
			{
				path: 'dashbor',
				name: prop + '.karya-bakti.kelola-dashbor',
				meta: {
					auth: true,
					name: 'Giat Pramuka - Kelola Karya Bakti Dashbor',
					roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Verifikator KBID', 'Admin Kwartir Cabang', 'Admin Kwartir Daerah'])
				},
				component: KelolaKaryaBaktiDashbor
			},
			{
				path: 'laporan',
				name: prop + '.karya-bakti.kelola-laporan',
				meta: {
					auth: true,
					name: 'Giat Pramuka - Kelola Karya Bakti Laporan',
					roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Verifikator KBID', 'Admin Kwartir Cabang', 'Admin Kwartir Daerah'])
				},
				component: KelolaKaryaBaktiLaporan
			},
			{
				path: 'verifikator',
				name: prop + '.karya-bakti.kelola-verifikator',
				meta: {
					auth: true,
					name: 'Giat Pramuka - Kelola Karya Bakti Verifikator',
					roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Verifikator KBID', 'Admin Kwartir Cabang', 'Admin Kwartir Daerah'])
				},
				component: KelolaKaryaBaktiVerifikator
			},
			{
				path: 'lihat-kbid',
				name: prop + '.karya-bakti.lihat-kbid',
				meta: {
					auth: true,
					name: 'Giat Pramuka - Kelola Karya Bakti Verifikator',
					roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Verifikator KBID', 'Admin Kwartir Cabang', 'Admin Kwartir Daerah'])
				},
				component: PageLihatKBID
			},
			{
				path: 'sertifikat',
				name: prop + '.karya-bakti.kelola-sertifikat',
				meta: {
					auth: true,
					name: 'Giat Pramuka - Kelola Karya Bakti Sertifikat',
					roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional', 'Verifikator KBID', 'Admin Kwartir Cabang', 'Admin Kwartir Daerah'])
				},
				component: LayoutKelolaSertifikat,
				children: [
					{
						path: '',
						redirect: (to) => {
							return {
								path: '/giat-karya-bakti/kelola/aktif/sertifikat/anggota'
							}
						}
					},
					{
						path: 'anggota',
						name: prop + '.karya-bakti.kelola-sertifikat.anggota',
						meta: {
							auth: true,
							name: 'Giat Pramuka - Kelola Karya Bakti Anggota',
							roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional'])
						},
						component: PageKelolaAnggotaKB
					},
					{
						path: 'kelola',
						name: prop + '.karya-bakti.kelola-sertifikat.kelola',
						meta: {
							auth: true,
							name: 'Giat Pramuka - Kelola Karya Bakti Sertifikat',
							roles: whereRole(['Admin Aplikasi', 'Admin Kwartir Nasional'])
						},
						component: PageKelolaSertifikat
					}
				]
			}
		]
	},
	{
		path: '/giat-karya-bakti/check-sfh',
		name: prop + '.karya-bakti.check-sfh',
		meta: { auth: true, name: 'Giat Pramuka - Cek SFH', roles: allRole },
		component: PageCheckSFH
	}
]

export default GiatRouter
