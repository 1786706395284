import axios from "axios"
import { auth } from "../../firebase"
import store from '../index'
export default {
  tweetCommit (state, data) { 
    if (data == null) {
      state.tweet = []
    }else{
      state.tweet.push(...data)
    }
  },
  unshiftTweetCommit (state, data) { 
    if (data == null) {
      state.tweet = []
    }else{
      state.tweet.unshift(...data)
    }
  },
  oneTweetCommit(state, data) { 
      state.tweet = data
  },
  detailTweetCommit(state,data){
    state.detailTweet = data
  },
  reloadTweetCommit (state,data) {
    if (Array.isArray(state.tweet)) {
      state.tweet = state.tweet
      .map((item) => {
        if (item.key==data.key) {
          return item = data
        }else{
          return item
        }
      })
    }
  },
  reloadCommentCommit (state,data) {
    state.comment = state.comment
    .map((item) => {
      if (item.key==data.key) {
        return item = data
      }else{
        return item
      }
    })
  },
  commentCommit (state, data) {
    if (data == null) {
      state.comment = []
    }else{
      state.comment.push(...data)
    }
  },
  deleteTweetCommit (state, data) {
    if (!data.itemKey) {
      state.tweet = state.tweet.filter(item => {
        return item.key !== data.key
      })
    } else {
      state.tweet.forEach(item => {
        if (item.key == data.itemKey) {
          item.commentCount = item.commentCount - 1
          item.comments = item.comments.filter(it => {
            return it.key !== data.key
          })
        }
      })
      state.comment = state.comment.filter(item => {
        return item.key !== data.key
      })
    }
  },
  deleteCommentCommit (state,data) {
    state.tweet = state.tweet.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
    state.comment = state.comment.filter( item => item.key !== data.key)
    .map((item) => {
        return item
    })
  },
  muteTweetCommit (state,data) {
    state.tweet = state.tweet.filter( item => item.user.userId !== data.user.userId)
    .map((item) => {
        return item
    })
    state.comment = state.comment.filter( item => item.user.userId !== data.user.userId)
    .map((item) => {
        return item
    })
  },
  likeCommit (state,data) {
    if (data.like==false) {
      state.tweet = state.tweet.map((item) => {
        if (item.key==data.key) {
          item.likeList = item.likeList?item.likeList:[]
          // item.likeList.push(store.state.Auth.user.userId)
          item.likeList.push(store.state.Auth.user.userId)
          item.likeCount = item.likeCount+1
          return item
        }else{
          return item
        }
      })
    }else{
      state.tweet = state.tweet.map((item) => {
        if (item.key==data.key) {
          item.likeList = item.likeList?item.likeList:[]
          // item.likeList = item.likeList.filter(item => item != store.state.Auth.user.userId)
          item.likeList = item.likeList.filter(item => item != store.state.Auth.user.userId)
          item.likeCount = item.likeCount-1
          return item
        }else{
          return item
        }
      })
    }
  },
  likeCommentPostCommit (state, data) {
    console.log(data.like)
    if (data.like == false) {
      state.tweet.map((item) => {
        if (data.itemKey == item.key) {
          item.comments.map(it => {
            if (data.key == it.key) {
              it.likeList = it.likeList ? it.likeList : []
              it.likeList.push(store.state.Auth.user.userId)
              it.likeCount = it.likeCount + 1
            }
          })
        }
      })
    } else {
      state.tweet.map((item) => {
        if (data.itemKey == item.key) {
          item.comments.map(it => {
            if (data.key == it.key) {
              it.likeList = it.likeList ? it.likeList:[]
              // it.likeList = it.likeList.filter(it => it !== store.state.Auth.user.userId)
              it.likeList = it.likeList.filter(i => i != store.state.Auth.user.userId)
              it.likeCount = it.likeCount - 1
            }
          })
        }
      })
    }
  },
  likeCommentCommit (state,data) {
    if (data.like==false) {
      state.comment = state.comment.map((item) => {
        if (item.key==data.key) {
          item.likeList = item.likeList?item.likeList:[]
          // item.likeList.push(store.state.Auth.user.userId)
          item.likeList.push(store.state.Auth.user.userId)
          item.likeCount = item.likeCount+1
          return item
        }else{
          return item
        }
      })
    }else{
      state.comment = state.comment.map((item) => {
        if (item.key==data.key) {
          item.likeList = item.likeList?item.likeList:[]
          // item.likeList = item.likeList.filter(item => item != store.state.Auth.user.userId)
          item.likeList = item.likeList.filter(item => item != store.state.Auth.user.userId)
          item.likeCount = item.likeCount-1
          return item
        }else{
          return item
        }
      })
    }
  },
  attendCommit (state,data) {
    if (data.attend==false) {
      state.tweet = state.tweet.map((item) => {
        if (item.key==data.key) {
          item.attendList = item.attendList?item.attendList:[]
          // item.attendList.push(store.state.Auth.user.userId)
          item.attendList.push(store.state.Auth.user.userId)
          item.attendCount = item.attendCount+1
          return item
        }else{
          return item
        }
      })
    }else{
      state.tweet = state.tweet.map((item) => {
        if (item.key==data.key) {
          item.attendList = item.attendList?item.attendList:[]
          // item.attendList = item.attendList.filter(item => item !== store.state.Auth.user.userId)
          item.attendList = item.attendList.filter(item => item !== store.state.Auth.user.userId)
          item.attendCount = item.attendCount-1
          return item
        }else{
          return item
        }
      })
    }
  },
  repostCommit (state, data) {
    if (data.parentkey) {
      state.tweet.forEach((item) => {
        if (data.parentkey.key == item.key) {
          item.comments.forEach((it) => {
            if (data.key == it.key) {
              it.retweetCount = it.retweetCount + 1
            }
          })
        }
      })
    } else {
      state.tweet.forEach((item) => {
        if (data.key == item.key) {
          item.retweetCount = item.retweetCount + 1
        }
      })
    }
  },
  shareCommit (state, data) {
    state.tweet.forEach(item => {
      if (data == item.key) {
        item.shareLinkCount = item.shareLinkCount+1
      }
    })
  },
  stopCommit (state, data) {
    state.stop = data
  }
}
