export default {
  setListMusda (state, payload) {
    
    state.listMusda = payload
    
  },

  addListMusda (state, payload) {
    state.listMusda.unshift(payload)
  }
}
