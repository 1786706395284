<template>
  <!-- loader Start -->
  <div id="loading" >
    <div id="loading-center">
    </div>
  </div>
  <!-- loader END -->
</template>
<script>
import lightLogo from '../../../assets/images/loader.png'
// import darkLogo from '../../../assets/images/logo-white.png'
import { socialvue } from '@/config/pluginInit'
export default {
  name: 'Loader',
  props: {
    dark: { type: Boolean, default: false }
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      logo: lightLogo,
    }
  },
  methods: {
    init () {
      socialvue.loaderInit()
    }
  }
}
</script>
