// AUTH
const Signin = () => import(/* webpackChunkName: "auth" */ '../views/AuthPages/Default/SigninV2/SignInDirect.vue')
const SigninPintu = () => import(/* webpackChunkName: "auth" */ '../views/AuthPages/Default/SigninV2/SignIn.vue')
const Signup = () => import(/* webpackChunkName: "signup" */ '../views/AuthPages/Default/SigninV2/KonfirmasiRegister.vue')
const RecoverPassword = () => import(/* webpackChunkName: "recover" */ '../views/AuthPages/Default/SigninV2/RecoverPassword')
const ResetPassword = () => import(/* webpackChunkName: "recover" */ '../views/AuthPages/Default/SigninV2/ResetPassword')

// SELF REGISTER
// const PrinKta = () => import(/* webpackChunkName: "kta-print" */ '../views/Apps/KTA/Components/print/CardPrint.vue')
// const RegisterKta = () => import(/* webpackChunkName: "kta" */ '../views/Apps/KTA/PageRegisterKta.vue')
// const RegisterKtaId = () => import(/* webpackChunkName: "kta-id" */ '../views/Apps/KTA/PageDetailOrder.vue')

var prop = 'auth1'

const AuthentifikasiRouter = [
	// {
	//   path: '/register-kta',
	//   name: 'register-kta',
	//   meta: { auth: false, name: 'Self Register KTA' },
	//   component: RegisterKta
	// },
	// {
	//   path: '/register-kta/:id',
	//   name: 'register-kta-id',
	//   meta: { auth: false, name: 'Self Register KTA Order' },
	//   component: RegisterKtaId
	// },
	// {
	//   path: '/print-kta/:id',
	//   name: 'print-kta',
	//   meta: { auth: false, name: 'Cetak KTA' },
	//   component: PrinKta
	// },
	{
		path: 'signin',
		name: prop + '.sign-in1',
		component: Signin,
		meta: { auth: false, name: 'Login' }
	},
	{
		path: 'signin',
		name: prop + '.sign-in1',
		component: Signin,
		meta: { auth: false, name: 'Login' }
	},
	{
		path: 'signup/:id',
		name: prop + '.sign-up1',
		component: Signup,
		meta: { auth: false, name: 'Konfirmasi Registrasi' }
	},
	{
		path: 'recoverpassword',
		name: prop + '.recoverpassword1',
		meta: { auth: false, name: 'lupa password' },
		component: RecoverPassword
	},
	{
		path: 'resetpassword',
		name: prop + '.resetpassword1',
		meta: { auth: false, name: 'password baru' },
		component: ResetPassword
	}
	// {
	//   path: "lacak-surat",
	//   name: prop + '.lacak-surat',
	//   component: LacakSurat,
	//   meta: { auth: false, name: "Lacak Surat"},
	// }
]
AuthentifikasiRouter.push({
	path: 'pintu-belakang',
	name: prop + '.pintu-belakang',
	component: SigninPintu,
	meta: { auth: false, name: 'Login Pintu Belakang' }
})

export default AuthentifikasiRouter
